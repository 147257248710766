import { DEVICE } from "src/constants";
import styled from "styled-components";

export const SubHeaderContainer = styled.section`
  width: 100%;
  background-color: var(--color-surface-default);
  padding: calc(var(--unit) * 4) calc(var(--unit) * 5);
  border-top: 1px solid var(--color-divider-default);
  box-sizing: border-box;
`;

export const SubHeaderInfoBlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: var(--unit);

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column-reverse;
  }
`;

export const SubHeaderCandidateContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
    align-items: normal;
    margin-top: calc(var(--unit) * 4);
  }
`;

export const SubHeaderStatusContainer = styled.div`
  display: flex;
  align-items: center;
`;
