import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";

export const ContentBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
  background-color: ${({ theme }) => theme.colors["color-surface-default"]};
  padding: 32px 24px;
`;

export const PrimaryText = styled.h2`
  margin: 8px 0;
  ${getTypographyStyles({ variant: "h2" })};
`;

export const SecondaryText = styled.h3`
  margin-bottom: 8px;
  ${getTypographyStyles({ variant: "h3" })};
`;

export const TextHighlight = styled.div`
  margin-bottom: 8px;
  ${getTypographyStyles({ variant: "body-highlight" })};
`;

export const Text = styled.div`
  ${getTypographyStyles({ variant: "body-wide-line" })};
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 32px;
`;
