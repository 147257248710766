import styled, { css } from "styled-components";
import {
  Field as FieldComponent,
  FieldGroup as FieldGroupComponent,
  DatePicker as DatePickerComponent,
  IDatePickerProps,
  RadioGroup as RadioGroupComponent,
  IRadioGroupProps,
  ITextAreaProps,
  TextArea as TextAreaComponent,
  IInputProps,
  Input as InputComponent
} from "@security-watchdog/sw-ui-kit";
import { IFieldProps } from "components/common/AnswerField/types";

export const Field = styled(FieldComponent)<IFieldProps>`
  ${({ isHighlightedField }) =>
    isHighlightedField
      ? css`
          margin-bottom: 6px;
        `
      : css`
          margin-bottom: 23px;
        `};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FieldGroup = styled(FieldGroupComponent)<IFieldProps>`
  ${({ isHighlightedField }) =>
    isHighlightedField
      ? css`
          margin-bottom: 6px;
        `
      : css`
          margin-bottom: 23px;
        `};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DatePicker = styled(DatePickerComponent)<IDatePickerProps>`
  max-width: 250px;
  margin-right: 16px;
`;

export const RadioGroup = styled(RadioGroupComponent)<IRadioGroupProps>`
  & > div {
    display: inline-flex;
  }
  & > div:nth-child(1) {
    margin-right: 24px;
  }
  & > div:nth-child(3) {
    width: 100%;
  }
`;

export const TextArea = styled(TextAreaComponent)<ITextAreaProps>`
  & textarea {
    min-height: 40px;
    padding: 10px 15px 8px;
  }
`;

export const ArrayFieldContainer = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 15px;
`;

export const InputTrashIconContainer = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  padding-top: 10px;
`;

export const Input = styled(InputComponent)<IInputProps>`
  width: 100%;
`;
