import styled, { css } from "styled-components";

import { TabLabelProps } from "components/Tabs/Tabs";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  flex-grow: 1;
`;

export const TabsWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  z-index: ${({ theme }): string => `${theme.layers.overlay}`};
`;

export const TabWrap = styled.div`
  display: flex;
  margin: 16px 32px 0 0;
  &:last-child {
    margin-right: 0;
  }
`;

export const TabLabel = styled.div<TabLabelProps>`
  position: relative;
  min-height: 100%;
  font-size: 14px;
  cursor: pointer;
  ${({ $isTabActive, $isDisabled, theme }) => {
    if ($isTabActive) {
      return css`
        color: ${theme.colors["color-text-active"]};
        line-height: 16px;
        &:after {
          content: "";
          display: block;
          background-color: ${theme.colors["color-icon-active"]};
          border-radius: 2px 2px 0 0;
          margin-top: 12px;
          height: 4px;
          width: 100%;
        }
      `;
    } else {
      return css`
        color: ${$isDisabled
          ? theme.colors["color-text-disabled"]
          : theme.colors["color-text-on-surface"]};
      `;
    }
  }};
`;
