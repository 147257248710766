import styled from "styled-components";

import { TYPE_SIGNATURE_TEXT_SIZE } from "components/eSignatureModal/constants";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  position: relative;
  height: 100%;
  background: ${({ theme }) => theme.colors["color-surface-subdued-default"]};
  border-radius: 4px;
  overflow: hidden;
`;

export const StylesInput = styled.input`
  width: 100%;
  padding: 24px 0;
  text-align: center;
  border: none;
  background: transparent;
  outline: none;
  font-family: DancingScriptBold, cursive;
  font-size: ${TYPE_SIGNATURE_TEXT_SIZE}px;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors["color-divider-default"]}`};

  &::placeholder {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 16px;
  }
`;
