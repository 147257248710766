import React, { FC, memo, NamedExoticComponent, Suspense } from "react";
import { Route, Routes as ReactRouterRoutes } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import { ROUTES } from "src/constants";
import { Footer } from "components/Footer";
import { IdleTimerComponent } from "components/IdleTimerContainer";
import { Spinner } from "components/common/Spinner";
import CookiesPage from "containers/CookiesPage";
import ProofOfIdentity from "containers/ProofOfIdentity";
import MyDocuments from "containers/MyDocuments";
import TermsAndConditions from "containers/TermsAndConditions";
import Help from "containers/Help";
import { SuccessSubmit } from "containers/SuccessSubmit";
import AddressTimeLine from "containers/AddressTimeLine";
import SectionList from "containers/SectionList";
import Login from "containers/Login";
import PersonalDetails from "containers/PersonalDetails";
import EmploymentAndEducation from "containers/EmploymentAndEducation";
import AdditionalDocuments from "containers/AdditionalDocuments";
import Declarations from "containers/Declarations";
import PersonalReferees from "containers/PersonalReferees";
import { ProfessionalMemberShip } from "containers/ProfessionalMemberShip";
import { isKonfirIntegrationEnabled, isTrustIdProductEnabled } from "src/utils";
import { TrustIdPage } from "containers/TrustIdPage";
import { CheckCaseIdWrapper } from "containers/CheckCaseIdWrapper";
import { KonfirPage } from "containers/KonfirPage";
import { KonfirRedirectPage } from "containers/KonfirPage/components";
import { AccessibilityStatementPage } from "containers/AccessibilityStatementPage";

const RoutesComponent: FC = () => (
  <CheckCaseIdWrapper>
    <Suspense fallback={<Spinner />}>
      <IdleTimerComponent>
        <ReactRouterRoutes>
          <Route
            path={ROUTES.ROOT}
            element={
              <PrivateRoute>
                <SectionList />
              </PrivateRoute>
            }
          />

          <Route path={ROUTES.LOGIN} element={<Login />} />

          <Route
            path={ROUTES.PROOF_OF_IDENTITY}
            element={
              <PrivateRoute>
                <ProofOfIdentity />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.PERSONAL_DETAILS}
            element={
              <PrivateRoute>
                <PersonalDetails />
              </PrivateRoute>
            }
          />

          {isTrustIdProductEnabled() ? (
            <Route
              path={ROUTES.TRUST_ID}
              element={
                <PrivateRoute>
                  <TrustIdPage />
                </PrivateRoute>
              }
            />
          ) : null}

          {isKonfirIntegrationEnabled() ? (
            <Route
              path={ROUTES.KONFIR}
              element={
                <PrivateRoute>
                  <KonfirPage />
                </PrivateRoute>
              }
            />
          ) : null}

          {isKonfirIntegrationEnabled() ? (
            <Route
              path={ROUTES.KONFIR_REDIRECT}
              element={
                <PrivateRoute>
                  <KonfirRedirectPage />
                </PrivateRoute>
              }
            />
          ) : null}

          <Route
            path={ROUTES.MY_DOCUMENTS}
            element={
              <PrivateRoute>
                <MyDocuments />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.ADDRESS_TIMELINE}
            element={
              <PrivateRoute>
                <AddressTimeLine />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.TERMS_AND_CONDITIONS}
            element={
              <PrivateRoute>
                <TermsAndConditions />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.EMPLOYMENT_AND_EDUCATION}
            element={
              <PrivateRoute>
                <EmploymentAndEducation />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.DECLARATIONS}
            element={
              <PrivateRoute>
                <Declarations />
              </PrivateRoute>
            }
          />

          <Route path={ROUTES.COOKIES_PAGE} element={<CookiesPage />} />

          <Route
            path={ROUTES.ACCESSIBILITY_STATEMENT}
            element={<AccessibilityStatementPage />}
          />

          <Route path={ROUTES.HELP} element={<Help />} />

          <Route
            path={ROUTES.SUCCESS_SUBMIT}
            element={
              <PrivateRoute>
                <SuccessSubmit />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.PROFESSIONAL_MEMBERSHIP}
            element={
              <PrivateRoute>
                <ProfessionalMemberShip />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.ADDITIONAL_DOCUMENTS}
            element={
              <PrivateRoute>
                <AdditionalDocuments />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.PERSONAL_REFEREES}
            element={
              <PrivateRoute>
                <PersonalReferees />
              </PrivateRoute>
            }
          />
        </ReactRouterRoutes>
        <Footer />
      </IdleTimerComponent>
    </Suspense>
  </CheckCaseIdWrapper>
);

export const Routes: NamedExoticComponent = memo(RoutesComponent);
