import React, { FC, memo } from "react";
import { LogoColorFulIcon } from "@security-watchdog/sw-ui-kit";

import { useCustomLogoImage } from "src/hooks/useCustomLogoImage";
import { attachmentFragment } from "src/graphQLTypes";
import * as s from "./styles";

export type LogoProps = {
  logo: attachmentFragment | null;
  isDataReady?: boolean;
};

export const Component: FC<LogoProps> = ({ logo, isDataReady }) => {
  const { imageUrl: customLogoUrl } = useCustomLogoImage(logo);

  if (isDataReady && customLogoUrl) {
    return (
      <s.DynamicLogo
        src={customLogoUrl}
        alt="Company logo"
        data-testid="dynamic-logo"
      />
    );
  }

  if (isDataReady && !customLogoUrl && !logo) {
    return (
      <s.StaticLogoContainer data-testid="static-logo">
        <LogoColorFulIcon role="img" aria-label="Logo of Matrix company" />
      </s.StaticLogoContainer>
    );
  }

  return null;
};

Component.displayName = "Logo";

export const Logo = memo(Component);
