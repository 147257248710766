import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";

export const PageTitle = styled.h1`
  ${getTypographyStyles({ color: "color-text-on-surface", variant: "h1" })};
  margin: 0;
`;

export const PageDescription = styled.div`
  ${getTypographyStyles({ variant: "body-wide-line" })};
`;
