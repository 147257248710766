import styled from "styled-components";
import {
  Button as ButtonComponent,
  IButtonProps,
  getTypographyStyles,
  theme
} from "@security-watchdog/sw-ui-kit";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${theme.colors["color-surface-default"]};
  padding: 40px 32px;
  margin: 32px;
  flex-direction: column;
  max-width: 588px;
  align-self: center;
  align-items: flex-start;
`;

export const Title = styled.h2`
  ${getTypographyStyles({ variant: "h2" })};
  margin-top: 32px;
`;

export const Description = styled.span`
  ${getTypographyStyles({ variant: "body-wide-line" })};
  margin-top: 6px;
`;

export const Button = styled(ButtonComponent)<IButtonProps>`
  margin-top: 32px;
  max-width: 336px;
  width: 100%;

  align-self: center;
  align-items: center;
  justify-content: center;
`;
