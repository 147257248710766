import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const Container = styled.section`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors["color-surface-default"]};
  margin-bottom: 8px;
  box-sizing: border-box;
`;

export const QuestionHeader = styled.div`
  padding: 23px 23px 16px 23px;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors["color-divider-default"]}`};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${DEVICE.TABLET}) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  ${getTypographyStyles({ variant: "body-highlight" })};

  @media (max-width: ${DEVICE.TABLET}) {
    order: 1;
    margin-top: 2px;
  }
`;

export const Description = styled.div`
  ${getTypographyStyles({
    color: "color-text-subdued",
    variant: "body-wide-line"
  })}
`;

export const ContentContainer = styled.div`
  padding: 0 23px;
`;

export const AnswerContainer = styled.div`
  border-bottom: ${({ theme }): string =>
    `1px solid ${theme.colors["color-divider-default"]}`};
  padding: 23px 0;
  &:last-child {
    border-bottom: none;
  }
`;

export const WarningContainer = styled.div`
  padding: 16px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ theme }) =>
    theme.colors["color-surface-subdued-info-default"]};
`;

export const WarningText = styled.div`
  margin-left: 10px;
  ${getTypographyStyles({ variant: "body-wide-line" })}
`;

export const AnswerToolsPanel = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const QuestionFooter = styled.div`
  padding: 8px 0;
  margin: 0 23px;
  border-top: ${({ theme }) =>
    `1px solid ${theme.colors["color-divider-default"]}`};
`;

export const DeleteButtonText = styled.span`
  color: ${({ theme }) => theme.colors["color-action-critical-default"]};
`;
