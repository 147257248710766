import React, { FC, memo } from "react";
import * as s from "./styles";
import { CandidateCaseStatus } from "src/graphQLTypes";
import { CANDIDATE_CASE_STATUS_TEXT } from "src/constants";

type Props = {
  status: CandidateCaseStatus;
};

const Component: FC<Props> = ({ status }) => {
  const statusText = CANDIDATE_CASE_STATUS_TEXT[status];

  return (
    <s.Wrapper>
      <div
        className="badge margin-r-2"
        role="status"
        aria-label={`Case status changed to ${statusText}`}
      >
        {statusText}
      </div>
      {status !== CandidateCaseStatus.INACTIVE && (
        <s.BarContainer status={status}>
          <span></span>
          <span></span>
          <span></span>
        </s.BarContainer>
      )}
    </s.Wrapper>
  );
};

Component.displayName = "CaseStatus";

export const CaseStatus = memo(Component);
