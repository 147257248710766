import React, { FC, PropsWithChildren, useEffect, useRef } from "react";

import * as s from "./styles";
import { Button, Icon } from "@security-watchdog/ui-components";
import { DESIGN_SYSTEM_MODAL_HEADER_TITLE_ID } from "components/DesignSystemModal/constants";

type ModalHeaderProps = PropsWithChildren<{
  title: string;
  subTitle?: string;
  onClose?: () => void;
}>;

export const ModalHeader: FC<ModalHeaderProps> = ({
  title,
  subTitle,
  onClose,
  children
}) => {
  const closeBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setTimeout(() => {
      closeBtnRef.current?.focus();
    }, 500);
  }, []);

  return (
    <s.Header $hasChildren={Boolean(children)}>
      <s.HeaderTitleWrapper>
        <div>
          <s.HeaderTitle id={DESIGN_SYSTEM_MODAL_HEADER_TITLE_ID}>
            {title}
          </s.HeaderTitle>
          {subTitle && <s.HeaderSubTitle>{subTitle}</s.HeaderSubTitle>}
        </div>
        {onClose && (
          <Button
            dataTestId="modal-close-button"
            variant="ghost"
            ref={closeBtnRef}
            onClick={onClose}
            aria-label="Close"
          >
            <Icon name="close" className="color-icon-default" />
          </Button>
        )}
      </s.HeaderTitleWrapper>
      {children}
    </s.Header>
  );
};

ModalHeader.displayName = "DesignSystemModalHeader";
