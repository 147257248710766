import React, { PropsWithChildren, useCallback, KeyboardEvent } from "react";
import { IProps } from "./types";
import * as s from "./styles";

const TileComponent: React.FC<PropsWithChildren<IProps>> = ({
  children,
  onClick,
  ariaLabel,
  role,
  className
}: PropsWithChildren<IProps>) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter" && onClick) {
        e.preventDefault();
        onClick();
      }
    },
    [onClick]
  );

  const isClickable = Boolean(onClick);

  return (
    <s.TileContainer
      className={className}
      $isClickable={isClickable}
      {...(isClickable ? { tabIndex: 0, role: "button" } : {})}
      {...(role ? { role: role } : {})}
      onKeyDown={handleKeyDown}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {children}
    </s.TileContainer>
  );
};

export const Tile = React.memo<PropsWithChildren<IProps>>(TileComponent);
