import styled from "styled-components";
import { Button, IButtonProps } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const Container = styled.div``;

export const CustomButton = styled(Button)<IButtonProps>`
  margin-left: 24px;
  @media (max-width: ${DEVICE.TABLET}) {
    margin: 8px 0 0;
    padding: 4px 0;
  }
`;

export const CustomCompleteButton = styled(CustomButton)<IButtonProps>`
  margin-top: 16px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  margin-top: 8px;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
    align-items: baseline;
  }
`;

export const Content = styled.div`
  margin-top: 24px;
`;
