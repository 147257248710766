import styled from "styled-components";
import { getTypographyStyles, theme } from "@security-watchdog/sw-ui-kit";

export const Container = styled.div`
  background-color: ${theme.colors["color-surface-default"]};
  margin-top: 24px;
`;

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  ${getTypographyStyles({ variant: "body-highlight" })};
`;

export const SubTitle = styled.div`
  ${getTypographyStyles({
    variant: "body-default",
    color: "color-text-subdued"
  })};
`;
