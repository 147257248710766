import React, { forwardRef } from "react";

import * as s from "./styles";

export const TypeSignatureControl = forwardRef<HTMLInputElement>((_, ref) => {
  return (
    <s.Wrapper>
      <s.StylesInput
        type="text"
        placeholder="Type your signature here"
        title="Signature"
        ref={ref}
      />
    </s.Wrapper>
  );
});

TypeSignatureControl.displayName = "SignatureTypeTextControl";
