// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Cabin-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Cabin-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Cabin-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Roboto-Italic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Roboto-MediumItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Roboto-BoldItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./DancingScript-Bold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Cabin;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

@font-face {
  font-family: Cabin;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}

@font-face {
  font-family: Cabin;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}

@font-face {
  font-family: Formular;
  font-weight: 400;
  font-style: italic;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2");
}

@font-face {
  font-family: Formular;
  font-weight: 500;
  font-style: italic;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2");
}

@font-face {
  font-family: Formular;
  font-weight: 700;
  font-style: italic;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff2");
}

@font-face {
  font-family: DancingScriptBold;
  font-weight: 700;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff2");
}
`, "",{"version":3,"sources":["webpack://./assets/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,+DAAkD;AACpD;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,+DAAiD;AACnD;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,+DAA+C;AACjD;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,4DAAiD;AACnD;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,4DAAuD;AACzD;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,4DAAqD;AACvD;;AAEA;EACE,8BAA8B;EAC9B,gBAAgB;EAChB,kBAAkB;EAClB,4DAAsD;AACxD","sourcesContent":["@font-face {\n  font-family: Cabin;\n  font-style: normal;\n  font-weight: 400;\n  font-display: swap;\n  src: url('./Cabin-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: Cabin;\n  font-style: normal;\n  font-weight: 500;\n  font-display: swap;\n  src: url('./Cabin-Medium.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: Cabin;\n  font-style: normal;\n  font-weight: 700;\n  font-display: swap;\n  src: url('./Cabin-Bold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: Formular;\n  font-weight: 400;\n  font-style: italic;\n  src: url(\"./Roboto-Italic.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-family: Formular;\n  font-weight: 500;\n  font-style: italic;\n  src: url(\"./Roboto-MediumItalic.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-family: Formular;\n  font-weight: 700;\n  font-style: italic;\n  src: url(\"./Roboto-BoldItalic.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-family: DancingScriptBold;\n  font-weight: 700;\n  font-style: normal;\n  src: url(\"./DancingScript-Bold.woff2\") format(\"woff2\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
