import { ButtonBack } from "components/ButtonBack";
import { AnswerField } from "components/common/AnswerField";
import { PageHeader } from "components/PageHeader";
import * as actions from "modules/case/actions";
import {
  takeAreCountriesLoading,
  takeCaseId,
  takeCountryOptions,
  takeHasAddressOverlaps,
  takeIsCaseUpdating,
  takeLogoVisibilityStatus
} from "modules/case/selectors";
import * as actionsMsgNtc from "modules/messages/actions";
import React, {
  Fragment,
  MutableRefObject,
  NamedExoticComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { FormState, useForm, UseFormReturn } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  BUTTON_TYPE,
  OptionProps,
  PlusSmallIcon,
  TrashBinIcon,
  TYPE_NOTIFICATION
} from "@security-watchdog/sw-ui-kit";
import { v4 as uuidv4 } from "uuid";
import { ConfirmModal } from "components/ConfirmModal";
import { IDraftStatuses } from "modules/case/types";
import { DefaultTheme, useTheme } from "styled-components";
import { SectionStatus } from "components/common/SectionStatus";
import { Header as CommonHeader } from "components/Header";
import { ContentLayout } from "components/ContentLayout";
import { ScreenerAccess } from "containers/ScreenerAccess";
import {
  checkIfFieldShouldTriggerCaseUpdate,
  convertQuestionsToPartialFieldHistoryRecords,
  getConditionalProductQuestionToSubmit,
  getUpdatedFieldsVisibility,
  getUpdatedQuestionsVisibility
} from "containers/common/SectionDetails/utils";
import {
  CONDITION_PRODUCT_MESSAGE,
  INTERVAL_OF_SAVING_DRAFT_DATA,
  ROUTES
} from "src/constants";
import {
  CaseTroubleshoootingHistoryType,
  Countries_countries,
  FieldType
} from "src/graphQLTypes";
import { usePrevious } from "src/hooks/usePrevious";
import {
  IAnswer,
  IFieldAnswer,
  IFieldValue,
  IFormFields,
  IPartialTroubleShootHistoryRecord,
  IQuestion,
  IValidateAnswersMap
} from "src/types";
import { isUndefinedOrNull } from "src/utils/typeGuards";
import { useExitPrompt } from "src/hooks/useExitPrompt";
import { useMount } from "src/hooks/useMount";
import { useInterval } from "src/hooks/useInterval";
import { useScrollToTop } from "src/hooks/useScrollToTop";
import { updateTroubleShootPartialHistory } from "src/utils/updateTroubleShootPartialHistory";
import {
  buildFieldKey,
  buildInputForCaseUpdate,
  checkConditionalProductQuestionsTriggered,
  formatId,
  getFieldsValidationMap,
  getFirstFieldIdByQuestions,
  isConditionalProductsSubmitEnabled,
  isGeneralUploadField,
  parseFieldKey,
  validateAnswers,
  validationResolver
} from "src/utils";
import * as s from "./styles";
import {
  IAnswerToDelete,
  CheckConditionsProps,
  IProps,
  CheckQuestionConditionsProps
} from "./types";
import debounce from "lodash/debounce";
import { useUnmount } from "src/hooks/useUnmount";
import { AccessibilitySkipLink } from "components/AccessibilitySkipLink";
import { MetaData } from "components/MetaData";

const SectionDetailsComponent: React.FC<IProps> = ({
  title,
  description,
  questions,
  subTitle,
  backPath,
  section,
  sectionsByAffectingField,
  saveDraftStatusState,
  sectionStatus,
  isFieldsDisabled,
  isOcrUploaded
}: IProps) => {
  useScrollToTop();

  const theme: DefaultTheme = useTheme();

  const navigateTo = useNavigate();

  const dispatch = useDispatch();

  const caseId: string = useSelector(takeCaseId);

  const countryOptions: Array<OptionProps<Countries_countries>> =
    useSelector(takeCountryOptions);

  const areCountriesLoading: boolean = useSelector(takeAreCountriesLoading);

  const isLogoVisible: boolean = useSelector(takeLogoVisibilityStatus);

  const isCaseUpdating: boolean = useSelector(takeIsCaseUpdating);

  const hasAddressOverlaps: boolean = useSelector(takeHasAddressOverlaps);
  const prevIsCaseUpdating: boolean | undefined = usePrevious(isCaseUpdating);

  const headerWrapperRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null);

  const commonHeaderRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null);

  const documentRootRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(document.querySelector("#root"));

  /*
    Needed to get scrolled element bottom coordinates and calculate
    a height, when we need to show status info in sticky header
  */
  const headerWrapperBottomCoordinate: MutableRefObject<number> =
    useRef<number>(0);

  const scrollCallback = useCallback((): void => {
    const documentContainer: HTMLElement | null = documentRootRef.current;

    if (!documentContainer || !commonHeaderRef.current) {
      return;
    }

    /*
      Counting if needed to show status info in sticky header
      and avoiding hardcoded variables

      headerWrapperBottomCoordinate.current - bottom side of
        block, depending on which we will show/hide status
        info in header. If this block not in view - show,
        else - hide

      documentContainer.scrollTop - number of scrolled pixels
        of document

      const isShowLogo = documentContainer.scrollTop < showLogoThreshold;
        commonHeaderRef.current.offsetHeight - height of sticky header

      So the formula is below. We take bottom coordinate, subtract
      a document scroll height and compare it with sticky header height.
      Because of sticky header is always on page we need to show
      status info not when headerWrapperBottom element will be outside a
      screen, but when it will be fully under sticky header
    */

    const isShowLogo =
      headerWrapperBottomCoordinate.current - documentContainer.scrollTop >
      commonHeaderRef.current.offsetHeight;

    if (!isLogoVisible && isShowLogo) {
      dispatch(actions.changeLogoVisibility(true));
      return;
    }

    if (isLogoVisible && !isShowLogo) {
      dispatch(actions.changeLogoVisibility(false));
    }
  }, [dispatch, isLogoVisible]);

  useEffect(() => {
    const document = documentRootRef.current;

    document?.addEventListener("scroll", scrollCallback);

    return (): void => document?.removeEventListener("scroll", scrollCallback);
  }, [scrollCallback]);

  useUnmount(() => {
    dispatch(actions.changeLogoVisibility(true));
  });

  const [
    isCheckOfAddressHistoryOverlapRequired,
    setIsCheckOfAddressHistoryOverlapRequired
  ] = useState<boolean>(false);

  const [isScotlandLocationPopUpVisible, setIsScotlandLocationPopUpVisible] =
    useState<boolean>(false);

  const [troubleShootHistory, setTroubleShootHistory] = useState<
    IPartialTroubleShootHistoryRecord[]
  >([]);

  const [allQuestions, setAllQuestions] = useState<IQuestion[]>(questions);
  const prevAllQuestions = usePrevious(allQuestions);
  const [answersErrors, setAnswersErrors] = useState<IValidateAnswersMap>({});

  const [newQuestionId, setNewQuestionId] = useState<string | undefined>(
    undefined
  );

  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);

  const [answerToDelete, setAnswerToDelete] = useState<IAnswerToDelete | null>(
    null
  );

  const isSaveDataInProgress: boolean = useMemo<boolean>(
    (): boolean =>
      saveDraftStatusState.status === IDraftStatuses.SAVING ||
      saveDraftStatusState.inProgress ||
      saveDraftStatusState.clearDataInProgress ||
      isCaseUpdating,
    [
      isCaseUpdating,
      saveDraftStatusState.clearDataInProgress,
      saveDraftStatusState.inProgress,
      saveDraftStatusState.status
    ]
  );

  const prevIsSaveDataInProgress: boolean | undefined =
    usePrevious<boolean>(isSaveDataInProgress);

  const prevClearDataInProgress: boolean | undefined = usePrevious(
    saveDraftStatusState.clearDataInProgress
  );

  const isExitPromptActive: boolean = useMemo<boolean>(
    () =>
      isSaveDataInProgress ||
      saveDraftStatusState.status === IDraftStatuses.UNSAVED_CHANGES ||
      isSaveDataInProgress,
    [isSaveDataInProgress, saveDraftStatusState.status]
  );

  useExitPrompt(isExitPromptActive);

  const updateSaveDraftStatus = useCallback(
    (status: IDraftStatuses) => dispatch(actions.updateSaveDraftStatus(status)),
    [dispatch]
  );

  useMount((): void => {
    if (headerWrapperRef.current) {
      headerWrapperBottomCoordinate.current =
        headerWrapperRef.current.getBoundingClientRect().bottom;
    }

    updateSaveDraftStatus(IDraftStatuses.NO_CHANGES);
  });

  useEffect(() => {
    if (prevClearDataInProgress && !saveDraftStatusState.clearDataInProgress) {
      navigateTo({
        pathname: ROUTES.ROOT,
        search: window.location.search
      });
    }
  }, [
    backPath,
    navigateTo,
    prevClearDataInProgress,
    saveDraftStatusState.clearDataInProgress
  ]);

  useEffect((): void => {
    if (newQuestionId) {
      dispatch(
        actionsMsgNtc.addMessageNtc({
          id: uuidv4(),
          type: TYPE_NOTIFICATION.Info,
          message: CONDITION_PRODUCT_MESSAGE
        })
      );
      const element: HTMLElement | null = document.getElementById(
        formatId(newQuestionId)
      );
      element?.scrollIntoView();
    }
  }, [allQuestions, newQuestionId, dispatch]);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    trigger,
    formState,
    unregister
  }: UseFormReturn<IFormFields> = useForm<IFormFields>({
    resolver: validationResolver,
    context: getFieldsValidationMap(questions)
  });

  const { errors, isSubmitted, isDirty }: FormState<IFormFields> = formState;

  const formValues = watch();

  const doAutoSave = useCallback((): void => {
    if (
      !isCaseUpdating &&
      !isFieldsDisabled &&
      !isSaveDataInProgress &&
      saveDraftStatusState.status === IDraftStatuses.UNSAVED_CHANGES
    ) {
      dispatch(
        actions.doAutoSave.started({
          caseId,
          section,
          data: formValues,
          questions: allQuestions,
          draftTroubleshootingHistory: troubleShootHistory
        })
      );
    }
  }, [
    allQuestions,
    caseId,
    dispatch,
    formValues,
    isCaseUpdating,
    isFieldsDisabled,
    isSaveDataInProgress,
    saveDraftStatusState.status,
    section,
    troubleShootHistory
  ]);

  const unregisterQuestionFields = useCallback(
    (question: IQuestion | null) => {
      question?.answers.forEach((answer) => {
        answer.fields.forEach((deletedQuestionField) => {
          const fieldKey = buildFieldKey({
            questionId: question.questionId,
            answerId: answer.answerId,
            fieldId: deletedQuestionField.fieldId,
            type: deletedQuestionField.type,
            fieldType: deletedQuestionField.fieldType,
            isMulti: deletedQuestionField.isMulti
          });
          unregister(fieldKey);
        });
      });
    },
    [unregister]
  );

  const unregisterPotentiallyDeletedQuestionAnswers = useCallback(
    (prevQuestions: IQuestion[], newQuestions: IQuestion[]) => {
      prevQuestions.forEach((prevQuestion) => {
        newQuestions.forEach((newQuestion) => {
          if (prevQuestion.questionId === newQuestion.questionId) {
            prevQuestion.answers.forEach((prevQuestionAnswer) => {
              const isPrevQuestionAnswerStillExist = newQuestion.answers.find(
                (currentQuestionAnswer) =>
                  currentQuestionAnswer.answerId === prevQuestionAnswer.answerId
              );

              if (!isPrevQuestionAnswerStillExist) {
                prevQuestionAnswer.fields.forEach((prevQuestionAnswerField) => {
                  const fieldKey = buildFieldKey({
                    questionId: prevQuestion.questionId,
                    answerId: prevQuestionAnswer.answerId,
                    fieldId: prevQuestionAnswerField.fieldId,
                    type: prevQuestionAnswerField.type,
                    fieldType: prevQuestionAnswerField.fieldType,
                    isMulti: prevQuestionAnswerField.isMulti
                  });
                  unregister(fieldKey);
                });
              }
            });
          }
        });
      });
    },
    [unregister]
  );

  useEffect(() => {
    if (prevIsCaseUpdating && !isCaseUpdating && prevAllQuestions) {
      const { conditionalQuestionId, isQuestionAdded, isQuestionDeleted } =
        checkConditionalProductQuestionsTriggered(questions, prevAllQuestions);
      const isRegularUserCaseSubmitClick =
        !conditionalQuestionId && isSubmitClicked;

      if (isRegularUserCaseSubmitClick) {
        dispatch(
          actions.clearSectionDraftValues.started({
            section,
            caseId,
            history: troubleShootHistory
          })
        );
        return;
      }

      if (conditionalQuestionId) {
        if (isQuestionAdded) {
          setNewQuestionId(conditionalQuestionId);
        }

        if (isQuestionDeleted) {
          const deletedQuestion =
            prevAllQuestions.find(
              (i) => i.questionId === conditionalQuestionId
            ) || null;
          unregisterQuestionFields(deletedQuestion);
        }
      }

      setAllQuestions(questions);
      unregisterPotentiallyDeletedQuestionAnswers(prevAllQuestions, questions);
    }
    return (): void => setNewQuestionId(undefined);
  }, [
    isCaseUpdating,
    prevIsCaseUpdating,
    prevAllQuestions,
    questions,
    dispatch,
    section,
    caseId,
    isSubmitClicked,
    unregisterQuestionFields,
    unregisterPotentiallyDeletedQuestionAnswers,
    troubleShootHistory
  ]);

  useInterval(doAutoSave, INTERVAL_OF_SAVING_DRAFT_DATA);

  const checkFieldConditions = useCallback(
    ({
      fieldKey = "",
      value = "",
      unregisteredFields = []
    }: CheckConditionsProps): void => {
      setAllQuestions((prevQuestions) =>
        getUpdatedFieldsVisibility({
          questions: prevQuestions,
          fieldKey,
          value,
          formValues,
          unregisteredFields
        })
      );
    },
    [formValues]
  );

  const checkQuestionConditions = useCallback(
    ({
      fieldKey,
      value,
      hasDependentFields
    }: CheckQuestionConditionsProps): void => {
      setAllQuestions((prevQuestions) =>
        getUpdatedQuestionsVisibility({
          triggeredFieldKey: fieldKey,
          triggeredFieldKeyValue: value,
          questions: prevQuestions,
          formValues: formValues,
          registerField: register,
          unregisterField: unregister
        })
      );

      if (hasDependentFields) {
        checkFieldConditions({ fieldKey, value });
      }
    },
    [checkFieldConditions, formValues, register, unregister]
  );

  const registerFields = useCallback(
    (questionsValue: IQuestion[]): void => {
      const unregisteredFields: string[] = [];
      questionsValue
        .filter((question: IQuestion) => question.isVisible)
        .forEach((question: IQuestion): void => {
          question.answers.forEach((answer: IAnswer): void => {
            answer.fields.forEach((field: IFieldAnswer): void => {
              const fieldKey = buildFieldKey({
                questionId: question.questionId,
                answerId: answer.answerId,
                fieldId: field.fieldId,
                type: field.type,
                fieldType: field.fieldType,
                isMulti: field.isMulti
              });
              const isRegistered: boolean = Object.keys(formValues).some(
                (key: string) => key === fieldKey
              );
              if (isRegistered && answer.isDeleted) {
                return unregister(fieldKey);
              }
              if (isGeneralUploadField(field.type) || answer.isDeleted) {
                return undefined;
              }
              if (field.isVisible && !isRegistered) {
                register(fieldKey);
                if (!isUndefinedOrNull(field.value)) {
                  setValue(fieldKey, field.value, { shouldDirty: true });
                }
              }
              if (!field.isVisible && isRegistered) {
                unregister(fieldKey);
                if (field.hasDependentFields) {
                  unregisteredFields.push(fieldKey);
                }
              }
              return undefined;
            });
          });
        });

      if (unregisteredFields.length) {
        checkFieldConditions({ unregisteredFields });
      }
    },
    [register, setValue, formValues, unregister, checkFieldConditions]
  );

  useEffect((): void => {
    if (!countryOptions.length) {
      const isNeedCountries: boolean = questions.some((question: IQuestion) =>
        question.fields.some(
          (field: IFieldAnswer) =>
            field.type === FieldType.COUNTRY ||
            field.type === FieldType.ADDRESS ||
            field.type === FieldType.COMPANY
        )
      );
      if (isNeedCountries && !areCountriesLoading) {
        dispatch(actions.getCountries.started());
      }
    }
  }, [areCountriesLoading, dispatch, questions, countryOptions.length]);

  useEffect((): void => {
    registerFields(allQuestions);
  }, [registerFields, allQuestions]);

  useEffect((): void => {
    if (isSubmitClicked && Object.keys(errors).length) {
      const elementId: string = getFirstFieldIdByQuestions(
        allQuestions,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors as any
      );
      const element: HTMLElement | null = document.getElementById(elementId);
      element?.scrollIntoView();
      setIsSubmitClicked(false);
    }
  }, [errors, isSubmitClicked, allQuestions]);

  const handleCloseConfirmModal = useCallback(
    () => setAnswerToDelete(null),
    []
  );

  const handleCloseScotlandLocationPopUp = useCallback(() => {
    setIsScotlandLocationPopUpVisible(false);
  }, []);

  const onSubmit = useCallback(
    (data: IFormFields): void => {
      if (!Object.keys(answersErrors).length) {
        dispatch(
          actions.caseUpdate.started({
            caseInput: buildInputForCaseUpdate(data, allQuestions, caseId)
          })
        );
      }
    },
    [answersErrors, dispatch, allQuestions, caseId]
  );

  const onSubmitConditionalProduct = useCallback(
    (fieldKey: string, data: IFormFields): void => {
      const questionToSubmit = getConditionalProductQuestionToSubmit(
        fieldKey,
        allQuestions
      );

      if (questionToSubmit) {
        dispatch(
          actions.caseUpdate.started({
            caseInput: buildInputForCaseUpdate(data, [questionToSubmit], caseId)
          })
        );
      }
    },
    [dispatch, allQuestions, caseId]
  );
  const onSubmitConditionalProductDebounced = useMemo(
    () => debounce(onSubmitConditionalProduct, 700),
    [onSubmitConditionalProduct]
  );

  const defaultSubmitClickHandler = useCallback(() => {
    setIsSubmitClicked(true);
    return handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  const handleSubmitClick = useCallback(() => {
    if (!isCheckOfAddressHistoryOverlapRequired || !hasAddressOverlaps) {
      void defaultSubmitClickHandler();
    } else {
      setIsScotlandLocationPopUpVisible(true);
    }
  }, [
    defaultSubmitClickHandler,
    hasAddressOverlaps,
    isCheckOfAddressHistoryOverlapRequired
  ]);

  const handleSetValue = useCallback(
    (
      hasDependentFields: boolean,
      hasGapRestriction: boolean,
      fieldKey: string,
      value: IFieldValue,
      answerIndex: number,
      isScotlandLocation: boolean
    ): void => {
      const { fieldId } = parseFieldKey(fieldKey);
      const shouldFieldTriggerCaseUpdate = checkIfFieldShouldTriggerCaseUpdate({
        fieldId,
        section,
        sectionsByAffectingField: sectionsByAffectingField || []
      });

      setValue(fieldKey, value, { shouldDirty: true });

      setTroubleShootHistory(
        updateTroubleShootPartialHistory({
          fieldKey,
          value,
          historyRecords: troubleShootHistory,
          answerIndex
        })
      );

      updateSaveDraftStatus(IDraftStatuses.UNSAVED_CHANGES);

      if (
        shouldFieldTriggerCaseUpdate &&
        isConditionalProductsSubmitEnabled()
      ) {
        return onSubmitConditionalProductDebounced(fieldKey, {
          ...formValues,
          [fieldKey]: value
        });
      }

      if (isSubmitted) {
        void trigger();
      }

      checkQuestionConditions({
        fieldKey,
        value,
        hasDependentFields,
        unregisteredFields: []
      });

      if (hasGapRestriction) {
        setAnswersErrors(
          validateAnswers(allQuestions, { ...formValues, [fieldKey]: value })
        );
      }
      if (isScotlandLocation && value === true) {
        setIsCheckOfAddressHistoryOverlapRequired(true);
      }
      if (isScotlandLocation && value === false) {
        setIsCheckOfAddressHistoryOverlapRequired(false);
      }
    },
    [
      setValue,
      troubleShootHistory,
      updateSaveDraftStatus,
      isSubmitted,
      checkQuestionConditions,
      sectionsByAffectingField,
      trigger,
      section,
      allQuestions,
      onSubmitConditionalProductDebounced,
      formValues
    ]
  );

  const handleConfirmScotlandLocationPopUp = useCallback(() => {
    setIsScotlandLocationPopUpVisible(false);
    void defaultSubmitClickHandler();
  }, [defaultSubmitClickHandler]);

  const handleDeleteAnswer = useCallback(
    ({ questionId, answerId, index }: IAnswerToDelete): void => {
      updateSaveDraftStatus(IDraftStatuses.UNSAVED_CHANGES);

      setTroubleShootHistory(
        (prevTroubleShootHistory: IPartialTroubleShootHistoryRecord[]) =>
          prevTroubleShootHistory.map(
            (record: IPartialTroubleShootHistoryRecord) =>
              record.answerId === answerId && index === record.index
                ? { ...record, to: undefined, isDeleted: true }
                : record
          )
      );

      const newAllQuestions: IQuestion[] = allQuestions.map(
        (prevQuestion: IQuestion) => {
          if (prevQuestion.questionId === questionId) {
            return {
              ...prevQuestion,
              answers: prevQuestion.answers.map((answer: IAnswer) =>
                answer.answerId === answerId
                  ? { ...answer, isDeleted: true }
                  : answer
              )
            };
          }
          return prevQuestion;
        }
      );
      setAllQuestions(newAllQuestions);
    },
    [allQuestions, updateSaveDraftStatus]
  );

  const handleConfirmDeleteAnswer = useCallback((): void => {
    if (answerToDelete) {
      handleDeleteAnswer(answerToDelete);
      handleCloseConfirmModal();
    }
  }, [answerToDelete, handleDeleteAnswer, handleCloseConfirmModal]);

  const handleDeleteAnswerClick = useCallback(
    (questionId: string, answerId: string, index: number) => (): void => {
      setAnswerToDelete({ questionId, answerId, index });
    },
    []
  );

  const onAddEntry = useCallback(
    (question: IQuestion) => (): void => {
      updateSaveDraftStatus(IDraftStatuses.UNSAVED_CHANGES);

      const newAllQuestions: IQuestion[] = allQuestions.map(
        (prevQuestion: IQuestion): IQuestion => {
          if (prevQuestion.questionId === question.questionId) {
            const answerId: string = uuidv4();

            const index: number = prevQuestion.answers.length;

            setTroubleShootHistory(
              (
                prevTroubleShootHistory: IPartialTroubleShootHistoryRecord[]
              ) => [
                ...prevTroubleShootHistory,
                ...question.fields.map((field: IFieldAnswer) => ({
                  type: CaseTroubleshoootingHistoryType.FIELD_VALUE_UPDATED,
                  answerId,
                  isDeleted: false,
                  fieldId: field.fieldId,
                  fieldType: field.type,
                  isMulti: field.isMulti,
                  to: undefined,
                  from: undefined,
                  index
                }))
              ]
            );
            return {
              ...prevQuestion,
              answers: [
                ...prevQuestion.answers,
                {
                  index,
                  answerId,
                  isAnswered: false,
                  fields: question.fields.map((field: IFieldAnswer) => ({
                    ...field,
                    answerIndex: index
                  }))
                }
              ]
            };
          }
          return prevQuestion;
        }
      );
      setAllQuestions(newAllQuestions);
    },
    [allQuestions, updateSaveDraftStatus]
  );

  useMount((): void =>
    setTroubleShootHistory(
      convertQuestionsToPartialFieldHistoryRecords(allQuestions)
    )
  );

  useEffect(() => {
    if (!isSaveDataInProgress && prevIsSaveDataInProgress) {
      setTroubleShootHistory(
        (prevHistoryRecords: IPartialTroubleShootHistoryRecord[]) =>
          prevHistoryRecords.map(
            (historyRecord: IPartialTroubleShootHistoryRecord) =>
              historyRecord.to !== undefined
                ? { ...historyRecord, from: historyRecord.to, to: undefined }
                : historyRecord
          )
      );
    }
  }, [allQuestions, isSaveDataInProgress, prevIsSaveDataInProgress]);

  const goToBack = useCallback((): void => {
    doAutoSave();
    navigateTo({
      pathname: backPath || ROUTES.ROOT,
      search: window.location.search
    });
  }, [navigateTo, backPath, doAutoSave]);

  const getValue = useCallback(
    (questionId: string, answerId: string, field: IFieldAnswer) => {
      const fieldKey = buildFieldKey({
        questionId,
        answerId,
        fieldId: field.fieldId,
        type: field.type,
        fieldType: field.fieldType,
        isMulti: field.isMulti
      });

      return formValues[fieldKey];
    },
    [formValues]
  );

  const getErrorMessage = useCallback(
    (questionId: string, answerId: string, field: IFieldAnswer): string => {
      const fieldKey = buildFieldKey({
        questionId,
        answerId,
        fieldId: field.fieldId,
        type: field.type,
        fieldType: field.fieldType,
        isMulti: field.isMulti
      });

      if (isSubmitted) {
        return ((errors[fieldKey] && errors[fieldKey]?.message
          ? errors[fieldKey]?.message
          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
            errors[fieldKey]) || answersErrors[fieldKey]) as any;
      }
      return errors[fieldKey] && errors[fieldKey]?.message
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (errors[fieldKey]?.message as any)
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (errors[fieldKey] as any);
    },
    [errors, answersErrors, isSubmitted]
  );

  const checkHasNotUploadFields = useCallback(
    (fields: IFieldAnswer[]): boolean =>
      fields.some(
        (field: IFieldAnswer): boolean => !isGeneralUploadField(field.type)
      ),
    []
  );

  const renderFields = useCallback(
    (fields: IFieldAnswer[], questionId: string, answerId: string) =>
      fields
        .filter((field) => field.isVisible && !isGeneralUploadField(field.type))
        .map((field) => (
          <AnswerField
            isOcrUploaded={isOcrUploaded}
            key={`${answerId}${field.fieldId}`}
            field={field}
            questionId={questionId}
            answerId={answerId}
            value={getValue(questionId, answerId, field)}
            errorMessage={getErrorMessage(questionId, answerId, field)}
            onChange={handleSetValue}
            countryOptions={countryOptions}
            isDisabled={isFieldsDisabled}
          />
        )),
    [
      countryOptions,
      getErrorMessage,
      getValue,
      handleSetValue,
      isFieldsDisabled,
      isOcrUploaded
    ]
  );

  const renderAnswer = useCallback(
    (answer: IAnswer, questionId: string, removable: boolean) => (
      <s.AnswerWrapper key={answer.answerId}>
        <s.AnswerActionContainer>
          {!answer.isCandidateAnswer &&
            answer.isCandidateAnswer !== undefined && (
              <s.CardTag>Answered by screener</s.CardTag>
            )}
          {removable && (
            <Button
              isDisabled={isFieldsDisabled}
              buttonType={BUTTON_TYPE.Link}
              icon={
                <TrashBinIcon
                  size={16}
                  color={
                    isFieldsDisabled
                      ? theme.colors["color-icon-disabled"]
                      : theme.colors["color-action-critical-default"]
                  }
                />
              }
              onClick={handleDeleteAnswerClick(
                questionId,
                answer.answerId,
                answer.index
              )}
            >
              <span>Delete answer</span>
            </Button>
          )}
        </s.AnswerActionContainer>
        {renderFields(answer.fields, questionId, answer.answerId)}
      </s.AnswerWrapper>
    ),
    [theme.colors, isFieldsDisabled, handleDeleteAnswerClick, renderFields]
  );

  return (
    <>
      <MetaData title={title} />

      <CommonHeader
        isSticky
        title={title}
        sectionStatus={sectionStatus}
        saveDraftStatus={saveDraftStatusState.status}
        ref={commonHeaderRef}
      />

      <ScreenerAccess />

      <ContentLayout isSticky>
        <ButtonBack ariaLabel={"save & back"} handlerClick={goToBack}>
          Save & Back
        </ButtonBack>
        <s.HeaderWrapper ref={headerWrapperRef}>
          <PageHeader title={title} description={description} />
          {subTitle}
          <SectionStatus
            sectionStatus={sectionStatus}
            saveDraftStatus={saveDraftStatusState.status}
            role="status"
          />
        </s.HeaderWrapper>
        <AccessibilitySkipLink
          name="Skip to content"
          skipTo="#submit-section-btn"
        />
        <s.QuestionsContainer aria-label={title}>
          {allQuestions
            .filter((question: IQuestion) => question.isVisible)
            .map((question: IQuestion) => {
              const notRemovedAnswers: IAnswer[] = question.answers.filter(
                (answer: IAnswer) => !answer.isDeleted
              );
              return (
                <Fragment key={question.questionId}>
                  {checkHasNotUploadFields(question.fields) && (
                    <s.QuestionWrapper id={formatId(question.questionId)}>
                      <div>
                        {notRemovedAnswers.map((answer: IAnswer) =>
                          renderAnswer(
                            answer,
                            question.questionId,
                            Boolean(notRemovedAnswers.length > 1)
                          )
                        )}
                      </div>
                      {question.isMultipleAnswers && (
                        <s.QuestionActionContainer>
                          <Button
                            buttonType={BUTTON_TYPE.Link}
                            onClick={onAddEntry(question)}
                            isDisabled={isFieldsDisabled}
                            icon={
                              <PlusSmallIcon
                                size={16}
                                color={
                                  isFieldsDisabled
                                    ? theme.colors["color-icon-disabled"]
                                    : theme.colors[
                                        "color-action-primary-default"
                                      ]
                                }
                              />
                            }
                          >
                            Add Entry
                          </Button>
                        </s.QuestionActionContainer>
                      )}
                    </s.QuestionWrapper>
                  )}
                </Fragment>
              );
            })}
        </s.QuestionsContainer>
        <s.CustomButton
          isLoading={isCaseUpdating}
          id="submit-section-btn"
          isDisabled={!isDirty || isFieldsDisabled || isSaveDataInProgress}
          onClick={handleSubmitClick}
          buttonType={BUTTON_TYPE.Primary}
        >
          Complete
        </s.CustomButton>
        <ConfirmModal
          isShowModal={Boolean(answerToDelete)}
          primaryBtnText="Remove answer"
          confirmAction={handleConfirmDeleteAnswer}
          onModalClose={handleCloseConfirmModal}
        >
          Are you sure you want to remove this answer?
        </ConfirmModal>
        <ConfirmModal
          isShowModal={Boolean(answerToDelete)}
          primaryBtnText="Remove answer"
          confirmAction={handleConfirmDeleteAnswer}
          onModalClose={handleCloseConfirmModal}
        >
          Are you sure you want to remove this answer?
        </ConfirmModal>
        <ConfirmModal
          isShowModal={isScotlandLocationPopUpVisible}
          primaryBtnText="Confirm"
          confirmAction={handleConfirmScotlandLocationPopUp}
          onModalClose={handleCloseScotlandLocationPopUp}
        >
          This change will require you to update your address history. Are you
          sure you wish to proceed?
        </ConfirmModal>
      </ContentLayout>
    </>
  );
};

export const SectionDetails: NamedExoticComponent<IProps> = React.memo<IProps>(
  SectionDetailsComponent
);
