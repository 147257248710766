import React, { FC } from "react";
import * as s from "./styles";
import { CheckListItem } from "containers/SuccessSubmit/components/CandidateChecks/components/CheckList/CheckListItem";
import {
  CandidateCaseStatus,
  GetCaseInformation_screeningCase_products
} from "src/graphQLTypes";
import { CheckAttentionAlert } from "containers/SuccessSubmit/components/CandidateChecks/components";
import { OpenedProduct } from "containers/SuccessSubmit/hooks";

type Props = {
  products: GetCaseInformation_screeningCase_products[];
  caseStatus?: CandidateCaseStatus | null;
  onActionClick: (product: OpenedProduct) => void;
};

export const CheckList: FC<Props> = ({
  products,
  caseStatus,
  onActionClick
}) => (
  <>
    {caseStatus === CandidateCaseStatus.CANDIDATE_ACTION_REQUIRED && (
      <CheckAttentionAlert />
    )}
    <h2 className="h2 margin-t-6">Your checks</h2>
    <p className="body-wide-line color-text-subdued">
      Here are the checks we have been asked to undertake. If anything is needed
      from you we will mark these as &quot;Awaiting Candidate&quot;.
    </p>
    <s.CheckListContainer role="list" aria-label="Checks">
      {products.map((product) => (
        <CheckListItem
          key={product.id}
          product={product}
          onActionClick={onActionClick}
        />
      ))}
    </s.CheckListContainer>
  </>
);
