import React, { useState } from "react";
import {
  Button,
  BUTTON_TYPE,
  EditSmallIcon,
  TextArea,
  theme
} from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";
import { IProps } from "./types";

export const AddNoteToScreener: React.FC<IProps> = ({
  noteForScreener,
  setNoteForScreener
}: IProps) => {
  const [isTextAreaVisible, setIsTextAreaVisible] = useState<boolean>(false);

  const setTextAreaVisibility = (): void =>
    setIsTextAreaVisible(!isTextAreaVisible);

  const handleSetNote = (e: React.ChangeEvent<HTMLTextAreaElement>): void =>
    setNoteForScreener(e.target.value);

  const handleCancelButtonClick = (): void => {
    setNoteForScreener("");
    setTextAreaVisibility();
  };

  return (
    <s.Container>
      {!isTextAreaVisible ? (
        <Button
          buttonType={BUTTON_TYPE.Link}
          onClick={setTextAreaVisibility}
          icon={
            <EditSmallIcon
              size={16}
              color={theme.colors["color-action-primary-default"]}
            />
          }
        >
          {`${noteForScreener ? "Edit" : "Add"} note to the screening team`}
        </Button>
      ) : (
        <>
          <s.TextAreaHeadingWrapper>
            <s.TextAreaTitle>Note to a screener</s.TextAreaTitle>
            <Button
              buttonType={BUTTON_TYPE.Link}
              onClick={handleCancelButtonClick}
            >
              Cancel
            </Button>
          </s.TextAreaHeadingWrapper>
          <TextArea
            value={noteForScreener}
            onChange={handleSetNote}
            maxLength={280}
          />
        </>
      )}
    </s.Container>
  );
};
