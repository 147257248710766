import { CandidateCaseProductStatus } from "./graphQLTypesOld";
import { CandidateCaseStatus } from "./graphQLTypesOld";
import { CandidatePortalAuthFlow } from "./graphQLTypesOld";
import { CaseTroubleshoootingHistoryType } from "./graphQLTypesOld";
import { CompOperator } from "./graphQLTypesOld";
import { ConsentType } from "./graphQLTypesOld";
import { DateUnit } from "./graphQLTypesOld";
import { FieldType } from "./graphQLTypesOld";
import { HandleableFieldType } from "./graphQLTypesOld";
import { OpsEbulkFieldType } from "./graphQLTypesOld";
import { OpsOcrFieldType } from "./graphQLTypesOld";
import { Section } from "./graphQLTypesOld";
import { TroubleshootingRequestStatus } from "./graphQLTypesOld";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  Long: { input: number; output: number };
  Upload: { input: File; output: File };
};

export type AdditionalCandidateAttachments = {
  __typename?: "AdditionalCandidateAttachments";
  candidateAttachments: Array<Attachment>;
  note?: Maybe<Scalars["String"]["output"]>;
  whenUploaded: Scalars["Long"]["output"];
};

export type Address = {
  __typename?: "Address";
  addressLine1: Scalars["String"]["output"];
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  addressLine3?: Maybe<Scalars["String"]["output"]>;
  country: Country;
  county?: Maybe<Scalars["String"]["output"]>;
  postcode: Scalars["String"]["output"];
  town: Scalars["String"]["output"];
};

export type AddressInput = {
  addressLine1: Scalars["String"]["input"];
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  addressLine3?: InputMaybe<Scalars["String"]["input"]>;
  countryId: Scalars["ID"]["input"];
  county?: InputMaybe<Scalars["String"]["input"]>;
  postcode: Scalars["String"]["input"];
  town: Scalars["String"]["input"];
};

/** Alert defined for the product. */
export type Alert = Entity & {
  __typename?: "Alert";
  code: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  severity: AlertSeverity;
  title: Scalars["String"]["output"];
};

export enum AlertSeverity {
  ERROR = "ERROR",
  WARNING = "WARNING"
}

export type AnswerDetails = {
  fieldValues: Array<FieldValue>;
  id: Scalars["ID"]["output"];
  isCandidateAnswer: Scalars["Boolean"]["output"];
};

export type AnswerDetailsInput = {
  fields: Array<FieldValueInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  removeAnswerDetails?: InputMaybe<RemoveAnswerDetailsInput>;
};

export type AnswerInput = {
  answers?: InputMaybe<Array<AnswerDetailsInput>>;
  questionId: Scalars["ID"]["input"];
};

export type Attachment = Entity & {
  __typename?: "Attachment";
  downloadUrl: Scalars["String"]["output"];
  fileName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  type?: Maybe<DocumentType>;
  uploadDate: Scalars["Long"]["output"];
  uploadedBy: Scalars["String"]["output"];
};

export type AttachmentInput = {
  activationRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  downloadUrl: Scalars["String"]["input"];
  fileName: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  uploadDate: Scalars["Long"]["input"];
  uploadedBy: Scalars["String"]["input"];
};

export type Attachments = {
  __typename?: "Attachments";
  value: Array<Attachment>;
};

export type BaseAnswer = {
  answers: Array<AnswerDetails>;
  question: BaseQuestion;
};

/** TODO investigate why interface with name Product leads to error on BFF side */
export type BaseProduct = {
  id: Scalars["ID"]["output"];
};

/** TODO investigate why interface with name Question leads to error on BFF side */
export type BaseQuestion = {
  conditionalElements: Array<QuestionConditionalFields>;
  fields: Array<Field>;
  id: Scalars["ID"]["output"];
  /** Whether the user can answer the question multiple times, like previous job */
  multipleAnswers: Scalars["Boolean"]["output"];
  /** Identity proof needs special wording/etc at the candidate portal. */
  questionType: QuestionType;
  title: Scalars["String"]["output"];
};

export type BooleanValue = {
  __typename?: "BooleanValue";
  value: Scalars["Boolean"]["output"];
};

export type Candidate = {
  __typename?: "Candidate";
  emailAddress: Scalars["String"]["output"];
  externalReferenceNumber?: Maybe<Scalars["String"]["output"]>;
  firstName: Scalars["String"]["output"];
  gender?: Maybe<Gender>;
  lastName: Scalars["String"]["output"];
  middleName?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<PersonTitle>;
};

export type CandidateAnswer = BaseAnswer & {
  __typename?: "CandidateAnswer";
  answers: Array<CandidateAnswerDetails>;
  conditionFulfilled: Scalars["Boolean"]["output"];
  question: CandidateQuestion;
};

export type CandidateAnswerDetails = AnswerDetails & {
  __typename?: "CandidateAnswerDetails";
  fieldValues: Array<CandidateFieldValue>;
  id: Scalars["ID"]["output"];
  isCandidateAnswer: Scalars["Boolean"]["output"];
};

export type CandidateAnswersCondition = {
  __typename?: "CandidateAnswersCondition";
  maxNumberOfAnswers?: Maybe<Scalars["Int"]["output"]>;
  minNumberOfAnswers?: Maybe<Scalars["Int"]["output"]>;
};

export type CandidateCase = {
  __typename?: "CandidateCase";
  additionalCandidateAttachments: Array<AdditionalCandidateAttachments>;
  assignee?: Maybe<Person>;
  candidate: Candidate;
  client: CandidateClient;
  code: Scalars["String"]["output"];
  consentForm?: Maybe<Attachment>;
  consentGiven: Scalars["Boolean"]["output"];
  consentText?: Maybe<Scalars["String"]["output"]>;
  consentType: ConsentType;
  contract: CandidateContract;
  /** Assumption: deadline is hard-coded number of days for now */
  deadline: Scalars["Long"]["output"];
  draft?: Maybe<Scalars["String"]["output"]>;
  eSignature?: Maybe<Attachment>;
  hasEnhancedCriminalityCheck: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  jobCountry: Country;
  ocrTriggered: Scalars["Boolean"]["output"];
  originalConsentForm?: Maybe<Attachment>;
  products: Array<CandidateCaseProduct>;
  sections: Array<CandidateDataSection>;
  sectionsByAffectingField: Array<SectionsByAffectingField>;
  status: CandidateCaseStatus;
  troubleshotByScreener: Scalars["Boolean"]["output"];
  trustIdApplication?: Maybe<CandidateTrustIdApplication>;
  whenCompleted?: Maybe<Scalars["Long"]["output"]>;
  whenCreated: Scalars["Long"]["output"];
};

export type CandidateCaseProduct = CaseProduct &
  Entity & {
    __typename?: "CandidateCaseProduct";
    candidateDataRequest?: Maybe<CandidateDataRequest>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    product: CandidateProduct;
    status: CandidateCaseProductStatus;
  };

export { CandidateCaseProductStatus };

export type CandidateCaseSaveDraftInput = {
  caseId: Scalars["ID"]["input"];
  draft: Scalars["String"]["input"];
  draftTroubleshootingHistory?: InputMaybe<
    Array<InputMaybe<CaseTroubleshootingHistoryInput>>
  >;
};

export type CandidateCaseSaveDraftPayload = {
  __typename?: "CandidateCaseSaveDraftPayload";
  case: CandidateCase;
};

export { CandidateCaseStatus };

export type CandidateCaseSubmitInput = {
  caseId: Scalars["ID"]["input"];
};

export type CandidateCaseSubmitPayload = {
  __typename?: "CandidateCaseSubmitPayload";
  case: CandidateCase;
};

export type CandidateCaseTroubleshootingRequest = {
  __typename?: "CandidateCaseTroubleshootingRequest";
  createdBy: Person;
  createdWhen: Scalars["Long"]["output"];
  id: Scalars["ID"]["output"];
  status: TroubleshootingRequestStatus;
};

export type CandidateCaseTroubleshootingRequestConnection = {
  __typename?: "CandidateCaseTroubleshootingRequestConnection";
  troubleshootingRequest?: Maybe<CandidateCaseTroubleshootingRequest>;
};

export type CandidateCaseTroubleshootingRequestUpdateInput = {
  caseId: Scalars["ID"]["input"];
  id: Scalars["String"]["input"];
  status: TroubleshootingRequestStatus;
};

export type CandidateCaseTroubleshootingRequestUpdatePayload = {
  __typename?: "CandidateCaseTroubleshootingRequestUpdatePayload";
  troubleshootingRequest: CandidateCaseTroubleshootingRequest;
};

export type CandidateCaseUpdateInput = {
  /** Answers one or more fields. The answers are accumulated. */
  answers: Array<AnswerInput>;
  caseId: Scalars["ID"]["input"];
  /** Index of consent form Upload */
  consentFormUploadIndex?: InputMaybe<Scalars["Int"]["input"]>;
  consentGiven: Scalars["Boolean"]["input"];
  /** Index of e-signature Upload */
  eSignatureUploadIndex?: InputMaybe<Scalars["Int"]["input"]>;
  parseAnswers?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CandidateCaseUpdatePayload = {
  __typename?: "CandidateCaseUpdatePayload";
  case: CandidateCase;
};

export type CandidateClient = {
  __typename?: "CandidateClient";
  id: Scalars["ID"]["output"];
  liveChatEnabled: Scalars["Boolean"]["output"];
  logo?: Maybe<Attachment>;
  name: Scalars["String"]["output"];
};

export enum CandidateContactChannel {
  CALL = "CALL",
  EMAIL = "EMAIL",
  TEXT = "TEXT"
}

export type CandidateContract = Contract &
  Entity & {
    __typename?: "CandidateContract";
    id: Scalars["ID"]["output"];
    support: CandidateContractSupport;
  };

export type CandidateContractSupport = {
  __typename?: "CandidateContractSupport";
  supportEmailAddress: Scalars["String"]["output"];
  supportPhoneNumber: Scalars["String"]["output"];
};

export type CandidateDataReplyContent = {
  __typename?: "CandidateDataReplyContent";
  attachments: Array<Attachment>;
  content: Scalars["String"]["output"];
  createdAt: Scalars["Long"]["output"];
};

export type CandidateDataRequest = {
  __typename?: "CandidateDataRequest";
  attachments: Array<Attachment>;
  attachmentsAllowed: Scalars["Boolean"]["output"];
  content: Scalars["String"]["output"];
  createdAt: Scalars["Long"]["output"];
  createdBy: Person;
  id: Scalars["ID"]["output"];
  lastUpdatedAt: Scalars["Long"]["output"];
  lastUpdatedBy: Person;
  replyData?: Maybe<CandidateDataReplyContent>;
  status: CandidateDataRequestStatus;
};

export enum CandidateDataRequestStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  REPLY_RECEIVED = "REPLY_RECEIVED"
}

export type CandidateDataSection = DataSection & {
  __typename?: "CandidateDataSection";
  /**
   * Provides information for both questions and optional answers.
   * If some question allows multiple answers then several Answer structures come with the same questionId in this array.
   */
  answers: Array<CandidateAnswer>;
  /** Whether all questions in this section has been answered. */
  completed: Scalars["Boolean"]["output"];
  section: Section;
};

export type CandidateField = Field & {
  __typename?: "CandidateField";
  conditional: Scalars["Boolean"]["output"];
  description: LocalizedString;
  fieldType: HandleableFieldType;
  /** Field ID within question. */
  id: Scalars["ID"]["output"];
  opsFields: Array<OpsField>;
  /** Number of fractional digits 0-5, if the field type is Number */
  required: Scalars["Boolean"]["output"];
  /** Field value restrictions */
  restrictions: Array<FieldRestriction>;
  title: LocalizedString;
  type: FieldType;
};

export type CandidateFieldCondition = FieldCondition & {
  __typename?: "CandidateFieldCondition";
  fieldValue: CandidateFieldValue;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type CandidateFieldValue = FieldValue & {
  __typename?: "CandidateFieldValue";
  field: CandidateField;
  skip: Scalars["Boolean"]["output"];
  value?: Maybe<FieldAnyValue>;
};

export type CandidateKonfirVerificationLinkCreateInput = {
  caseId: Scalars["ID"]["input"];
};

export type CandidateKonfirVerificationLinkCreatePayload = {
  __typename?: "CandidateKonfirVerificationLinkCreatePayload";
  verificationLink: Scalars["String"]["output"];
};

export { CandidatePortalAuthFlow };

export type CandidatePortalAuthFlowPayload = {
  __typename?: "CandidatePortalAuthFlowPayload";
  authFlow: CandidatePortalAuthFlow;
};

export type CandidateProduct = BaseProduct & {
  __typename?: "CandidateProduct";
  conditionalQuestions: Array<CandidateProductConditionalQuestion>;
  conditionalValidations: Array<CandidateProductConditionalValidation>;
  id: Scalars["ID"]["output"];
};

export type CandidateProductConditionalQuestion = ProductConditionalQuestion & {
  __typename?: "CandidateProductConditionalQuestion";
  answersCondition?: Maybe<CandidateAnswersCondition>;
  conditions: Array<CandidateFieldCondition>;
  id?: Maybe<Scalars["ID"]["output"]>;
  /** Questions to be enabled when condition is true. */
  question: CandidateQuestion;
};

export type CandidateProductConditionalValidation = {
  __typename?: "CandidateProductConditionalValidation";
  answersCondition?: Maybe<CandidateAnswersCondition>;
  conditions: Array<CandidateFieldCondition>;
  id?: Maybe<Scalars["ID"]["output"]>;
  validationMessage: Scalars["String"]["output"];
};

export type CandidateProfile = {
  __typename?: "CandidateProfile";
  contactPreference: Array<CandidateContactChannel>;
};

export type CandidateQuestion = BaseQuestion & {
  __typename?: "CandidateQuestion";
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: Scalars["Boolean"]["output"];
  conditionalElements: Array<CandidateQuestionConditionalFields>;
  fields: Array<CandidateField>;
  id: Scalars["ID"]["output"];
  /** Whether the user can answer the question multiple times, like previous job */
  multipleAnswers: Scalars["Boolean"]["output"];
  /** Identity proof needs special wording/etc at the candidate portal. */
  questionType: QuestionType;
  title: Scalars["String"]["output"];
};

export type CandidateQuestionConditionalFields = QuestionConditionalFields & {
  __typename?: "CandidateQuestionConditionalFields";
  conditions: Array<CandidateFieldCondition>;
  /** Fields to be enabled when condition is true. */
  fields: Array<CandidateField>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type CandidateSignUpInput = {
  contactPreference: Array<CandidateContactChannel>;
};

export type CandidateSignUpPayload = {
  __typename?: "CandidateSignUpPayload";
  profile: CandidateProfile;
};

export type CandidateTrustIdApplication = {
  __typename?: "CandidateTrustIdApplication";
  state: TrustIdApplicationState;
};

export type CandidateTrustIdGuestLinkCreateInput = {
  caseId: Scalars["ID"]["input"];
};

export type CandidateTrustIdGuestLinkCreatePayload = {
  __typename?: "CandidateTrustIdGuestLinkCreatePayload";
  guestLink: Scalars["String"]["output"];
};

export type CandidateUploadAdditionalDocumentInput = {
  caseId: Scalars["ID"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
};

export type CandidateUploadAdditionalDocumentPayload = {
  __typename?: "CandidateUploadAdditionalDocumentPayload";
  screeningCaseId: Scalars["String"]["output"];
};

export type CaseProduct = {
  id: Scalars["ID"]["output"];
  /** Product with all overrides applied. */
  product: BaseProduct;
};

export { CaseTroubleshoootingHistoryType };

export type CaseTroubleshootingHistoryInput = {
  caseId: Scalars["ID"]["input"];
  fieldId: Scalars["ID"]["input"];
  from?: InputMaybe<FieldValueInput>;
  index: Scalars["Long"]["input"];
  to?: InputMaybe<FieldValueInput>;
  type: CaseTroubleshoootingHistoryType;
};

export { CompOperator };

export type Company = {
  __typename?: "Company";
  address: Address;
  name: Scalars["String"]["output"];
};

export type CompanyInput = {
  address?: InputMaybe<AddressInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export { ConsentType };

export type Contract = {
  id: Scalars["ID"]["output"];
};

export type Country = Entity & {
  __typename?: "Country";
  countryCode: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isoCode: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type CountryInput = {
  id: Scalars["ID"]["input"];
  isoCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type Currency = Entity & {
  __typename?: "Currency";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  /** Currency symbol, for example, $ for USD. */
  symbol?: Maybe<Scalars["String"]["output"]>;
};

export type DataSection = {
  /**
   * Provides information for both questions and optional answers.
   * If some question allows multiple answers then several Answer structures come with the same questionId in this array.
   */
  answers: Array<BaseAnswer>;
  /** Whether all questions in this section has been answered. */
  completed: Scalars["Boolean"]["output"];
  section: Section;
};

/** Possibly unbound date range. Either "from" or "to" have to be set. */
export type DateRange = {
  __typename?: "DateRange";
  /** From date (inclusive). */
  from?: Maybe<Scalars["Date"]["output"]>;
  /** To date (inclusive). */
  to?: Maybe<Scalars["Date"]["output"]>;
};

/** Possibly unbound date range. Either "from" or "to" have to be set. */
export type DateRangeInput = {
  /** From date (inclusive). */
  from?: InputMaybe<Scalars["Date"]["input"]>;
  /** To date (inclusive). */
  to?: InputMaybe<Scalars["Date"]["input"]>;
};

export type DateRestriction = Entity & {
  __typename?: "DateRestriction";
  comp: CompOperator;
  id: Scalars["ID"]["output"];
  value: Scalars["Date"]["output"];
};

export { DateUnit };

export type DateValue = {
  __typename?: "DateValue";
  value: Scalars["Date"]["output"];
};

export enum DocumentType {
  CERTIFICATE_OF_COMPLETION = "CERTIFICATE_OF_COMPLETION",
  CERTIFICATE_OF_GOOD_CONDUCT = "CERTIFICATE_OF_GOOD_CONDUCT",
  CERTIFICATE_OF_INTERIM_CLEAR = "CERTIFICATE_OF_INTERIM_CLEAR",
  CV = "CV",
  DECLARATION_OF_AUTHORITY = "DECLARATION_OF_AUTHORITY",
  DRIVING_LICENCE = "DRIVING_LICENCE",
  DVLA_RESULT_PDF = "DVLA_RESULT_PDF",
  EDUCATION_PROFESSIONAL_QUALIFICATION_PROFESSIONAL_MEMBERSHIP_CERTIFICATE = "EDUCATION_PROFESSIONAL_QUALIFICATION_PROFESSIONAL_MEMBERSHIP_CERTIFICATE",
  EQUIFAX_RESULT_PDF = "EQUIFAX_RESULT_PDF",
  IDENTITY_DOCUMENT = "IDENTITY_DOCUMENT",
  IMPERIL__APPEAL_FORM = "IMPERIL__APPEAL_FORM",
  IMPERIL__DOCUMENTATION = "IMPERIL__DOCUMENTATION",
  IMPERIL__RISK_MATRIX = "IMPERIL__RISK_MATRIX",
  KONFIR_RESULT_PDF = "KONFIR_RESULT_PDF",
  NOTIFICATION = "NOTIFICATION",
  OTHER = "OTHER",
  PROOF_OF_ADDRESS = "PROOF_OF_ADDRESS",
  SIGNED_DECLARATION_OF_AUTHORITY = "SIGNED_DECLARATION_OF_AUTHORITY",
  TRUST_ID_RESULT_PDF = "TRUST_ID_RESULT_PDF",
  WORKAROUND_INFORMATION = "WORKAROUND_INFORMATION"
}

export type DurationRestriction = Entity & {
  __typename?: "DurationRestriction";
  comp: CompOperator;
  id: Scalars["ID"]["output"];
  unit: DateUnit;
  value: Scalars["Int"]["output"];
};

export type Entity = {
  id: Scalars["ID"]["output"];
};

export type Field = {
  conditional: Scalars["Boolean"]["output"];
  /** Upload fields need description to be displayed in candidates portal My Documents. */
  description: LocalizedString;
  fieldType: HandleableFieldType;
  id: Scalars["ID"]["output"];
  required: Scalars["Boolean"]["output"];
  /** Field value restrictions */
  restrictions: Array<FieldRestriction>;
  title: LocalizedString;
  type: FieldType;
};

export type FieldAnyValue =
  | Address
  | Attachment
  | Attachments
  | BooleanValue
  | Company
  | Country
  | DateRange
  | DateValue
  | FloatValue
  | LongValue
  | QualificationsAndGrades
  | StringValue
  | StringValues;

/** Condition to add poduct or questions based on candidate's answer */
export type FieldCondition = {
  fieldValue: FieldValue;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type FieldRestriction =
  | DateRestriction
  | DurationRestriction
  | GapRestriction
  | LengthRestriction
  | NumericRestriction
  | SelectRestriction;

export { FieldType };

export type FieldValue = {
  field: Field;
  value?: Maybe<FieldAnyValue>;
};

export type FieldValueInput = {
  addressValue?: InputMaybe<AddressInput>;
  attachmentValue?: InputMaybe<AttachmentInput>;
  attachmentValues?: InputMaybe<Array<AttachmentInput>>;
  booleanValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  companyValue?: InputMaybe<CompanyInput>;
  countryValue?: InputMaybe<CountryInput>;
  dateRangeValue?: InputMaybe<DateRangeInput>;
  dateValue?: InputMaybe<Scalars["Date"]["input"]>;
  fieldId: Scalars["ID"]["input"];
  floatValue?: InputMaybe<Scalars["Float"]["input"]>;
  idValue?: InputMaybe<Scalars["ID"]["input"]>;
  longValue?: InputMaybe<Scalars["Long"]["input"]>;
  qualificationsAndGradesValue?: InputMaybe<QualificationsAndGradesInput>;
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
  stringValues?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Sequential Upload parameter index, starting from 0 */
  uploadIndex?: InputMaybe<Scalars["Int"]["input"]>;
  /** Upload indexes for multiple upload field */
  uploadIndexes?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type FloatValue = {
  __typename?: "FloatValue";
  value: Scalars["Float"]["output"];
};

export type GapRestriction = {
  __typename?: "GapRestriction";
  comp: CompOperator;
  /** Check against specified field, or against previous answer for mulit answer question if field not provided */
  fieldToSubtractFrom?: Maybe<Field>;
  id: Scalars["ID"]["output"];
  unit: DateUnit;
  value: Scalars["Int"]["output"];
};

export type Gender = Entity & {
  __typename?: "Gender";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type GradeTable = {
  __typename?: "GradeTable";
  grade?: Maybe<Scalars["String"]["output"]>;
  subject?: Maybe<Scalars["String"]["output"]>;
};

export type GradeTableInput = {
  grade?: InputMaybe<Scalars["String"]["input"]>;
  subject?: InputMaybe<Scalars["String"]["input"]>;
};

export { HandleableFieldType };

export type Language = Entity & {
  __typename?: "Language";
  id: Scalars["ID"]["output"];
  /** Language name, see ISO 639-1. */
  name: Scalars["String"]["output"];
};

export type LengthRestriction = Entity & {
  __typename?: "LengthRestriction";
  id: Scalars["ID"]["output"];
  maxLength?: Maybe<Scalars["Int"]["output"]>;
  minLength?: Maybe<Scalars["Int"]["output"]>;
};

export type LocalizedString = {
  __typename?: "LocalizedString";
  /** Translation in current locale. */
  current: Scalars["String"]["output"];
  translations: Array<StringTranslation>;
};

export type LongValue = {
  __typename?: "LongValue";
  value: Scalars["Long"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  caseSubmit: CandidateCaseSubmitPayload;
  caseTroubleshootingRequestUpdate: CandidateCaseTroubleshootingRequestUpdatePayload;
  caseUpdate: CandidateCaseUpdatePayload;
  konfirVerificationLinkCreate: CandidateKonfirVerificationLinkCreatePayload;
  konfirVerificationSubmitted: CandidateCase;
  respondToDataRequest: RespondToCandidateDataRequestPayload;
  saveDraft: CandidateCaseSaveDraftPayload;
  signUp: CandidateSignUpPayload;
  trustIdGuestLinkCreate: CandidateTrustIdGuestLinkCreatePayload;
  uploadAdditionalDocuments: CandidateUploadAdditionalDocumentPayload;
};

export type MutationCaseSubmitArgs = {
  input: CandidateCaseSubmitInput;
};

export type MutationCaseTroubleshootingRequestUpdateArgs = {
  input: CandidateCaseTroubleshootingRequestUpdateInput;
};

export type MutationCaseUpdateArgs = {
  files?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  input: CandidateCaseUpdateInput;
};

export type MutationKonfirVerificationLinkCreateArgs = {
  input: CandidateKonfirVerificationLinkCreateInput;
};

export type MutationKonfirVerificationSubmittedArgs = {
  caseId: Scalars["ID"]["input"];
};

export type MutationRespondToDataRequestArgs = {
  files?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  input: RespondToCandidateDataRequestInput;
};

export type MutationSaveDraftArgs = {
  input: CandidateCaseSaveDraftInput;
};

export type MutationSignUpArgs = {
  input?: InputMaybe<CandidateSignUpInput>;
};

export type MutationTrustIdGuestLinkCreateArgs = {
  input: CandidateTrustIdGuestLinkCreateInput;
};

export type MutationUploadAdditionalDocumentsArgs = {
  files: Array<Scalars["Upload"]["input"]>;
  input: CandidateUploadAdditionalDocumentInput;
};

export type NumericRestriction = Entity & {
  __typename?: "NumericRestriction";
  comp: CompOperator;
  id: Scalars["ID"]["output"];
  value: Scalars["Float"]["output"];
};

export enum OpsDvlaFieldType {
  CONSENT_FORM_DATE = "CONSENT_FORM_DATE",
  DRIVING_LICENCE_NUMBER = "DRIVING_LICENCE_NUMBER",
  DRIVING_SCORE = "DRIVING_SCORE"
}

export type OpsDvlaFieldValue = {
  __typename?: "OpsDvlaFieldValue";
  value: OpsDvlaFieldType;
};

export { OpsEbulkFieldType };

export type OpsEbulkFieldValue = {
  __typename?: "OpsEbulkFieldValue";
  value: OpsEbulkFieldType;
};

export enum OpsEquifaxFieldType {
  ADDRESS_HISTORY = "ADDRESS_HISTORY",
  APPLICANT_TITLE = "APPLICANT_TITLE",
  BIRTH_DATE = "BIRTH_DATE",
  FORENAME = "FORENAME",
  MIDDLE_NAME = "MIDDLE_NAME",
  OTHER_FORENAMES = "OTHER_FORENAMES",
  OTHER_SURNAMES = "OTHER_SURNAMES",
  SURNAME = "SURNAME"
}

export type OpsEquifaxFieldValue = {
  __typename?: "OpsEquifaxFieldValue";
  value: OpsEquifaxFieldType;
};

export type OpsField =
  | OpsDvlaFieldValue
  | OpsEbulkFieldValue
  | OpsEquifaxFieldValue
  | OpsKonfirFieldValue
  | OpsOcrFieldValue
  | OpsTrustIdFieldValue;

export enum OpsKonfirFieldType {
  ACTIVITY_TYPE = "ACTIVITY_TYPE",
  ADDRESS_HISTORY = "ADDRESS_HISTORY",
  BIRTH_DATE = "BIRTH_DATE",
  DATE_PERIOD = "DATE_PERIOD",
  EMPLOYMENT_DECLARED_ANNUAL_INCOME = "EMPLOYMENT_DECLARED_ANNUAL_INCOME",
  EMPLOYMENT_EMPLOYER_NAME = "EMPLOYMENT_EMPLOYER_NAME",
  EMPLOYMENT_JOB_TITLE = "EMPLOYMENT_JOB_TITLE",
  FIRST_NAME = "FIRST_NAME",
  GAP_IN_EMPLOYMENT_DESCRIPTION = "GAP_IN_EMPLOYMENT_DESCRIPTION",
  GAP_IN_EMPLOYMENT_IS_RECEIVING_BENEFITS = "GAP_IN_EMPLOYMENT_IS_RECEIVING_BENEFITS",
  IS_CURRENT = "IS_CURRENT",
  LAST_NAME = "LAST_NAME",
  MIDDLE_NAME = "MIDDLE_NAME",
  NATIONAL_INSURANCE_NUMBER = "NATIONAL_INSURANCE_NUMBER",
  SELF_EMPLOYMENT_ACCOUNTING_ACCESS = "SELF_EMPLOYMENT_ACCOUNTING_ACCESS",
  SELF_EMPLOYMENT_ACCOUNTING_PROVIDER = "SELF_EMPLOYMENT_ACCOUNTING_PROVIDER",
  SELF_EMPLOYMENT_COMPANY_NAME = "SELF_EMPLOYMENT_COMPANY_NAME",
  SELF_EMPLOYMENT_COMPANY_NUMBER = "SELF_EMPLOYMENT_COMPANY_NUMBER",
  SELF_EMPLOYMENT_NOTES = "SELF_EMPLOYMENT_NOTES",
  SELF_EMPLOYMENT_UNIQUE_TAX_PAYER_REFERENCE = "SELF_EMPLOYMENT_UNIQUE_TAX_PAYER_REFERENCE"
}

export type OpsKonfirFieldValue = {
  __typename?: "OpsKonfirFieldValue";
  value: OpsKonfirFieldType;
};

export { OpsOcrFieldType };

export type OpsOcrFieldValue = {
  __typename?: "OpsOcrFieldValue";
  value: OpsOcrFieldType;
};

export enum OpsTrustIdFieldType {
  TRUST_ID_CLIENT_NAME = "TRUST_ID_CLIENT_NAME"
}

export type OpsTrustIdFieldValue = {
  __typename?: "OpsTrustIdFieldValue";
  value: OpsTrustIdFieldType;
};

export type Person = Entity & {
  __typename?: "Person";
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  jobPosition?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  notificationEmail: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type PersonTitle = Entity & {
  __typename?: "PersonTitle";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type ProductConditionalQuestion = {
  conditions: Array<FieldCondition>;
  id?: Maybe<Scalars["ID"]["output"]>;
  /** Questions to be enabled when condition is true. */
  question: BaseQuestion;
};

export type QualificationAndGrades = {
  __typename?: "QualificationAndGrades";
  grades: Array<GradeTable>;
  qualification: Scalars["String"]["output"];
};

export type QualificationAndGradesInput = {
  grades: Array<GradeTableInput>;
  qualification: Scalars["String"]["input"];
};

export type QualificationsAndGrades = {
  __typename?: "QualificationsAndGrades";
  qualificationsAndGrades: Array<QualificationAndGrades>;
};

export type QualificationsAndGradesInput = {
  qualificationsAndGrades: Array<QualificationAndGradesInput>;
};

export type Query = {
  __typename?: "Query";
  activeCaseTroubleshootingRequest: CandidateCaseTroubleshootingRequestConnection;
  authFlow: CandidatePortalAuthFlowPayload;
  countries: Array<Country>;
  currencies: Array<Currency>;
  genders: Array<Gender>;
  languages: Array<Language>;
  personTitles: Array<PersonTitle>;
  profile?: Maybe<CandidateProfile>;
  /** Pending case to be filled by candidate. */
  screeningCase: CandidateCase;
  /** Pending cases to be filled by candidate. */
  screeningCases: Array<CandidateCase>;
  /** System query, should not be used. */
  stub?: Maybe<Person>;
  /** System query, should not be used. */
  stubCatalogCandidate: StubOutput;
};

export type QueryActiveCaseTroubleshootingRequestArgs = {
  caseId: Scalars["ID"]["input"];
};

export type QueryAuthFlowArgs = {
  caseId: Scalars["ID"]["input"];
};

export type QueryScreeningCaseArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryStubArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryStubCatalogCandidateArgs = {
  id: Scalars["ID"]["input"];
};

/** Enables fields based on condition. */
export type QuestionConditionalFields = {
  conditions: Array<FieldCondition>;
  /** Fields to be enabled when condition is true. */
  fields: Array<Field>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

/** question queries */
export enum QuestionType {
  ADDRESS_HISTORY = "ADDRESS_HISTORY",
  EMPLOYMENT_EDUCATION_HISTORY = "EMPLOYMENT_EDUCATION_HISTORY",
  IDENTITY_PROOF = "IDENTITY_PROOF",
  OTHER = "OTHER"
}

export type RaisedAlert = Entity & {
  __typename?: "RaisedAlert";
  actedBy?: Maybe<Person>;
  actedWhen?: Maybe<Scalars["Long"]["output"]>;
  /** @deprecated No longer supported */
  alert: Alert;
  alertId: Scalars["ID"]["output"];
  caseProductId: Scalars["String"]["output"];
  comment: Scalars["String"]["output"];
  createdBy: Person;
  createdWhen: Scalars["Long"]["output"];
  id: Scalars["ID"]["output"];
  reopenReason?: Maybe<Scalars["String"]["output"]>;
  severity: AlertSeverity;
  status: RaisedAlertStatus;
  title: Scalars["String"]["output"];
};

export enum RaisedAlertStatus {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  REMOVED = "REMOVED"
}

export type RemoveAnswerDetailsInput = {
  removeFromBilling: Scalars["Boolean"]["input"];
};

/** DataRequest */
export type RespondToCandidateDataRequestInput = {
  content: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

export type RespondToCandidateDataRequestPayload = {
  __typename?: "RespondToCandidateDataRequestPayload";
  product: CandidateCaseProduct;
};

export { Section };

/**
 * fieldId: Id of the field that triggers conditional product
 *
 * conditionalProductsAffectedSections: list of sections that will be affected
 *   (i.e. new question(s) will be added to the section) in case conditional product was triggerred
 */
export type SectionsByAffectingField = {
  __typename?: "SectionsByAffectingField";
  conditionalProductsAffectedSections: Array<Section>;
  fieldId: Scalars["ID"]["output"];
};

export type SelectRestriction = Entity & {
  __typename?: "SelectRestriction";
  id: Scalars["ID"]["output"];
  multiSelect: Scalars["Boolean"]["output"];
  values: Array<SelectValue>;
};

export type SelectValue = Entity & {
  __typename?: "SelectValue";
  id: Scalars["ID"]["output"];
  /** Higher-level menu like Work, Education for activity list. */
  section: LocalizedString;
  title: LocalizedString;
};

export type StringTranslation = {
  __typename?: "StringTranslation";
  /** Language */
  language: Language;
  /** Localized information. */
  string: Scalars["String"]["output"];
};

export type StringValue = {
  __typename?: "StringValue";
  value: Scalars["String"]["output"];
};

export type StringValues = {
  __typename?: "StringValues";
  value: Array<Scalars["String"]["output"]>;
};

export type StubOutput = {
  __typename?: "StubOutput";
  client?: Maybe<CandidateClient>;
  field?: Maybe<CandidateField>;
  product?: Maybe<CandidateProduct>;
  question?: Maybe<CandidateQuestion>;
};

export { TroubleshootingRequestStatus };

export enum TrustIdApplicationStatus {
  CONTAINER_SUBMITTED = "CONTAINER_SUBMITTED",
  GUEST_LINK_CREATED = "GUEST_LINK_CREATED",
  RESULT_RECEIVED = "RESULT_RECEIVED",
  TIMED_OUT = "TIMED_OUT"
}

export type TrustIdApplicationState = {
  __typename?: "TrustIdApplicationState";
  status: TrustIdApplicationStatus;
  whenUpdated: Scalars["Long"]["output"];
};

export type AttachmentFragmentFragment = {
  __typename?: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: number;
  uploadedBy: string;
};

export type CandidateCaseFragmentFragment = {
  __typename?: "CandidateCase";
  id: string;
  code: string;
  status: CandidateCaseStatus;
  deadline: number;
  ocrTriggered: boolean;
  consentType: ConsentType;
  consentText?: string | null;
  troubleshotByScreener: boolean;
  consentGiven: boolean;
  whenCreated: number;
  whenCompleted?: number | null;
  draft?: string | null;
  hasEnhancedCriminalityCheck: boolean;
  additionalCandidateAttachments: Array<{
    __typename?: "AdditionalCandidateAttachments";
    note?: string | null;
    whenUploaded: number;
    candidateAttachments: Array<{
      __typename?: "Attachment";
      downloadUrl: string;
      fileName: string;
      id: string;
      uploadDate: number;
      uploadedBy: string;
    }>;
  }>;
  candidate: {
    __typename?: "Candidate";
    firstName: string;
    lastName: string;
    emailAddress: string;
  };
  eSignature?: {
    __typename?: "Attachment";
    downloadUrl: string;
    fileName: string;
    id: string;
    uploadDate: number;
    uploadedBy: string;
  } | null;
  trustIdApplication?: {
    __typename?: "CandidateTrustIdApplication";
    state: {
      __typename?: "TrustIdApplicationState";
      status: TrustIdApplicationStatus;
    };
  } | null;
  consentForm?: {
    __typename?: "Attachment";
    downloadUrl: string;
    fileName: string;
    id: string;
    uploadDate: number;
    uploadedBy: string;
  } | null;
  originalConsentForm?: {
    __typename?: "Attachment";
    downloadUrl: string;
    fileName: string;
    id: string;
    uploadDate: number;
    uploadedBy: string;
  } | null;
  client: {
    __typename?: "CandidateClient";
    id: string;
    name: string;
    liveChatEnabled: boolean;
    logo?: {
      __typename?: "Attachment";
      downloadUrl: string;
      fileName: string;
      id: string;
      uploadDate: number;
      uploadedBy: string;
    } | null;
  };
  sectionsByAffectingField: Array<{
    __typename?: "SectionsByAffectingField";
    conditionalProductsAffectedSections: Array<Section>;
    fieldId: string;
  }>;
  sections: Array<{
    __typename?: "CandidateDataSection";
    completed: boolean;
    section: Section;
    answers: Array<{
      __typename?: "CandidateAnswer";
      conditionFulfilled: boolean;
      answers: Array<{
        __typename?: "CandidateAnswerDetails";
        isCandidateAnswer: boolean;
        id: string;
        fieldValues: Array<{
          __typename?: "CandidateFieldValue";
          skip: boolean;
          field: {
            __typename?: "CandidateField";
            id: string;
            type: FieldType;
            fieldType: HandleableFieldType;
            required: boolean;
            conditional: boolean;
            restrictions: Array<
              | {
                  __typename?: "DateRestriction";
                  comp: CompOperator;
                  dateValue: string;
                }
              | {
                  __typename?: "DurationRestriction";
                  unit: DateUnit;
                  durationValue: number;
                }
              | {
                  __typename?: "GapRestriction";
                  unit: DateUnit;
                  gapValue: number;
                }
              | {
                  __typename?: "LengthRestriction";
                  maxLength?: number | null;
                  minLength?: number | null;
                }
              | {
                  __typename?: "NumericRestriction";
                  comp: CompOperator;
                  numericValue: number;
                }
              | {
                  __typename?: "SelectRestriction";
                  multiSelect: boolean;
                  values: Array<{
                    __typename?: "SelectValue";
                    id: string;
                    title: { __typename?: "LocalizedString"; current: string };
                  }>;
                }
            >;
            description: { __typename?: "LocalizedString"; current: string };
            title: { __typename?: "LocalizedString"; current: string };
            opsFields: Array<
              | { __typename?: "OpsDvlaFieldValue" }
              | {
                  __typename?: "OpsEbulkFieldValue";
                  ebulkValue: OpsEbulkFieldType;
                }
              | { __typename?: "OpsEquifaxFieldValue" }
              | { __typename?: "OpsKonfirFieldValue" }
              | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
              | { __typename?: "OpsTrustIdFieldValue" }
            >;
          };
          value?:
            | {
                __typename?: "Address";
                addressLine1: string;
                addressLine2?: string | null;
                addressLine3?: string | null;
                county?: string | null;
                postcode: string;
                town: string;
                country: {
                  __typename?: "Country";
                  id: string;
                  name: string;
                  isoCode: string;
                };
              }
            | {
                __typename?: "Attachment";
                downloadUrl: string;
                fileName: string;
                id: string;
                uploadDate: number;
                uploadedBy: string;
              }
            | {
                __typename?: "Attachments";
                attachmentsValue: Array<{
                  __typename?: "Attachment";
                  downloadUrl: string;
                  fileName: string;
                  id: string;
                  uploadDate: number;
                  uploadedBy: string;
                }>;
              }
            | { __typename?: "BooleanValue"; booleanValue: boolean }
            | {
                __typename?: "Company";
                name: string;
                address: {
                  __typename?: "Address";
                  addressLine1: string;
                  addressLine2?: string | null;
                  addressLine3?: string | null;
                  county?: string | null;
                  postcode: string;
                  town: string;
                  country: {
                    __typename?: "Country";
                    id: string;
                    name: string;
                    isoCode: string;
                  };
                };
              }
            | {
                __typename?: "Country";
                id: string;
                name: string;
                isoCode: string;
              }
            | {
                __typename?: "DateRange";
                from?: string | null;
                to?: string | null;
              }
            | { __typename?: "DateValue"; dateValue: string }
            | { __typename?: "FloatValue"; floatValue: number }
            | { __typename?: "LongValue" }
            | {
                __typename?: "QualificationsAndGrades";
                qualificationsAndGrades: Array<{
                  __typename?: "QualificationAndGrades";
                  qualification: string;
                  grades: Array<{
                    __typename?: "GradeTable";
                    grade?: string | null;
                    subject?: string | null;
                  }>;
                }>;
              }
            | { __typename?: "StringValue"; stringValue: string }
            | { __typename?: "StringValues"; stringValues: Array<string> }
            | null;
        }>;
      }>;
      question: {
        __typename?: "CandidateQuestion";
        id: string;
        conditional: boolean;
        multipleAnswers: boolean;
        questionType: QuestionType;
        title: string;
        fields: Array<{
          __typename?: "CandidateField";
          id: string;
          type: FieldType;
          fieldType: HandleableFieldType;
          required: boolean;
          conditional: boolean;
          restrictions: Array<
            | {
                __typename?: "DateRestriction";
                comp: CompOperator;
                dateValue: string;
              }
            | {
                __typename?: "DurationRestriction";
                unit: DateUnit;
                durationValue: number;
              }
            | {
                __typename?: "GapRestriction";
                unit: DateUnit;
                gapValue: number;
              }
            | {
                __typename?: "LengthRestriction";
                maxLength?: number | null;
                minLength?: number | null;
              }
            | {
                __typename?: "NumericRestriction";
                comp: CompOperator;
                numericValue: number;
              }
            | {
                __typename?: "SelectRestriction";
                multiSelect: boolean;
                values: Array<{
                  __typename?: "SelectValue";
                  id: string;
                  title: { __typename?: "LocalizedString"; current: string };
                }>;
              }
          >;
          description: { __typename?: "LocalizedString"; current: string };
          title: { __typename?: "LocalizedString"; current: string };
          opsFields: Array<
            | { __typename?: "OpsDvlaFieldValue" }
            | {
                __typename?: "OpsEbulkFieldValue";
                ebulkValue: OpsEbulkFieldType;
              }
            | { __typename?: "OpsEquifaxFieldValue" }
            | { __typename?: "OpsKonfirFieldValue" }
            | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
            | { __typename?: "OpsTrustIdFieldValue" }
          >;
        }>;
        conditionalElements: Array<{
          __typename?: "CandidateQuestionConditionalFields";
          id?: string | null;
          fields: Array<{
            __typename?: "CandidateField";
            id: string;
            type: FieldType;
            fieldType: HandleableFieldType;
            required: boolean;
            conditional: boolean;
            restrictions: Array<
              | {
                  __typename?: "DateRestriction";
                  comp: CompOperator;
                  dateValue: string;
                }
              | {
                  __typename?: "DurationRestriction";
                  unit: DateUnit;
                  durationValue: number;
                }
              | {
                  __typename?: "GapRestriction";
                  unit: DateUnit;
                  gapValue: number;
                }
              | {
                  __typename?: "LengthRestriction";
                  maxLength?: number | null;
                  minLength?: number | null;
                }
              | {
                  __typename?: "NumericRestriction";
                  comp: CompOperator;
                  numericValue: number;
                }
              | {
                  __typename?: "SelectRestriction";
                  multiSelect: boolean;
                  values: Array<{
                    __typename?: "SelectValue";
                    id: string;
                    title: { __typename?: "LocalizedString"; current: string };
                  }>;
                }
            >;
            description: { __typename?: "LocalizedString"; current: string };
            title: { __typename?: "LocalizedString"; current: string };
            opsFields: Array<
              | { __typename?: "OpsDvlaFieldValue" }
              | {
                  __typename?: "OpsEbulkFieldValue";
                  ebulkValue: OpsEbulkFieldType;
                }
              | { __typename?: "OpsEquifaxFieldValue" }
              | { __typename?: "OpsKonfirFieldValue" }
              | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
              | { __typename?: "OpsTrustIdFieldValue" }
            >;
          }>;
          conditions: Array<{
            __typename?: "CandidateFieldCondition";
            id?: string | null;
            fieldValue: {
              __typename?: "CandidateFieldValue";
              field: {
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              };
              value?:
                | {
                    __typename?: "Address";
                    addressLine1: string;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    county?: string | null;
                    postcode: string;
                    town: string;
                    country: {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    };
                  }
                | {
                    __typename?: "Attachment";
                    downloadUrl: string;
                    fileName: string;
                    id: string;
                    uploadDate: number;
                    uploadedBy: string;
                  }
                | {
                    __typename?: "Attachments";
                    attachmentsValue: Array<{
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }>;
                  }
                | { __typename?: "BooleanValue"; booleanValue: boolean }
                | {
                    __typename?: "Company";
                    name: string;
                    address: {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    };
                  }
                | {
                    __typename?: "Country";
                    id: string;
                    name: string;
                    isoCode: string;
                  }
                | {
                    __typename?: "DateRange";
                    from?: string | null;
                    to?: string | null;
                  }
                | { __typename?: "DateValue"; dateValue: string }
                | { __typename?: "FloatValue"; floatValue: number }
                | { __typename?: "LongValue" }
                | {
                    __typename?: "QualificationsAndGrades";
                    qualificationsAndGrades: Array<{
                      __typename?: "QualificationAndGrades";
                      qualification: string;
                      grades: Array<{
                        __typename?: "GradeTable";
                        grade?: string | null;
                        subject?: string | null;
                      }>;
                    }>;
                  }
                | { __typename?: "StringValue"; stringValue: string }
                | { __typename?: "StringValues"; stringValues: Array<string> }
                | null;
            };
          }>;
        }>;
      };
    }>;
  }>;
  contract: {
    __typename?: "CandidateContract";
    id: string;
    support: {
      __typename?: "CandidateContractSupport";
      supportEmailAddress: string;
      supportPhoneNumber: string;
    };
  };
  products: Array<{
    __typename?: "CandidateCaseProduct";
    id: string;
    status: CandidateCaseProductStatus;
    name: string;
    product: {
      __typename?: "CandidateProduct";
      id: string;
      conditionalQuestions: Array<{
        __typename?: "CandidateProductConditionalQuestion";
        id?: string | null;
        conditions: Array<{
          __typename?: "CandidateFieldCondition";
          id?: string | null;
          fieldValue: {
            __typename?: "CandidateFieldValue";
            field: {
              __typename?: "CandidateField";
              id: string;
              type: FieldType;
              fieldType: HandleableFieldType;
              required: boolean;
              conditional: boolean;
              restrictions: Array<
                | {
                    __typename?: "DateRestriction";
                    comp: CompOperator;
                    dateValue: string;
                  }
                | {
                    __typename?: "DurationRestriction";
                    unit: DateUnit;
                    durationValue: number;
                  }
                | {
                    __typename?: "GapRestriction";
                    unit: DateUnit;
                    gapValue: number;
                  }
                | {
                    __typename?: "LengthRestriction";
                    maxLength?: number | null;
                    minLength?: number | null;
                  }
                | {
                    __typename?: "NumericRestriction";
                    comp: CompOperator;
                    numericValue: number;
                  }
                | {
                    __typename?: "SelectRestriction";
                    multiSelect: boolean;
                    values: Array<{
                      __typename?: "SelectValue";
                      id: string;
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                    }>;
                  }
              >;
              description: { __typename?: "LocalizedString"; current: string };
              title: { __typename?: "LocalizedString"; current: string };
              opsFields: Array<
                | { __typename?: "OpsDvlaFieldValue" }
                | {
                    __typename?: "OpsEbulkFieldValue";
                    ebulkValue: OpsEbulkFieldType;
                  }
                | { __typename?: "OpsEquifaxFieldValue" }
                | { __typename?: "OpsKonfirFieldValue" }
                | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
                | { __typename?: "OpsTrustIdFieldValue" }
              >;
            };
            value?:
              | {
                  __typename?: "Address";
                  addressLine1: string;
                  addressLine2?: string | null;
                  addressLine3?: string | null;
                  county?: string | null;
                  postcode: string;
                  town: string;
                  country: {
                    __typename?: "Country";
                    id: string;
                    name: string;
                    isoCode: string;
                  };
                }
              | {
                  __typename?: "Attachment";
                  downloadUrl: string;
                  fileName: string;
                  id: string;
                  uploadDate: number;
                  uploadedBy: string;
                }
              | {
                  __typename?: "Attachments";
                  attachmentsValue: Array<{
                    __typename?: "Attachment";
                    downloadUrl: string;
                    fileName: string;
                    id: string;
                    uploadDate: number;
                    uploadedBy: string;
                  }>;
                }
              | { __typename?: "BooleanValue"; booleanValue: boolean }
              | {
                  __typename?: "Company";
                  name: string;
                  address: {
                    __typename?: "Address";
                    addressLine1: string;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    county?: string | null;
                    postcode: string;
                    town: string;
                    country: {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    };
                  };
                }
              | {
                  __typename?: "Country";
                  id: string;
                  name: string;
                  isoCode: string;
                }
              | {
                  __typename?: "DateRange";
                  from?: string | null;
                  to?: string | null;
                }
              | { __typename?: "DateValue"; dateValue: string }
              | { __typename?: "FloatValue"; floatValue: number }
              | { __typename?: "LongValue" }
              | {
                  __typename?: "QualificationsAndGrades";
                  qualificationsAndGrades: Array<{
                    __typename?: "QualificationAndGrades";
                    qualification: string;
                    grades: Array<{
                      __typename?: "GradeTable";
                      grade?: string | null;
                      subject?: string | null;
                    }>;
                  }>;
                }
              | { __typename?: "StringValue"; stringValue: string }
              | { __typename?: "StringValues"; stringValues: Array<string> }
              | null;
          };
        }>;
        question: {
          __typename?: "CandidateQuestion";
          id: string;
          conditional: boolean;
          multipleAnswers: boolean;
          questionType: QuestionType;
          title: string;
          fields: Array<{
            __typename?: "CandidateField";
            id: string;
            type: FieldType;
            fieldType: HandleableFieldType;
            required: boolean;
            conditional: boolean;
            restrictions: Array<
              | {
                  __typename?: "DateRestriction";
                  comp: CompOperator;
                  dateValue: string;
                }
              | {
                  __typename?: "DurationRestriction";
                  unit: DateUnit;
                  durationValue: number;
                }
              | {
                  __typename?: "GapRestriction";
                  unit: DateUnit;
                  gapValue: number;
                }
              | {
                  __typename?: "LengthRestriction";
                  maxLength?: number | null;
                  minLength?: number | null;
                }
              | {
                  __typename?: "NumericRestriction";
                  comp: CompOperator;
                  numericValue: number;
                }
              | {
                  __typename?: "SelectRestriction";
                  multiSelect: boolean;
                  values: Array<{
                    __typename?: "SelectValue";
                    id: string;
                    title: { __typename?: "LocalizedString"; current: string };
                  }>;
                }
            >;
            description: { __typename?: "LocalizedString"; current: string };
            title: { __typename?: "LocalizedString"; current: string };
            opsFields: Array<
              | { __typename?: "OpsDvlaFieldValue" }
              | {
                  __typename?: "OpsEbulkFieldValue";
                  ebulkValue: OpsEbulkFieldType;
                }
              | { __typename?: "OpsEquifaxFieldValue" }
              | { __typename?: "OpsKonfirFieldValue" }
              | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
              | { __typename?: "OpsTrustIdFieldValue" }
            >;
          }>;
          conditionalElements: Array<{
            __typename?: "CandidateQuestionConditionalFields";
            id?: string | null;
            fields: Array<{
              __typename?: "CandidateField";
              id: string;
              type: FieldType;
              fieldType: HandleableFieldType;
              required: boolean;
              conditional: boolean;
              restrictions: Array<
                | {
                    __typename?: "DateRestriction";
                    comp: CompOperator;
                    dateValue: string;
                  }
                | {
                    __typename?: "DurationRestriction";
                    unit: DateUnit;
                    durationValue: number;
                  }
                | {
                    __typename?: "GapRestriction";
                    unit: DateUnit;
                    gapValue: number;
                  }
                | {
                    __typename?: "LengthRestriction";
                    maxLength?: number | null;
                    minLength?: number | null;
                  }
                | {
                    __typename?: "NumericRestriction";
                    comp: CompOperator;
                    numericValue: number;
                  }
                | {
                    __typename?: "SelectRestriction";
                    multiSelect: boolean;
                    values: Array<{
                      __typename?: "SelectValue";
                      id: string;
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                    }>;
                  }
              >;
              description: { __typename?: "LocalizedString"; current: string };
              title: { __typename?: "LocalizedString"; current: string };
              opsFields: Array<
                | { __typename?: "OpsDvlaFieldValue" }
                | {
                    __typename?: "OpsEbulkFieldValue";
                    ebulkValue: OpsEbulkFieldType;
                  }
                | { __typename?: "OpsEquifaxFieldValue" }
                | { __typename?: "OpsKonfirFieldValue" }
                | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
                | { __typename?: "OpsTrustIdFieldValue" }
              >;
            }>;
            conditions: Array<{
              __typename?: "CandidateFieldCondition";
              id?: string | null;
              fieldValue: {
                __typename?: "CandidateFieldValue";
                field: {
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                };
                value?:
                  | {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    }
                  | {
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }
                  | {
                      __typename?: "Attachments";
                      attachmentsValue: Array<{
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }>;
                    }
                  | { __typename?: "BooleanValue"; booleanValue: boolean }
                  | {
                      __typename?: "Company";
                      name: string;
                      address: {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      };
                    }
                  | {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    }
                  | {
                      __typename?: "DateRange";
                      from?: string | null;
                      to?: string | null;
                    }
                  | { __typename?: "DateValue"; dateValue: string }
                  | { __typename?: "FloatValue"; floatValue: number }
                  | { __typename?: "LongValue" }
                  | {
                      __typename?: "QualificationsAndGrades";
                      qualificationsAndGrades: Array<{
                        __typename?: "QualificationAndGrades";
                        qualification: string;
                        grades: Array<{
                          __typename?: "GradeTable";
                          grade?: string | null;
                          subject?: string | null;
                        }>;
                      }>;
                    }
                  | { __typename?: "StringValue"; stringValue: string }
                  | { __typename?: "StringValues"; stringValues: Array<string> }
                  | null;
              };
            }>;
          }>;
        };
      }>;
      conditionalValidations: Array<{
        __typename?: "CandidateProductConditionalValidation";
        id?: string | null;
        validationMessage: string;
        answersCondition?: {
          __typename?: "CandidateAnswersCondition";
          maxNumberOfAnswers?: number | null;
          minNumberOfAnswers?: number | null;
        } | null;
        conditions: Array<{
          __typename?: "CandidateFieldCondition";
          id?: string | null;
          fieldValue: {
            __typename?: "CandidateFieldValue";
            field: {
              __typename?: "CandidateField";
              id: string;
              type: FieldType;
              fieldType: HandleableFieldType;
              required: boolean;
              conditional: boolean;
              restrictions: Array<
                | {
                    __typename?: "DateRestriction";
                    comp: CompOperator;
                    dateValue: string;
                  }
                | {
                    __typename?: "DurationRestriction";
                    unit: DateUnit;
                    durationValue: number;
                  }
                | {
                    __typename?: "GapRestriction";
                    unit: DateUnit;
                    gapValue: number;
                  }
                | {
                    __typename?: "LengthRestriction";
                    maxLength?: number | null;
                    minLength?: number | null;
                  }
                | {
                    __typename?: "NumericRestriction";
                    comp: CompOperator;
                    numericValue: number;
                  }
                | {
                    __typename?: "SelectRestriction";
                    multiSelect: boolean;
                    values: Array<{
                      __typename?: "SelectValue";
                      id: string;
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                    }>;
                  }
              >;
              description: { __typename?: "LocalizedString"; current: string };
              title: { __typename?: "LocalizedString"; current: string };
              opsFields: Array<
                | { __typename?: "OpsDvlaFieldValue" }
                | {
                    __typename?: "OpsEbulkFieldValue";
                    ebulkValue: OpsEbulkFieldType;
                  }
                | { __typename?: "OpsEquifaxFieldValue" }
                | { __typename?: "OpsKonfirFieldValue" }
                | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
                | { __typename?: "OpsTrustIdFieldValue" }
              >;
            };
            value?:
              | {
                  __typename?: "Address";
                  addressLine1: string;
                  addressLine2?: string | null;
                  addressLine3?: string | null;
                  county?: string | null;
                  postcode: string;
                  town: string;
                  country: {
                    __typename?: "Country";
                    id: string;
                    name: string;
                    isoCode: string;
                  };
                }
              | {
                  __typename?: "Attachment";
                  downloadUrl: string;
                  fileName: string;
                  id: string;
                  uploadDate: number;
                  uploadedBy: string;
                }
              | {
                  __typename?: "Attachments";
                  attachmentsValue: Array<{
                    __typename?: "Attachment";
                    downloadUrl: string;
                    fileName: string;
                    id: string;
                    uploadDate: number;
                    uploadedBy: string;
                  }>;
                }
              | { __typename?: "BooleanValue"; booleanValue: boolean }
              | {
                  __typename?: "Company";
                  name: string;
                  address: {
                    __typename?: "Address";
                    addressLine1: string;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    county?: string | null;
                    postcode: string;
                    town: string;
                    country: {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    };
                  };
                }
              | {
                  __typename?: "Country";
                  id: string;
                  name: string;
                  isoCode: string;
                }
              | {
                  __typename?: "DateRange";
                  from?: string | null;
                  to?: string | null;
                }
              | { __typename?: "DateValue"; dateValue: string }
              | { __typename?: "FloatValue"; floatValue: number }
              | { __typename?: "LongValue" }
              | {
                  __typename?: "QualificationsAndGrades";
                  qualificationsAndGrades: Array<{
                    __typename?: "QualificationAndGrades";
                    qualification: string;
                    grades: Array<{
                      __typename?: "GradeTable";
                      grade?: string | null;
                      subject?: string | null;
                    }>;
                  }>;
                }
              | { __typename?: "StringValue"; stringValue: string }
              | { __typename?: "StringValues"; stringValues: Array<string> }
              | null;
          };
        }>;
      }>;
    };
  }>;
};

export type TroubleshootingRequestFragmentFragment = {
  __typename?: "CandidateCaseTroubleshootingRequest";
  id: string;
  status: TroubleshootingRequestStatus;
  createdWhen: number;
};

export type CandidateFieldFragmentFragment = {
  __typename?: "CandidateField";
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  required: boolean;
  conditional: boolean;
  restrictions: Array<
    | { __typename?: "DateRestriction"; comp: CompOperator; dateValue: string }
    | {
        __typename?: "DurationRestriction";
        unit: DateUnit;
        durationValue: number;
      }
    | { __typename?: "GapRestriction"; unit: DateUnit; gapValue: number }
    | {
        __typename?: "LengthRestriction";
        maxLength?: number | null;
        minLength?: number | null;
      }
    | {
        __typename?: "NumericRestriction";
        comp: CompOperator;
        numericValue: number;
      }
    | {
        __typename?: "SelectRestriction";
        multiSelect: boolean;
        values: Array<{
          __typename?: "SelectValue";
          id: string;
          title: { __typename?: "LocalizedString"; current: string };
        }>;
      }
  >;
  description: { __typename?: "LocalizedString"; current: string };
  title: { __typename?: "LocalizedString"; current: string };
  opsFields: Array<
    | { __typename?: "OpsDvlaFieldValue" }
    | { __typename?: "OpsEbulkFieldValue"; ebulkValue: OpsEbulkFieldType }
    | { __typename?: "OpsEquifaxFieldValue" }
    | { __typename?: "OpsKonfirFieldValue" }
    | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
    | { __typename?: "OpsTrustIdFieldValue" }
  >;
};

export type CountryFragmentFragment = {
  __typename?: "Country";
  id: string;
  name: string;
  isoCode: string;
};

type FieldAnyValueFragment_Address_Fragment = {
  __typename?: "Address";
  addressLine1: string;
  addressLine2?: string | null;
  addressLine3?: string | null;
  county?: string | null;
  postcode: string;
  town: string;
  country: {
    __typename?: "Country";
    id: string;
    name: string;
    isoCode: string;
  };
};

type FieldAnyValueFragment_Attachment_Fragment = {
  __typename?: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: number;
  uploadedBy: string;
};

type FieldAnyValueFragment_Attachments_Fragment = {
  __typename?: "Attachments";
  attachmentsValue: Array<{
    __typename?: "Attachment";
    downloadUrl: string;
    fileName: string;
    id: string;
    uploadDate: number;
    uploadedBy: string;
  }>;
};

type FieldAnyValueFragment_BooleanValue_Fragment = {
  __typename?: "BooleanValue";
  booleanValue: boolean;
};

type FieldAnyValueFragment_Company_Fragment = {
  __typename?: "Company";
  name: string;
  address: {
    __typename?: "Address";
    addressLine1: string;
    addressLine2?: string | null;
    addressLine3?: string | null;
    county?: string | null;
    postcode: string;
    town: string;
    country: {
      __typename?: "Country";
      id: string;
      name: string;
      isoCode: string;
    };
  };
};

type FieldAnyValueFragment_Country_Fragment = {
  __typename?: "Country";
  id: string;
  name: string;
  isoCode: string;
};

type FieldAnyValueFragment_DateRange_Fragment = {
  __typename?: "DateRange";
  from?: string | null;
  to?: string | null;
};

type FieldAnyValueFragment_DateValue_Fragment = {
  __typename?: "DateValue";
  dateValue: string;
};

type FieldAnyValueFragment_FloatValue_Fragment = {
  __typename?: "FloatValue";
  floatValue: number;
};

type FieldAnyValueFragment_LongValue_Fragment = { __typename?: "LongValue" };

type FieldAnyValueFragment_QualificationsAndGrades_Fragment = {
  __typename?: "QualificationsAndGrades";
  qualificationsAndGrades: Array<{
    __typename?: "QualificationAndGrades";
    qualification: string;
    grades: Array<{
      __typename?: "GradeTable";
      grade?: string | null;
      subject?: string | null;
    }>;
  }>;
};

type FieldAnyValueFragment_StringValue_Fragment = {
  __typename?: "StringValue";
  stringValue: string;
};

type FieldAnyValueFragment_StringValues_Fragment = {
  __typename?: "StringValues";
  stringValues: Array<string>;
};

export type FieldAnyValueFragmentFragment =
  | FieldAnyValueFragment_Address_Fragment
  | FieldAnyValueFragment_Attachment_Fragment
  | FieldAnyValueFragment_Attachments_Fragment
  | FieldAnyValueFragment_BooleanValue_Fragment
  | FieldAnyValueFragment_Company_Fragment
  | FieldAnyValueFragment_Country_Fragment
  | FieldAnyValueFragment_DateRange_Fragment
  | FieldAnyValueFragment_DateValue_Fragment
  | FieldAnyValueFragment_FloatValue_Fragment
  | FieldAnyValueFragment_LongValue_Fragment
  | FieldAnyValueFragment_QualificationsAndGrades_Fragment
  | FieldAnyValueFragment_StringValue_Fragment
  | FieldAnyValueFragment_StringValues_Fragment;

type FieldRestrictionFragment_DateRestriction_Fragment = {
  __typename?: "DateRestriction";
  comp: CompOperator;
  dateValue: string;
};

type FieldRestrictionFragment_DurationRestriction_Fragment = {
  __typename?: "DurationRestriction";
  unit: DateUnit;
  durationValue: number;
};

type FieldRestrictionFragment_GapRestriction_Fragment = {
  __typename?: "GapRestriction";
  unit: DateUnit;
  gapValue: number;
};

type FieldRestrictionFragment_LengthRestriction_Fragment = {
  __typename?: "LengthRestriction";
  maxLength?: number | null;
  minLength?: number | null;
};

type FieldRestrictionFragment_NumericRestriction_Fragment = {
  __typename?: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
};

type FieldRestrictionFragment_SelectRestriction_Fragment = {
  __typename?: "SelectRestriction";
  multiSelect: boolean;
  values: Array<{
    __typename?: "SelectValue";
    id: string;
    title: { __typename?: "LocalizedString"; current: string };
  }>;
};

export type FieldRestrictionFragmentFragment =
  | FieldRestrictionFragment_DateRestriction_Fragment
  | FieldRestrictionFragment_DurationRestriction_Fragment
  | FieldRestrictionFragment_GapRestriction_Fragment
  | FieldRestrictionFragment_LengthRestriction_Fragment
  | FieldRestrictionFragment_NumericRestriction_Fragment
  | FieldRestrictionFragment_SelectRestriction_Fragment;

export type SaveDraftMutationVariables = Exact<{
  input: CandidateCaseSaveDraftInput;
}>;

export type SaveDraftMutation = {
  __typename?: "Mutation";
  saveDraft: {
    __typename?: "CandidateCaseSaveDraftPayload";
    case: {
      __typename?: "CandidateCase";
      id: string;
      code: string;
      status: CandidateCaseStatus;
      deadline: number;
      ocrTriggered: boolean;
      consentType: ConsentType;
      consentText?: string | null;
      troubleshotByScreener: boolean;
      consentGiven: boolean;
      whenCreated: number;
      whenCompleted?: number | null;
      draft?: string | null;
      hasEnhancedCriminalityCheck: boolean;
      additionalCandidateAttachments: Array<{
        __typename?: "AdditionalCandidateAttachments";
        note?: string | null;
        whenUploaded: number;
        candidateAttachments: Array<{
          __typename?: "Attachment";
          downloadUrl: string;
          fileName: string;
          id: string;
          uploadDate: number;
          uploadedBy: string;
        }>;
      }>;
      candidate: {
        __typename?: "Candidate";
        firstName: string;
        lastName: string;
        emailAddress: string;
      };
      eSignature?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
      trustIdApplication?: {
        __typename?: "CandidateTrustIdApplication";
        state: {
          __typename?: "TrustIdApplicationState";
          status: TrustIdApplicationStatus;
        };
      } | null;
      consentForm?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
      originalConsentForm?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
      client: {
        __typename?: "CandidateClient";
        id: string;
        name: string;
        liveChatEnabled: boolean;
        logo?: {
          __typename?: "Attachment";
          downloadUrl: string;
          fileName: string;
          id: string;
          uploadDate: number;
          uploadedBy: string;
        } | null;
      };
      sectionsByAffectingField: Array<{
        __typename?: "SectionsByAffectingField";
        conditionalProductsAffectedSections: Array<Section>;
        fieldId: string;
      }>;
      sections: Array<{
        __typename?: "CandidateDataSection";
        completed: boolean;
        section: Section;
        answers: Array<{
          __typename?: "CandidateAnswer";
          conditionFulfilled: boolean;
          answers: Array<{
            __typename?: "CandidateAnswerDetails";
            isCandidateAnswer: boolean;
            id: string;
            fieldValues: Array<{
              __typename?: "CandidateFieldValue";
              skip: boolean;
              field: {
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              };
              value?:
                | {
                    __typename?: "Address";
                    addressLine1: string;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    county?: string | null;
                    postcode: string;
                    town: string;
                    country: {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    };
                  }
                | {
                    __typename?: "Attachment";
                    downloadUrl: string;
                    fileName: string;
                    id: string;
                    uploadDate: number;
                    uploadedBy: string;
                  }
                | {
                    __typename?: "Attachments";
                    attachmentsValue: Array<{
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }>;
                  }
                | { __typename?: "BooleanValue"; booleanValue: boolean }
                | {
                    __typename?: "Company";
                    name: string;
                    address: {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    };
                  }
                | {
                    __typename?: "Country";
                    id: string;
                    name: string;
                    isoCode: string;
                  }
                | {
                    __typename?: "DateRange";
                    from?: string | null;
                    to?: string | null;
                  }
                | { __typename?: "DateValue"; dateValue: string }
                | { __typename?: "FloatValue"; floatValue: number }
                | { __typename?: "LongValue" }
                | {
                    __typename?: "QualificationsAndGrades";
                    qualificationsAndGrades: Array<{
                      __typename?: "QualificationAndGrades";
                      qualification: string;
                      grades: Array<{
                        __typename?: "GradeTable";
                        grade?: string | null;
                        subject?: string | null;
                      }>;
                    }>;
                  }
                | { __typename?: "StringValue"; stringValue: string }
                | { __typename?: "StringValues"; stringValues: Array<string> }
                | null;
            }>;
          }>;
          question: {
            __typename?: "CandidateQuestion";
            id: string;
            conditional: boolean;
            multipleAnswers: boolean;
            questionType: QuestionType;
            title: string;
            fields: Array<{
              __typename?: "CandidateField";
              id: string;
              type: FieldType;
              fieldType: HandleableFieldType;
              required: boolean;
              conditional: boolean;
              restrictions: Array<
                | {
                    __typename?: "DateRestriction";
                    comp: CompOperator;
                    dateValue: string;
                  }
                | {
                    __typename?: "DurationRestriction";
                    unit: DateUnit;
                    durationValue: number;
                  }
                | {
                    __typename?: "GapRestriction";
                    unit: DateUnit;
                    gapValue: number;
                  }
                | {
                    __typename?: "LengthRestriction";
                    maxLength?: number | null;
                    minLength?: number | null;
                  }
                | {
                    __typename?: "NumericRestriction";
                    comp: CompOperator;
                    numericValue: number;
                  }
                | {
                    __typename?: "SelectRestriction";
                    multiSelect: boolean;
                    values: Array<{
                      __typename?: "SelectValue";
                      id: string;
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                    }>;
                  }
              >;
              description: { __typename?: "LocalizedString"; current: string };
              title: { __typename?: "LocalizedString"; current: string };
              opsFields: Array<
                | { __typename?: "OpsDvlaFieldValue" }
                | {
                    __typename?: "OpsEbulkFieldValue";
                    ebulkValue: OpsEbulkFieldType;
                  }
                | { __typename?: "OpsEquifaxFieldValue" }
                | { __typename?: "OpsKonfirFieldValue" }
                | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
                | { __typename?: "OpsTrustIdFieldValue" }
              >;
            }>;
            conditionalElements: Array<{
              __typename?: "CandidateQuestionConditionalFields";
              id?: string | null;
              fields: Array<{
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              }>;
              conditions: Array<{
                __typename?: "CandidateFieldCondition";
                id?: string | null;
                fieldValue: {
                  __typename?: "CandidateFieldValue";
                  field: {
                    __typename?: "CandidateField";
                    id: string;
                    type: FieldType;
                    fieldType: HandleableFieldType;
                    required: boolean;
                    conditional: boolean;
                    restrictions: Array<
                      | {
                          __typename?: "DateRestriction";
                          comp: CompOperator;
                          dateValue: string;
                        }
                      | {
                          __typename?: "DurationRestriction";
                          unit: DateUnit;
                          durationValue: number;
                        }
                      | {
                          __typename?: "GapRestriction";
                          unit: DateUnit;
                          gapValue: number;
                        }
                      | {
                          __typename?: "LengthRestriction";
                          maxLength?: number | null;
                          minLength?: number | null;
                        }
                      | {
                          __typename?: "NumericRestriction";
                          comp: CompOperator;
                          numericValue: number;
                        }
                      | {
                          __typename?: "SelectRestriction";
                          multiSelect: boolean;
                          values: Array<{
                            __typename?: "SelectValue";
                            id: string;
                            title: {
                              __typename?: "LocalizedString";
                              current: string;
                            };
                          }>;
                        }
                    >;
                    description: {
                      __typename?: "LocalizedString";
                      current: string;
                    };
                    title: { __typename?: "LocalizedString"; current: string };
                    opsFields: Array<
                      | { __typename?: "OpsDvlaFieldValue" }
                      | {
                          __typename?: "OpsEbulkFieldValue";
                          ebulkValue: OpsEbulkFieldType;
                        }
                      | { __typename?: "OpsEquifaxFieldValue" }
                      | { __typename?: "OpsKonfirFieldValue" }
                      | {
                          __typename?: "OpsOcrFieldValue";
                          ocrValue: OpsOcrFieldType;
                        }
                      | { __typename?: "OpsTrustIdFieldValue" }
                    >;
                  };
                  value?:
                    | {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      }
                    | {
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }
                    | {
                        __typename?: "Attachments";
                        attachmentsValue: Array<{
                          __typename?: "Attachment";
                          downloadUrl: string;
                          fileName: string;
                          id: string;
                          uploadDate: number;
                          uploadedBy: string;
                        }>;
                      }
                    | { __typename?: "BooleanValue"; booleanValue: boolean }
                    | {
                        __typename?: "Company";
                        name: string;
                        address: {
                          __typename?: "Address";
                          addressLine1: string;
                          addressLine2?: string | null;
                          addressLine3?: string | null;
                          county?: string | null;
                          postcode: string;
                          town: string;
                          country: {
                            __typename?: "Country";
                            id: string;
                            name: string;
                            isoCode: string;
                          };
                        };
                      }
                    | {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      }
                    | {
                        __typename?: "DateRange";
                        from?: string | null;
                        to?: string | null;
                      }
                    | { __typename?: "DateValue"; dateValue: string }
                    | { __typename?: "FloatValue"; floatValue: number }
                    | { __typename?: "LongValue" }
                    | {
                        __typename?: "QualificationsAndGrades";
                        qualificationsAndGrades: Array<{
                          __typename?: "QualificationAndGrades";
                          qualification: string;
                          grades: Array<{
                            __typename?: "GradeTable";
                            grade?: string | null;
                            subject?: string | null;
                          }>;
                        }>;
                      }
                    | { __typename?: "StringValue"; stringValue: string }
                    | {
                        __typename?: "StringValues";
                        stringValues: Array<string>;
                      }
                    | null;
                };
              }>;
            }>;
          };
        }>;
      }>;
      contract: {
        __typename?: "CandidateContract";
        id: string;
        support: {
          __typename?: "CandidateContractSupport";
          supportEmailAddress: string;
          supportPhoneNumber: string;
        };
      };
      products: Array<{
        __typename?: "CandidateCaseProduct";
        id: string;
        status: CandidateCaseProductStatus;
        name: string;
        product: {
          __typename?: "CandidateProduct";
          id: string;
          conditionalQuestions: Array<{
            __typename?: "CandidateProductConditionalQuestion";
            id?: string | null;
            conditions: Array<{
              __typename?: "CandidateFieldCondition";
              id?: string | null;
              fieldValue: {
                __typename?: "CandidateFieldValue";
                field: {
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                };
                value?:
                  | {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    }
                  | {
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }
                  | {
                      __typename?: "Attachments";
                      attachmentsValue: Array<{
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }>;
                    }
                  | { __typename?: "BooleanValue"; booleanValue: boolean }
                  | {
                      __typename?: "Company";
                      name: string;
                      address: {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      };
                    }
                  | {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    }
                  | {
                      __typename?: "DateRange";
                      from?: string | null;
                      to?: string | null;
                    }
                  | { __typename?: "DateValue"; dateValue: string }
                  | { __typename?: "FloatValue"; floatValue: number }
                  | { __typename?: "LongValue" }
                  | {
                      __typename?: "QualificationsAndGrades";
                      qualificationsAndGrades: Array<{
                        __typename?: "QualificationAndGrades";
                        qualification: string;
                        grades: Array<{
                          __typename?: "GradeTable";
                          grade?: string | null;
                          subject?: string | null;
                        }>;
                      }>;
                    }
                  | { __typename?: "StringValue"; stringValue: string }
                  | { __typename?: "StringValues"; stringValues: Array<string> }
                  | null;
              };
            }>;
            question: {
              __typename?: "CandidateQuestion";
              id: string;
              conditional: boolean;
              multipleAnswers: boolean;
              questionType: QuestionType;
              title: string;
              fields: Array<{
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              }>;
              conditionalElements: Array<{
                __typename?: "CandidateQuestionConditionalFields";
                id?: string | null;
                fields: Array<{
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                }>;
                conditions: Array<{
                  __typename?: "CandidateFieldCondition";
                  id?: string | null;
                  fieldValue: {
                    __typename?: "CandidateFieldValue";
                    field: {
                      __typename?: "CandidateField";
                      id: string;
                      type: FieldType;
                      fieldType: HandleableFieldType;
                      required: boolean;
                      conditional: boolean;
                      restrictions: Array<
                        | {
                            __typename?: "DateRestriction";
                            comp: CompOperator;
                            dateValue: string;
                          }
                        | {
                            __typename?: "DurationRestriction";
                            unit: DateUnit;
                            durationValue: number;
                          }
                        | {
                            __typename?: "GapRestriction";
                            unit: DateUnit;
                            gapValue: number;
                          }
                        | {
                            __typename?: "LengthRestriction";
                            maxLength?: number | null;
                            minLength?: number | null;
                          }
                        | {
                            __typename?: "NumericRestriction";
                            comp: CompOperator;
                            numericValue: number;
                          }
                        | {
                            __typename?: "SelectRestriction";
                            multiSelect: boolean;
                            values: Array<{
                              __typename?: "SelectValue";
                              id: string;
                              title: {
                                __typename?: "LocalizedString";
                                current: string;
                              };
                            }>;
                          }
                      >;
                      description: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                      opsFields: Array<
                        | { __typename?: "OpsDvlaFieldValue" }
                        | {
                            __typename?: "OpsEbulkFieldValue";
                            ebulkValue: OpsEbulkFieldType;
                          }
                        | { __typename?: "OpsEquifaxFieldValue" }
                        | { __typename?: "OpsKonfirFieldValue" }
                        | {
                            __typename?: "OpsOcrFieldValue";
                            ocrValue: OpsOcrFieldType;
                          }
                        | { __typename?: "OpsTrustIdFieldValue" }
                      >;
                    };
                    value?:
                      | {
                          __typename?: "Address";
                          addressLine1: string;
                          addressLine2?: string | null;
                          addressLine3?: string | null;
                          county?: string | null;
                          postcode: string;
                          town: string;
                          country: {
                            __typename?: "Country";
                            id: string;
                            name: string;
                            isoCode: string;
                          };
                        }
                      | {
                          __typename?: "Attachment";
                          downloadUrl: string;
                          fileName: string;
                          id: string;
                          uploadDate: number;
                          uploadedBy: string;
                        }
                      | {
                          __typename?: "Attachments";
                          attachmentsValue: Array<{
                            __typename?: "Attachment";
                            downloadUrl: string;
                            fileName: string;
                            id: string;
                            uploadDate: number;
                            uploadedBy: string;
                          }>;
                        }
                      | { __typename?: "BooleanValue"; booleanValue: boolean }
                      | {
                          __typename?: "Company";
                          name: string;
                          address: {
                            __typename?: "Address";
                            addressLine1: string;
                            addressLine2?: string | null;
                            addressLine3?: string | null;
                            county?: string | null;
                            postcode: string;
                            town: string;
                            country: {
                              __typename?: "Country";
                              id: string;
                              name: string;
                              isoCode: string;
                            };
                          };
                        }
                      | {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        }
                      | {
                          __typename?: "DateRange";
                          from?: string | null;
                          to?: string | null;
                        }
                      | { __typename?: "DateValue"; dateValue: string }
                      | { __typename?: "FloatValue"; floatValue: number }
                      | { __typename?: "LongValue" }
                      | {
                          __typename?: "QualificationsAndGrades";
                          qualificationsAndGrades: Array<{
                            __typename?: "QualificationAndGrades";
                            qualification: string;
                            grades: Array<{
                              __typename?: "GradeTable";
                              grade?: string | null;
                              subject?: string | null;
                            }>;
                          }>;
                        }
                      | { __typename?: "StringValue"; stringValue: string }
                      | {
                          __typename?: "StringValues";
                          stringValues: Array<string>;
                        }
                      | null;
                  };
                }>;
              }>;
            };
          }>;
          conditionalValidations: Array<{
            __typename?: "CandidateProductConditionalValidation";
            id?: string | null;
            validationMessage: string;
            answersCondition?: {
              __typename?: "CandidateAnswersCondition";
              maxNumberOfAnswers?: number | null;
              minNumberOfAnswers?: number | null;
            } | null;
            conditions: Array<{
              __typename?: "CandidateFieldCondition";
              id?: string | null;
              fieldValue: {
                __typename?: "CandidateFieldValue";
                field: {
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                };
                value?:
                  | {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    }
                  | {
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }
                  | {
                      __typename?: "Attachments";
                      attachmentsValue: Array<{
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }>;
                    }
                  | { __typename?: "BooleanValue"; booleanValue: boolean }
                  | {
                      __typename?: "Company";
                      name: string;
                      address: {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      };
                    }
                  | {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    }
                  | {
                      __typename?: "DateRange";
                      from?: string | null;
                      to?: string | null;
                    }
                  | { __typename?: "DateValue"; dateValue: string }
                  | { __typename?: "FloatValue"; floatValue: number }
                  | { __typename?: "LongValue" }
                  | {
                      __typename?: "QualificationsAndGrades";
                      qualificationsAndGrades: Array<{
                        __typename?: "QualificationAndGrades";
                        qualification: string;
                        grades: Array<{
                          __typename?: "GradeTable";
                          grade?: string | null;
                          subject?: string | null;
                        }>;
                      }>;
                    }
                  | { __typename?: "StringValue"; stringValue: string }
                  | { __typename?: "StringValues"; stringValues: Array<string> }
                  | null;
              };
            }>;
          }>;
        };
      }>;
    };
  };
};

export type SaveUploadHistoryMutationVariables = Exact<{
  input: CandidateCaseSaveDraftInput;
}>;

export type SaveUploadHistoryMutation = {
  __typename?: "Mutation";
  saveDraft: {
    __typename?: "CandidateCaseSaveDraftPayload";
    case: {
      __typename?: "CandidateCase";
      id: string;
      code: string;
      status: CandidateCaseStatus;
      deadline: number;
      ocrTriggered: boolean;
      consentType: ConsentType;
      consentText?: string | null;
      troubleshotByScreener: boolean;
      consentGiven: boolean;
      whenCreated: number;
      whenCompleted?: number | null;
      draft?: string | null;
      hasEnhancedCriminalityCheck: boolean;
      additionalCandidateAttachments: Array<{
        __typename?: "AdditionalCandidateAttachments";
        note?: string | null;
        whenUploaded: number;
        candidateAttachments: Array<{
          __typename?: "Attachment";
          downloadUrl: string;
          fileName: string;
          id: string;
          uploadDate: number;
          uploadedBy: string;
        }>;
      }>;
      candidate: {
        __typename?: "Candidate";
        firstName: string;
        lastName: string;
        emailAddress: string;
      };
      eSignature?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
      trustIdApplication?: {
        __typename?: "CandidateTrustIdApplication";
        state: {
          __typename?: "TrustIdApplicationState";
          status: TrustIdApplicationStatus;
        };
      } | null;
      consentForm?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
      originalConsentForm?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
      client: {
        __typename?: "CandidateClient";
        id: string;
        name: string;
        liveChatEnabled: boolean;
        logo?: {
          __typename?: "Attachment";
          downloadUrl: string;
          fileName: string;
          id: string;
          uploadDate: number;
          uploadedBy: string;
        } | null;
      };
      sectionsByAffectingField: Array<{
        __typename?: "SectionsByAffectingField";
        conditionalProductsAffectedSections: Array<Section>;
        fieldId: string;
      }>;
      sections: Array<{
        __typename?: "CandidateDataSection";
        completed: boolean;
        section: Section;
        answers: Array<{
          __typename?: "CandidateAnswer";
          conditionFulfilled: boolean;
          answers: Array<{
            __typename?: "CandidateAnswerDetails";
            isCandidateAnswer: boolean;
            id: string;
            fieldValues: Array<{
              __typename?: "CandidateFieldValue";
              skip: boolean;
              field: {
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              };
              value?:
                | {
                    __typename?: "Address";
                    addressLine1: string;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    county?: string | null;
                    postcode: string;
                    town: string;
                    country: {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    };
                  }
                | {
                    __typename?: "Attachment";
                    downloadUrl: string;
                    fileName: string;
                    id: string;
                    uploadDate: number;
                    uploadedBy: string;
                  }
                | {
                    __typename?: "Attachments";
                    attachmentsValue: Array<{
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }>;
                  }
                | { __typename?: "BooleanValue"; booleanValue: boolean }
                | {
                    __typename?: "Company";
                    name: string;
                    address: {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    };
                  }
                | {
                    __typename?: "Country";
                    id: string;
                    name: string;
                    isoCode: string;
                  }
                | {
                    __typename?: "DateRange";
                    from?: string | null;
                    to?: string | null;
                  }
                | { __typename?: "DateValue"; dateValue: string }
                | { __typename?: "FloatValue"; floatValue: number }
                | { __typename?: "LongValue" }
                | {
                    __typename?: "QualificationsAndGrades";
                    qualificationsAndGrades: Array<{
                      __typename?: "QualificationAndGrades";
                      qualification: string;
                      grades: Array<{
                        __typename?: "GradeTable";
                        grade?: string | null;
                        subject?: string | null;
                      }>;
                    }>;
                  }
                | { __typename?: "StringValue"; stringValue: string }
                | { __typename?: "StringValues"; stringValues: Array<string> }
                | null;
            }>;
          }>;
          question: {
            __typename?: "CandidateQuestion";
            id: string;
            conditional: boolean;
            multipleAnswers: boolean;
            questionType: QuestionType;
            title: string;
            fields: Array<{
              __typename?: "CandidateField";
              id: string;
              type: FieldType;
              fieldType: HandleableFieldType;
              required: boolean;
              conditional: boolean;
              restrictions: Array<
                | {
                    __typename?: "DateRestriction";
                    comp: CompOperator;
                    dateValue: string;
                  }
                | {
                    __typename?: "DurationRestriction";
                    unit: DateUnit;
                    durationValue: number;
                  }
                | {
                    __typename?: "GapRestriction";
                    unit: DateUnit;
                    gapValue: number;
                  }
                | {
                    __typename?: "LengthRestriction";
                    maxLength?: number | null;
                    minLength?: number | null;
                  }
                | {
                    __typename?: "NumericRestriction";
                    comp: CompOperator;
                    numericValue: number;
                  }
                | {
                    __typename?: "SelectRestriction";
                    multiSelect: boolean;
                    values: Array<{
                      __typename?: "SelectValue";
                      id: string;
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                    }>;
                  }
              >;
              description: { __typename?: "LocalizedString"; current: string };
              title: { __typename?: "LocalizedString"; current: string };
              opsFields: Array<
                | { __typename?: "OpsDvlaFieldValue" }
                | {
                    __typename?: "OpsEbulkFieldValue";
                    ebulkValue: OpsEbulkFieldType;
                  }
                | { __typename?: "OpsEquifaxFieldValue" }
                | { __typename?: "OpsKonfirFieldValue" }
                | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
                | { __typename?: "OpsTrustIdFieldValue" }
              >;
            }>;
            conditionalElements: Array<{
              __typename?: "CandidateQuestionConditionalFields";
              id?: string | null;
              fields: Array<{
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              }>;
              conditions: Array<{
                __typename?: "CandidateFieldCondition";
                id?: string | null;
                fieldValue: {
                  __typename?: "CandidateFieldValue";
                  field: {
                    __typename?: "CandidateField";
                    id: string;
                    type: FieldType;
                    fieldType: HandleableFieldType;
                    required: boolean;
                    conditional: boolean;
                    restrictions: Array<
                      | {
                          __typename?: "DateRestriction";
                          comp: CompOperator;
                          dateValue: string;
                        }
                      | {
                          __typename?: "DurationRestriction";
                          unit: DateUnit;
                          durationValue: number;
                        }
                      | {
                          __typename?: "GapRestriction";
                          unit: DateUnit;
                          gapValue: number;
                        }
                      | {
                          __typename?: "LengthRestriction";
                          maxLength?: number | null;
                          minLength?: number | null;
                        }
                      | {
                          __typename?: "NumericRestriction";
                          comp: CompOperator;
                          numericValue: number;
                        }
                      | {
                          __typename?: "SelectRestriction";
                          multiSelect: boolean;
                          values: Array<{
                            __typename?: "SelectValue";
                            id: string;
                            title: {
                              __typename?: "LocalizedString";
                              current: string;
                            };
                          }>;
                        }
                    >;
                    description: {
                      __typename?: "LocalizedString";
                      current: string;
                    };
                    title: { __typename?: "LocalizedString"; current: string };
                    opsFields: Array<
                      | { __typename?: "OpsDvlaFieldValue" }
                      | {
                          __typename?: "OpsEbulkFieldValue";
                          ebulkValue: OpsEbulkFieldType;
                        }
                      | { __typename?: "OpsEquifaxFieldValue" }
                      | { __typename?: "OpsKonfirFieldValue" }
                      | {
                          __typename?: "OpsOcrFieldValue";
                          ocrValue: OpsOcrFieldType;
                        }
                      | { __typename?: "OpsTrustIdFieldValue" }
                    >;
                  };
                  value?:
                    | {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      }
                    | {
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }
                    | {
                        __typename?: "Attachments";
                        attachmentsValue: Array<{
                          __typename?: "Attachment";
                          downloadUrl: string;
                          fileName: string;
                          id: string;
                          uploadDate: number;
                          uploadedBy: string;
                        }>;
                      }
                    | { __typename?: "BooleanValue"; booleanValue: boolean }
                    | {
                        __typename?: "Company";
                        name: string;
                        address: {
                          __typename?: "Address";
                          addressLine1: string;
                          addressLine2?: string | null;
                          addressLine3?: string | null;
                          county?: string | null;
                          postcode: string;
                          town: string;
                          country: {
                            __typename?: "Country";
                            id: string;
                            name: string;
                            isoCode: string;
                          };
                        };
                      }
                    | {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      }
                    | {
                        __typename?: "DateRange";
                        from?: string | null;
                        to?: string | null;
                      }
                    | { __typename?: "DateValue"; dateValue: string }
                    | { __typename?: "FloatValue"; floatValue: number }
                    | { __typename?: "LongValue" }
                    | {
                        __typename?: "QualificationsAndGrades";
                        qualificationsAndGrades: Array<{
                          __typename?: "QualificationAndGrades";
                          qualification: string;
                          grades: Array<{
                            __typename?: "GradeTable";
                            grade?: string | null;
                            subject?: string | null;
                          }>;
                        }>;
                      }
                    | { __typename?: "StringValue"; stringValue: string }
                    | {
                        __typename?: "StringValues";
                        stringValues: Array<string>;
                      }
                    | null;
                };
              }>;
            }>;
          };
        }>;
      }>;
      contract: {
        __typename?: "CandidateContract";
        id: string;
        support: {
          __typename?: "CandidateContractSupport";
          supportEmailAddress: string;
          supportPhoneNumber: string;
        };
      };
      products: Array<{
        __typename?: "CandidateCaseProduct";
        id: string;
        status: CandidateCaseProductStatus;
        name: string;
        product: {
          __typename?: "CandidateProduct";
          id: string;
          conditionalQuestions: Array<{
            __typename?: "CandidateProductConditionalQuestion";
            id?: string | null;
            conditions: Array<{
              __typename?: "CandidateFieldCondition";
              id?: string | null;
              fieldValue: {
                __typename?: "CandidateFieldValue";
                field: {
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                };
                value?:
                  | {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    }
                  | {
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }
                  | {
                      __typename?: "Attachments";
                      attachmentsValue: Array<{
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }>;
                    }
                  | { __typename?: "BooleanValue"; booleanValue: boolean }
                  | {
                      __typename?: "Company";
                      name: string;
                      address: {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      };
                    }
                  | {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    }
                  | {
                      __typename?: "DateRange";
                      from?: string | null;
                      to?: string | null;
                    }
                  | { __typename?: "DateValue"; dateValue: string }
                  | { __typename?: "FloatValue"; floatValue: number }
                  | { __typename?: "LongValue" }
                  | {
                      __typename?: "QualificationsAndGrades";
                      qualificationsAndGrades: Array<{
                        __typename?: "QualificationAndGrades";
                        qualification: string;
                        grades: Array<{
                          __typename?: "GradeTable";
                          grade?: string | null;
                          subject?: string | null;
                        }>;
                      }>;
                    }
                  | { __typename?: "StringValue"; stringValue: string }
                  | { __typename?: "StringValues"; stringValues: Array<string> }
                  | null;
              };
            }>;
            question: {
              __typename?: "CandidateQuestion";
              id: string;
              conditional: boolean;
              multipleAnswers: boolean;
              questionType: QuestionType;
              title: string;
              fields: Array<{
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              }>;
              conditionalElements: Array<{
                __typename?: "CandidateQuestionConditionalFields";
                id?: string | null;
                fields: Array<{
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                }>;
                conditions: Array<{
                  __typename?: "CandidateFieldCondition";
                  id?: string | null;
                  fieldValue: {
                    __typename?: "CandidateFieldValue";
                    field: {
                      __typename?: "CandidateField";
                      id: string;
                      type: FieldType;
                      fieldType: HandleableFieldType;
                      required: boolean;
                      conditional: boolean;
                      restrictions: Array<
                        | {
                            __typename?: "DateRestriction";
                            comp: CompOperator;
                            dateValue: string;
                          }
                        | {
                            __typename?: "DurationRestriction";
                            unit: DateUnit;
                            durationValue: number;
                          }
                        | {
                            __typename?: "GapRestriction";
                            unit: DateUnit;
                            gapValue: number;
                          }
                        | {
                            __typename?: "LengthRestriction";
                            maxLength?: number | null;
                            minLength?: number | null;
                          }
                        | {
                            __typename?: "NumericRestriction";
                            comp: CompOperator;
                            numericValue: number;
                          }
                        | {
                            __typename?: "SelectRestriction";
                            multiSelect: boolean;
                            values: Array<{
                              __typename?: "SelectValue";
                              id: string;
                              title: {
                                __typename?: "LocalizedString";
                                current: string;
                              };
                            }>;
                          }
                      >;
                      description: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                      opsFields: Array<
                        | { __typename?: "OpsDvlaFieldValue" }
                        | {
                            __typename?: "OpsEbulkFieldValue";
                            ebulkValue: OpsEbulkFieldType;
                          }
                        | { __typename?: "OpsEquifaxFieldValue" }
                        | { __typename?: "OpsKonfirFieldValue" }
                        | {
                            __typename?: "OpsOcrFieldValue";
                            ocrValue: OpsOcrFieldType;
                          }
                        | { __typename?: "OpsTrustIdFieldValue" }
                      >;
                    };
                    value?:
                      | {
                          __typename?: "Address";
                          addressLine1: string;
                          addressLine2?: string | null;
                          addressLine3?: string | null;
                          county?: string | null;
                          postcode: string;
                          town: string;
                          country: {
                            __typename?: "Country";
                            id: string;
                            name: string;
                            isoCode: string;
                          };
                        }
                      | {
                          __typename?: "Attachment";
                          downloadUrl: string;
                          fileName: string;
                          id: string;
                          uploadDate: number;
                          uploadedBy: string;
                        }
                      | {
                          __typename?: "Attachments";
                          attachmentsValue: Array<{
                            __typename?: "Attachment";
                            downloadUrl: string;
                            fileName: string;
                            id: string;
                            uploadDate: number;
                            uploadedBy: string;
                          }>;
                        }
                      | { __typename?: "BooleanValue"; booleanValue: boolean }
                      | {
                          __typename?: "Company";
                          name: string;
                          address: {
                            __typename?: "Address";
                            addressLine1: string;
                            addressLine2?: string | null;
                            addressLine3?: string | null;
                            county?: string | null;
                            postcode: string;
                            town: string;
                            country: {
                              __typename?: "Country";
                              id: string;
                              name: string;
                              isoCode: string;
                            };
                          };
                        }
                      | {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        }
                      | {
                          __typename?: "DateRange";
                          from?: string | null;
                          to?: string | null;
                        }
                      | { __typename?: "DateValue"; dateValue: string }
                      | { __typename?: "FloatValue"; floatValue: number }
                      | { __typename?: "LongValue" }
                      | {
                          __typename?: "QualificationsAndGrades";
                          qualificationsAndGrades: Array<{
                            __typename?: "QualificationAndGrades";
                            qualification: string;
                            grades: Array<{
                              __typename?: "GradeTable";
                              grade?: string | null;
                              subject?: string | null;
                            }>;
                          }>;
                        }
                      | { __typename?: "StringValue"; stringValue: string }
                      | {
                          __typename?: "StringValues";
                          stringValues: Array<string>;
                        }
                      | null;
                  };
                }>;
              }>;
            };
          }>;
          conditionalValidations: Array<{
            __typename?: "CandidateProductConditionalValidation";
            id?: string | null;
            validationMessage: string;
            answersCondition?: {
              __typename?: "CandidateAnswersCondition";
              maxNumberOfAnswers?: number | null;
              minNumberOfAnswers?: number | null;
            } | null;
            conditions: Array<{
              __typename?: "CandidateFieldCondition";
              id?: string | null;
              fieldValue: {
                __typename?: "CandidateFieldValue";
                field: {
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                };
                value?:
                  | {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    }
                  | {
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }
                  | {
                      __typename?: "Attachments";
                      attachmentsValue: Array<{
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }>;
                    }
                  | { __typename?: "BooleanValue"; booleanValue: boolean }
                  | {
                      __typename?: "Company";
                      name: string;
                      address: {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      };
                    }
                  | {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    }
                  | {
                      __typename?: "DateRange";
                      from?: string | null;
                      to?: string | null;
                    }
                  | { __typename?: "DateValue"; dateValue: string }
                  | { __typename?: "FloatValue"; floatValue: number }
                  | { __typename?: "LongValue" }
                  | {
                      __typename?: "QualificationsAndGrades";
                      qualificationsAndGrades: Array<{
                        __typename?: "QualificationAndGrades";
                        qualification: string;
                        grades: Array<{
                          __typename?: "GradeTable";
                          grade?: string | null;
                          subject?: string | null;
                        }>;
                      }>;
                    }
                  | { __typename?: "StringValue"; stringValue: string }
                  | { __typename?: "StringValues"; stringValues: Array<string> }
                  | null;
              };
            }>;
          }>;
        };
      }>;
    };
  };
};

export type CaseSubmitMutationVariables = Exact<{
  input: CandidateCaseSubmitInput;
}>;

export type CaseSubmitMutation = {
  __typename?: "Mutation";
  caseSubmit: {
    __typename?: "CandidateCaseSubmitPayload";
    case: {
      __typename?: "CandidateCase";
      id: string;
      code: string;
      status: CandidateCaseStatus;
      deadline: number;
      ocrTriggered: boolean;
      consentType: ConsentType;
      consentText?: string | null;
      troubleshotByScreener: boolean;
      consentGiven: boolean;
      whenCreated: number;
      whenCompleted?: number | null;
      draft?: string | null;
      hasEnhancedCriminalityCheck: boolean;
      additionalCandidateAttachments: Array<{
        __typename?: "AdditionalCandidateAttachments";
        note?: string | null;
        whenUploaded: number;
        candidateAttachments: Array<{
          __typename?: "Attachment";
          downloadUrl: string;
          fileName: string;
          id: string;
          uploadDate: number;
          uploadedBy: string;
        }>;
      }>;
      candidate: {
        __typename?: "Candidate";
        firstName: string;
        lastName: string;
        emailAddress: string;
      };
      eSignature?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
      trustIdApplication?: {
        __typename?: "CandidateTrustIdApplication";
        state: {
          __typename?: "TrustIdApplicationState";
          status: TrustIdApplicationStatus;
        };
      } | null;
      consentForm?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
      originalConsentForm?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
      client: {
        __typename?: "CandidateClient";
        id: string;
        name: string;
        liveChatEnabled: boolean;
        logo?: {
          __typename?: "Attachment";
          downloadUrl: string;
          fileName: string;
          id: string;
          uploadDate: number;
          uploadedBy: string;
        } | null;
      };
      sectionsByAffectingField: Array<{
        __typename?: "SectionsByAffectingField";
        conditionalProductsAffectedSections: Array<Section>;
        fieldId: string;
      }>;
      sections: Array<{
        __typename?: "CandidateDataSection";
        completed: boolean;
        section: Section;
        answers: Array<{
          __typename?: "CandidateAnswer";
          conditionFulfilled: boolean;
          answers: Array<{
            __typename?: "CandidateAnswerDetails";
            isCandidateAnswer: boolean;
            id: string;
            fieldValues: Array<{
              __typename?: "CandidateFieldValue";
              skip: boolean;
              field: {
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              };
              value?:
                | {
                    __typename?: "Address";
                    addressLine1: string;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    county?: string | null;
                    postcode: string;
                    town: string;
                    country: {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    };
                  }
                | {
                    __typename?: "Attachment";
                    downloadUrl: string;
                    fileName: string;
                    id: string;
                    uploadDate: number;
                    uploadedBy: string;
                  }
                | {
                    __typename?: "Attachments";
                    attachmentsValue: Array<{
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }>;
                  }
                | { __typename?: "BooleanValue"; booleanValue: boolean }
                | {
                    __typename?: "Company";
                    name: string;
                    address: {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    };
                  }
                | {
                    __typename?: "Country";
                    id: string;
                    name: string;
                    isoCode: string;
                  }
                | {
                    __typename?: "DateRange";
                    from?: string | null;
                    to?: string | null;
                  }
                | { __typename?: "DateValue"; dateValue: string }
                | { __typename?: "FloatValue"; floatValue: number }
                | { __typename?: "LongValue" }
                | {
                    __typename?: "QualificationsAndGrades";
                    qualificationsAndGrades: Array<{
                      __typename?: "QualificationAndGrades";
                      qualification: string;
                      grades: Array<{
                        __typename?: "GradeTable";
                        grade?: string | null;
                        subject?: string | null;
                      }>;
                    }>;
                  }
                | { __typename?: "StringValue"; stringValue: string }
                | { __typename?: "StringValues"; stringValues: Array<string> }
                | null;
            }>;
          }>;
          question: {
            __typename?: "CandidateQuestion";
            id: string;
            conditional: boolean;
            multipleAnswers: boolean;
            questionType: QuestionType;
            title: string;
            fields: Array<{
              __typename?: "CandidateField";
              id: string;
              type: FieldType;
              fieldType: HandleableFieldType;
              required: boolean;
              conditional: boolean;
              restrictions: Array<
                | {
                    __typename?: "DateRestriction";
                    comp: CompOperator;
                    dateValue: string;
                  }
                | {
                    __typename?: "DurationRestriction";
                    unit: DateUnit;
                    durationValue: number;
                  }
                | {
                    __typename?: "GapRestriction";
                    unit: DateUnit;
                    gapValue: number;
                  }
                | {
                    __typename?: "LengthRestriction";
                    maxLength?: number | null;
                    minLength?: number | null;
                  }
                | {
                    __typename?: "NumericRestriction";
                    comp: CompOperator;
                    numericValue: number;
                  }
                | {
                    __typename?: "SelectRestriction";
                    multiSelect: boolean;
                    values: Array<{
                      __typename?: "SelectValue";
                      id: string;
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                    }>;
                  }
              >;
              description: { __typename?: "LocalizedString"; current: string };
              title: { __typename?: "LocalizedString"; current: string };
              opsFields: Array<
                | { __typename?: "OpsDvlaFieldValue" }
                | {
                    __typename?: "OpsEbulkFieldValue";
                    ebulkValue: OpsEbulkFieldType;
                  }
                | { __typename?: "OpsEquifaxFieldValue" }
                | { __typename?: "OpsKonfirFieldValue" }
                | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
                | { __typename?: "OpsTrustIdFieldValue" }
              >;
            }>;
            conditionalElements: Array<{
              __typename?: "CandidateQuestionConditionalFields";
              id?: string | null;
              fields: Array<{
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              }>;
              conditions: Array<{
                __typename?: "CandidateFieldCondition";
                id?: string | null;
                fieldValue: {
                  __typename?: "CandidateFieldValue";
                  field: {
                    __typename?: "CandidateField";
                    id: string;
                    type: FieldType;
                    fieldType: HandleableFieldType;
                    required: boolean;
                    conditional: boolean;
                    restrictions: Array<
                      | {
                          __typename?: "DateRestriction";
                          comp: CompOperator;
                          dateValue: string;
                        }
                      | {
                          __typename?: "DurationRestriction";
                          unit: DateUnit;
                          durationValue: number;
                        }
                      | {
                          __typename?: "GapRestriction";
                          unit: DateUnit;
                          gapValue: number;
                        }
                      | {
                          __typename?: "LengthRestriction";
                          maxLength?: number | null;
                          minLength?: number | null;
                        }
                      | {
                          __typename?: "NumericRestriction";
                          comp: CompOperator;
                          numericValue: number;
                        }
                      | {
                          __typename?: "SelectRestriction";
                          multiSelect: boolean;
                          values: Array<{
                            __typename?: "SelectValue";
                            id: string;
                            title: {
                              __typename?: "LocalizedString";
                              current: string;
                            };
                          }>;
                        }
                    >;
                    description: {
                      __typename?: "LocalizedString";
                      current: string;
                    };
                    title: { __typename?: "LocalizedString"; current: string };
                    opsFields: Array<
                      | { __typename?: "OpsDvlaFieldValue" }
                      | {
                          __typename?: "OpsEbulkFieldValue";
                          ebulkValue: OpsEbulkFieldType;
                        }
                      | { __typename?: "OpsEquifaxFieldValue" }
                      | { __typename?: "OpsKonfirFieldValue" }
                      | {
                          __typename?: "OpsOcrFieldValue";
                          ocrValue: OpsOcrFieldType;
                        }
                      | { __typename?: "OpsTrustIdFieldValue" }
                    >;
                  };
                  value?:
                    | {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      }
                    | {
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }
                    | {
                        __typename?: "Attachments";
                        attachmentsValue: Array<{
                          __typename?: "Attachment";
                          downloadUrl: string;
                          fileName: string;
                          id: string;
                          uploadDate: number;
                          uploadedBy: string;
                        }>;
                      }
                    | { __typename?: "BooleanValue"; booleanValue: boolean }
                    | {
                        __typename?: "Company";
                        name: string;
                        address: {
                          __typename?: "Address";
                          addressLine1: string;
                          addressLine2?: string | null;
                          addressLine3?: string | null;
                          county?: string | null;
                          postcode: string;
                          town: string;
                          country: {
                            __typename?: "Country";
                            id: string;
                            name: string;
                            isoCode: string;
                          };
                        };
                      }
                    | {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      }
                    | {
                        __typename?: "DateRange";
                        from?: string | null;
                        to?: string | null;
                      }
                    | { __typename?: "DateValue"; dateValue: string }
                    | { __typename?: "FloatValue"; floatValue: number }
                    | { __typename?: "LongValue" }
                    | {
                        __typename?: "QualificationsAndGrades";
                        qualificationsAndGrades: Array<{
                          __typename?: "QualificationAndGrades";
                          qualification: string;
                          grades: Array<{
                            __typename?: "GradeTable";
                            grade?: string | null;
                            subject?: string | null;
                          }>;
                        }>;
                      }
                    | { __typename?: "StringValue"; stringValue: string }
                    | {
                        __typename?: "StringValues";
                        stringValues: Array<string>;
                      }
                    | null;
                };
              }>;
            }>;
          };
        }>;
      }>;
      contract: {
        __typename?: "CandidateContract";
        id: string;
        support: {
          __typename?: "CandidateContractSupport";
          supportEmailAddress: string;
          supportPhoneNumber: string;
        };
      };
      products: Array<{
        __typename?: "CandidateCaseProduct";
        id: string;
        status: CandidateCaseProductStatus;
        name: string;
        product: {
          __typename?: "CandidateProduct";
          id: string;
          conditionalQuestions: Array<{
            __typename?: "CandidateProductConditionalQuestion";
            id?: string | null;
            conditions: Array<{
              __typename?: "CandidateFieldCondition";
              id?: string | null;
              fieldValue: {
                __typename?: "CandidateFieldValue";
                field: {
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                };
                value?:
                  | {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    }
                  | {
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }
                  | {
                      __typename?: "Attachments";
                      attachmentsValue: Array<{
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }>;
                    }
                  | { __typename?: "BooleanValue"; booleanValue: boolean }
                  | {
                      __typename?: "Company";
                      name: string;
                      address: {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      };
                    }
                  | {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    }
                  | {
                      __typename?: "DateRange";
                      from?: string | null;
                      to?: string | null;
                    }
                  | { __typename?: "DateValue"; dateValue: string }
                  | { __typename?: "FloatValue"; floatValue: number }
                  | { __typename?: "LongValue" }
                  | {
                      __typename?: "QualificationsAndGrades";
                      qualificationsAndGrades: Array<{
                        __typename?: "QualificationAndGrades";
                        qualification: string;
                        grades: Array<{
                          __typename?: "GradeTable";
                          grade?: string | null;
                          subject?: string | null;
                        }>;
                      }>;
                    }
                  | { __typename?: "StringValue"; stringValue: string }
                  | { __typename?: "StringValues"; stringValues: Array<string> }
                  | null;
              };
            }>;
            question: {
              __typename?: "CandidateQuestion";
              id: string;
              conditional: boolean;
              multipleAnswers: boolean;
              questionType: QuestionType;
              title: string;
              fields: Array<{
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              }>;
              conditionalElements: Array<{
                __typename?: "CandidateQuestionConditionalFields";
                id?: string | null;
                fields: Array<{
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                }>;
                conditions: Array<{
                  __typename?: "CandidateFieldCondition";
                  id?: string | null;
                  fieldValue: {
                    __typename?: "CandidateFieldValue";
                    field: {
                      __typename?: "CandidateField";
                      id: string;
                      type: FieldType;
                      fieldType: HandleableFieldType;
                      required: boolean;
                      conditional: boolean;
                      restrictions: Array<
                        | {
                            __typename?: "DateRestriction";
                            comp: CompOperator;
                            dateValue: string;
                          }
                        | {
                            __typename?: "DurationRestriction";
                            unit: DateUnit;
                            durationValue: number;
                          }
                        | {
                            __typename?: "GapRestriction";
                            unit: DateUnit;
                            gapValue: number;
                          }
                        | {
                            __typename?: "LengthRestriction";
                            maxLength?: number | null;
                            minLength?: number | null;
                          }
                        | {
                            __typename?: "NumericRestriction";
                            comp: CompOperator;
                            numericValue: number;
                          }
                        | {
                            __typename?: "SelectRestriction";
                            multiSelect: boolean;
                            values: Array<{
                              __typename?: "SelectValue";
                              id: string;
                              title: {
                                __typename?: "LocalizedString";
                                current: string;
                              };
                            }>;
                          }
                      >;
                      description: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                      opsFields: Array<
                        | { __typename?: "OpsDvlaFieldValue" }
                        | {
                            __typename?: "OpsEbulkFieldValue";
                            ebulkValue: OpsEbulkFieldType;
                          }
                        | { __typename?: "OpsEquifaxFieldValue" }
                        | { __typename?: "OpsKonfirFieldValue" }
                        | {
                            __typename?: "OpsOcrFieldValue";
                            ocrValue: OpsOcrFieldType;
                          }
                        | { __typename?: "OpsTrustIdFieldValue" }
                      >;
                    };
                    value?:
                      | {
                          __typename?: "Address";
                          addressLine1: string;
                          addressLine2?: string | null;
                          addressLine3?: string | null;
                          county?: string | null;
                          postcode: string;
                          town: string;
                          country: {
                            __typename?: "Country";
                            id: string;
                            name: string;
                            isoCode: string;
                          };
                        }
                      | {
                          __typename?: "Attachment";
                          downloadUrl: string;
                          fileName: string;
                          id: string;
                          uploadDate: number;
                          uploadedBy: string;
                        }
                      | {
                          __typename?: "Attachments";
                          attachmentsValue: Array<{
                            __typename?: "Attachment";
                            downloadUrl: string;
                            fileName: string;
                            id: string;
                            uploadDate: number;
                            uploadedBy: string;
                          }>;
                        }
                      | { __typename?: "BooleanValue"; booleanValue: boolean }
                      | {
                          __typename?: "Company";
                          name: string;
                          address: {
                            __typename?: "Address";
                            addressLine1: string;
                            addressLine2?: string | null;
                            addressLine3?: string | null;
                            county?: string | null;
                            postcode: string;
                            town: string;
                            country: {
                              __typename?: "Country";
                              id: string;
                              name: string;
                              isoCode: string;
                            };
                          };
                        }
                      | {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        }
                      | {
                          __typename?: "DateRange";
                          from?: string | null;
                          to?: string | null;
                        }
                      | { __typename?: "DateValue"; dateValue: string }
                      | { __typename?: "FloatValue"; floatValue: number }
                      | { __typename?: "LongValue" }
                      | {
                          __typename?: "QualificationsAndGrades";
                          qualificationsAndGrades: Array<{
                            __typename?: "QualificationAndGrades";
                            qualification: string;
                            grades: Array<{
                              __typename?: "GradeTable";
                              grade?: string | null;
                              subject?: string | null;
                            }>;
                          }>;
                        }
                      | { __typename?: "StringValue"; stringValue: string }
                      | {
                          __typename?: "StringValues";
                          stringValues: Array<string>;
                        }
                      | null;
                  };
                }>;
              }>;
            };
          }>;
          conditionalValidations: Array<{
            __typename?: "CandidateProductConditionalValidation";
            id?: string | null;
            validationMessage: string;
            answersCondition?: {
              __typename?: "CandidateAnswersCondition";
              maxNumberOfAnswers?: number | null;
              minNumberOfAnswers?: number | null;
            } | null;
            conditions: Array<{
              __typename?: "CandidateFieldCondition";
              id?: string | null;
              fieldValue: {
                __typename?: "CandidateFieldValue";
                field: {
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                };
                value?:
                  | {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    }
                  | {
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }
                  | {
                      __typename?: "Attachments";
                      attachmentsValue: Array<{
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }>;
                    }
                  | { __typename?: "BooleanValue"; booleanValue: boolean }
                  | {
                      __typename?: "Company";
                      name: string;
                      address: {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      };
                    }
                  | {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    }
                  | {
                      __typename?: "DateRange";
                      from?: string | null;
                      to?: string | null;
                    }
                  | { __typename?: "DateValue"; dateValue: string }
                  | { __typename?: "FloatValue"; floatValue: number }
                  | { __typename?: "LongValue" }
                  | {
                      __typename?: "QualificationsAndGrades";
                      qualificationsAndGrades: Array<{
                        __typename?: "QualificationAndGrades";
                        qualification: string;
                        grades: Array<{
                          __typename?: "GradeTable";
                          grade?: string | null;
                          subject?: string | null;
                        }>;
                      }>;
                    }
                  | { __typename?: "StringValue"; stringValue: string }
                  | { __typename?: "StringValues"; stringValues: Array<string> }
                  | null;
              };
            }>;
          }>;
        };
      }>;
    };
  };
};

export type DoCaseTroubleshootingRequestUpdateMutationVariables = Exact<{
  input: CandidateCaseTroubleshootingRequestUpdateInput;
}>;

export type DoCaseTroubleshootingRequestUpdateMutation = {
  __typename?: "Mutation";
  caseTroubleshootingRequestUpdate: {
    __typename?: "CandidateCaseTroubleshootingRequestUpdatePayload";
    troubleshootingRequest: {
      __typename?: "CandidateCaseTroubleshootingRequest";
      id: string;
      status: TroubleshootingRequestStatus;
      createdWhen: number;
    };
  };
};

export type CaseUpdateMutationVariables = Exact<{
  files?: InputMaybe<
    Array<Scalars["Upload"]["input"]> | Scalars["Upload"]["input"]
  >;
  input: CandidateCaseUpdateInput;
}>;

export type CaseUpdateMutation = {
  __typename?: "Mutation";
  caseUpdate: {
    __typename?: "CandidateCaseUpdatePayload";
    case: {
      __typename?: "CandidateCase";
      id: string;
      code: string;
      status: CandidateCaseStatus;
      deadline: number;
      ocrTriggered: boolean;
      consentType: ConsentType;
      consentText?: string | null;
      troubleshotByScreener: boolean;
      consentGiven: boolean;
      whenCreated: number;
      whenCompleted?: number | null;
      draft?: string | null;
      hasEnhancedCriminalityCheck: boolean;
      additionalCandidateAttachments: Array<{
        __typename?: "AdditionalCandidateAttachments";
        note?: string | null;
        whenUploaded: number;
        candidateAttachments: Array<{
          __typename?: "Attachment";
          downloadUrl: string;
          fileName: string;
          id: string;
          uploadDate: number;
          uploadedBy: string;
        }>;
      }>;
      candidate: {
        __typename?: "Candidate";
        firstName: string;
        lastName: string;
        emailAddress: string;
      };
      eSignature?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
      trustIdApplication?: {
        __typename?: "CandidateTrustIdApplication";
        state: {
          __typename?: "TrustIdApplicationState";
          status: TrustIdApplicationStatus;
        };
      } | null;
      consentForm?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
      originalConsentForm?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
      client: {
        __typename?: "CandidateClient";
        id: string;
        name: string;
        liveChatEnabled: boolean;
        logo?: {
          __typename?: "Attachment";
          downloadUrl: string;
          fileName: string;
          id: string;
          uploadDate: number;
          uploadedBy: string;
        } | null;
      };
      sectionsByAffectingField: Array<{
        __typename?: "SectionsByAffectingField";
        conditionalProductsAffectedSections: Array<Section>;
        fieldId: string;
      }>;
      sections: Array<{
        __typename?: "CandidateDataSection";
        completed: boolean;
        section: Section;
        answers: Array<{
          __typename?: "CandidateAnswer";
          conditionFulfilled: boolean;
          answers: Array<{
            __typename?: "CandidateAnswerDetails";
            isCandidateAnswer: boolean;
            id: string;
            fieldValues: Array<{
              __typename?: "CandidateFieldValue";
              skip: boolean;
              field: {
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              };
              value?:
                | {
                    __typename?: "Address";
                    addressLine1: string;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    county?: string | null;
                    postcode: string;
                    town: string;
                    country: {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    };
                  }
                | {
                    __typename?: "Attachment";
                    downloadUrl: string;
                    fileName: string;
                    id: string;
                    uploadDate: number;
                    uploadedBy: string;
                  }
                | {
                    __typename?: "Attachments";
                    attachmentsValue: Array<{
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }>;
                  }
                | { __typename?: "BooleanValue"; booleanValue: boolean }
                | {
                    __typename?: "Company";
                    name: string;
                    address: {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    };
                  }
                | {
                    __typename?: "Country";
                    id: string;
                    name: string;
                    isoCode: string;
                  }
                | {
                    __typename?: "DateRange";
                    from?: string | null;
                    to?: string | null;
                  }
                | { __typename?: "DateValue"; dateValue: string }
                | { __typename?: "FloatValue"; floatValue: number }
                | { __typename?: "LongValue" }
                | {
                    __typename?: "QualificationsAndGrades";
                    qualificationsAndGrades: Array<{
                      __typename?: "QualificationAndGrades";
                      qualification: string;
                      grades: Array<{
                        __typename?: "GradeTable";
                        grade?: string | null;
                        subject?: string | null;
                      }>;
                    }>;
                  }
                | { __typename?: "StringValue"; stringValue: string }
                | { __typename?: "StringValues"; stringValues: Array<string> }
                | null;
            }>;
          }>;
          question: {
            __typename?: "CandidateQuestion";
            id: string;
            conditional: boolean;
            multipleAnswers: boolean;
            questionType: QuestionType;
            title: string;
            fields: Array<{
              __typename?: "CandidateField";
              id: string;
              type: FieldType;
              fieldType: HandleableFieldType;
              required: boolean;
              conditional: boolean;
              restrictions: Array<
                | {
                    __typename?: "DateRestriction";
                    comp: CompOperator;
                    dateValue: string;
                  }
                | {
                    __typename?: "DurationRestriction";
                    unit: DateUnit;
                    durationValue: number;
                  }
                | {
                    __typename?: "GapRestriction";
                    unit: DateUnit;
                    gapValue: number;
                  }
                | {
                    __typename?: "LengthRestriction";
                    maxLength?: number | null;
                    minLength?: number | null;
                  }
                | {
                    __typename?: "NumericRestriction";
                    comp: CompOperator;
                    numericValue: number;
                  }
                | {
                    __typename?: "SelectRestriction";
                    multiSelect: boolean;
                    values: Array<{
                      __typename?: "SelectValue";
                      id: string;
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                    }>;
                  }
              >;
              description: { __typename?: "LocalizedString"; current: string };
              title: { __typename?: "LocalizedString"; current: string };
              opsFields: Array<
                | { __typename?: "OpsDvlaFieldValue" }
                | {
                    __typename?: "OpsEbulkFieldValue";
                    ebulkValue: OpsEbulkFieldType;
                  }
                | { __typename?: "OpsEquifaxFieldValue" }
                | { __typename?: "OpsKonfirFieldValue" }
                | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
                | { __typename?: "OpsTrustIdFieldValue" }
              >;
            }>;
            conditionalElements: Array<{
              __typename?: "CandidateQuestionConditionalFields";
              id?: string | null;
              fields: Array<{
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              }>;
              conditions: Array<{
                __typename?: "CandidateFieldCondition";
                id?: string | null;
                fieldValue: {
                  __typename?: "CandidateFieldValue";
                  field: {
                    __typename?: "CandidateField";
                    id: string;
                    type: FieldType;
                    fieldType: HandleableFieldType;
                    required: boolean;
                    conditional: boolean;
                    restrictions: Array<
                      | {
                          __typename?: "DateRestriction";
                          comp: CompOperator;
                          dateValue: string;
                        }
                      | {
                          __typename?: "DurationRestriction";
                          unit: DateUnit;
                          durationValue: number;
                        }
                      | {
                          __typename?: "GapRestriction";
                          unit: DateUnit;
                          gapValue: number;
                        }
                      | {
                          __typename?: "LengthRestriction";
                          maxLength?: number | null;
                          minLength?: number | null;
                        }
                      | {
                          __typename?: "NumericRestriction";
                          comp: CompOperator;
                          numericValue: number;
                        }
                      | {
                          __typename?: "SelectRestriction";
                          multiSelect: boolean;
                          values: Array<{
                            __typename?: "SelectValue";
                            id: string;
                            title: {
                              __typename?: "LocalizedString";
                              current: string;
                            };
                          }>;
                        }
                    >;
                    description: {
                      __typename?: "LocalizedString";
                      current: string;
                    };
                    title: { __typename?: "LocalizedString"; current: string };
                    opsFields: Array<
                      | { __typename?: "OpsDvlaFieldValue" }
                      | {
                          __typename?: "OpsEbulkFieldValue";
                          ebulkValue: OpsEbulkFieldType;
                        }
                      | { __typename?: "OpsEquifaxFieldValue" }
                      | { __typename?: "OpsKonfirFieldValue" }
                      | {
                          __typename?: "OpsOcrFieldValue";
                          ocrValue: OpsOcrFieldType;
                        }
                      | { __typename?: "OpsTrustIdFieldValue" }
                    >;
                  };
                  value?:
                    | {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      }
                    | {
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }
                    | {
                        __typename?: "Attachments";
                        attachmentsValue: Array<{
                          __typename?: "Attachment";
                          downloadUrl: string;
                          fileName: string;
                          id: string;
                          uploadDate: number;
                          uploadedBy: string;
                        }>;
                      }
                    | { __typename?: "BooleanValue"; booleanValue: boolean }
                    | {
                        __typename?: "Company";
                        name: string;
                        address: {
                          __typename?: "Address";
                          addressLine1: string;
                          addressLine2?: string | null;
                          addressLine3?: string | null;
                          county?: string | null;
                          postcode: string;
                          town: string;
                          country: {
                            __typename?: "Country";
                            id: string;
                            name: string;
                            isoCode: string;
                          };
                        };
                      }
                    | {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      }
                    | {
                        __typename?: "DateRange";
                        from?: string | null;
                        to?: string | null;
                      }
                    | { __typename?: "DateValue"; dateValue: string }
                    | { __typename?: "FloatValue"; floatValue: number }
                    | { __typename?: "LongValue" }
                    | {
                        __typename?: "QualificationsAndGrades";
                        qualificationsAndGrades: Array<{
                          __typename?: "QualificationAndGrades";
                          qualification: string;
                          grades: Array<{
                            __typename?: "GradeTable";
                            grade?: string | null;
                            subject?: string | null;
                          }>;
                        }>;
                      }
                    | { __typename?: "StringValue"; stringValue: string }
                    | {
                        __typename?: "StringValues";
                        stringValues: Array<string>;
                      }
                    | null;
                };
              }>;
            }>;
          };
        }>;
      }>;
      contract: {
        __typename?: "CandidateContract";
        id: string;
        support: {
          __typename?: "CandidateContractSupport";
          supportEmailAddress: string;
          supportPhoneNumber: string;
        };
      };
      products: Array<{
        __typename?: "CandidateCaseProduct";
        id: string;
        status: CandidateCaseProductStatus;
        name: string;
        product: {
          __typename?: "CandidateProduct";
          id: string;
          conditionalQuestions: Array<{
            __typename?: "CandidateProductConditionalQuestion";
            id?: string | null;
            conditions: Array<{
              __typename?: "CandidateFieldCondition";
              id?: string | null;
              fieldValue: {
                __typename?: "CandidateFieldValue";
                field: {
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                };
                value?:
                  | {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    }
                  | {
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }
                  | {
                      __typename?: "Attachments";
                      attachmentsValue: Array<{
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }>;
                    }
                  | { __typename?: "BooleanValue"; booleanValue: boolean }
                  | {
                      __typename?: "Company";
                      name: string;
                      address: {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      };
                    }
                  | {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    }
                  | {
                      __typename?: "DateRange";
                      from?: string | null;
                      to?: string | null;
                    }
                  | { __typename?: "DateValue"; dateValue: string }
                  | { __typename?: "FloatValue"; floatValue: number }
                  | { __typename?: "LongValue" }
                  | {
                      __typename?: "QualificationsAndGrades";
                      qualificationsAndGrades: Array<{
                        __typename?: "QualificationAndGrades";
                        qualification: string;
                        grades: Array<{
                          __typename?: "GradeTable";
                          grade?: string | null;
                          subject?: string | null;
                        }>;
                      }>;
                    }
                  | { __typename?: "StringValue"; stringValue: string }
                  | { __typename?: "StringValues"; stringValues: Array<string> }
                  | null;
              };
            }>;
            question: {
              __typename?: "CandidateQuestion";
              id: string;
              conditional: boolean;
              multipleAnswers: boolean;
              questionType: QuestionType;
              title: string;
              fields: Array<{
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              }>;
              conditionalElements: Array<{
                __typename?: "CandidateQuestionConditionalFields";
                id?: string | null;
                fields: Array<{
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                }>;
                conditions: Array<{
                  __typename?: "CandidateFieldCondition";
                  id?: string | null;
                  fieldValue: {
                    __typename?: "CandidateFieldValue";
                    field: {
                      __typename?: "CandidateField";
                      id: string;
                      type: FieldType;
                      fieldType: HandleableFieldType;
                      required: boolean;
                      conditional: boolean;
                      restrictions: Array<
                        | {
                            __typename?: "DateRestriction";
                            comp: CompOperator;
                            dateValue: string;
                          }
                        | {
                            __typename?: "DurationRestriction";
                            unit: DateUnit;
                            durationValue: number;
                          }
                        | {
                            __typename?: "GapRestriction";
                            unit: DateUnit;
                            gapValue: number;
                          }
                        | {
                            __typename?: "LengthRestriction";
                            maxLength?: number | null;
                            minLength?: number | null;
                          }
                        | {
                            __typename?: "NumericRestriction";
                            comp: CompOperator;
                            numericValue: number;
                          }
                        | {
                            __typename?: "SelectRestriction";
                            multiSelect: boolean;
                            values: Array<{
                              __typename?: "SelectValue";
                              id: string;
                              title: {
                                __typename?: "LocalizedString";
                                current: string;
                              };
                            }>;
                          }
                      >;
                      description: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                      opsFields: Array<
                        | { __typename?: "OpsDvlaFieldValue" }
                        | {
                            __typename?: "OpsEbulkFieldValue";
                            ebulkValue: OpsEbulkFieldType;
                          }
                        | { __typename?: "OpsEquifaxFieldValue" }
                        | { __typename?: "OpsKonfirFieldValue" }
                        | {
                            __typename?: "OpsOcrFieldValue";
                            ocrValue: OpsOcrFieldType;
                          }
                        | { __typename?: "OpsTrustIdFieldValue" }
                      >;
                    };
                    value?:
                      | {
                          __typename?: "Address";
                          addressLine1: string;
                          addressLine2?: string | null;
                          addressLine3?: string | null;
                          county?: string | null;
                          postcode: string;
                          town: string;
                          country: {
                            __typename?: "Country";
                            id: string;
                            name: string;
                            isoCode: string;
                          };
                        }
                      | {
                          __typename?: "Attachment";
                          downloadUrl: string;
                          fileName: string;
                          id: string;
                          uploadDate: number;
                          uploadedBy: string;
                        }
                      | {
                          __typename?: "Attachments";
                          attachmentsValue: Array<{
                            __typename?: "Attachment";
                            downloadUrl: string;
                            fileName: string;
                            id: string;
                            uploadDate: number;
                            uploadedBy: string;
                          }>;
                        }
                      | { __typename?: "BooleanValue"; booleanValue: boolean }
                      | {
                          __typename?: "Company";
                          name: string;
                          address: {
                            __typename?: "Address";
                            addressLine1: string;
                            addressLine2?: string | null;
                            addressLine3?: string | null;
                            county?: string | null;
                            postcode: string;
                            town: string;
                            country: {
                              __typename?: "Country";
                              id: string;
                              name: string;
                              isoCode: string;
                            };
                          };
                        }
                      | {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        }
                      | {
                          __typename?: "DateRange";
                          from?: string | null;
                          to?: string | null;
                        }
                      | { __typename?: "DateValue"; dateValue: string }
                      | { __typename?: "FloatValue"; floatValue: number }
                      | { __typename?: "LongValue" }
                      | {
                          __typename?: "QualificationsAndGrades";
                          qualificationsAndGrades: Array<{
                            __typename?: "QualificationAndGrades";
                            qualification: string;
                            grades: Array<{
                              __typename?: "GradeTable";
                              grade?: string | null;
                              subject?: string | null;
                            }>;
                          }>;
                        }
                      | { __typename?: "StringValue"; stringValue: string }
                      | {
                          __typename?: "StringValues";
                          stringValues: Array<string>;
                        }
                      | null;
                  };
                }>;
              }>;
            };
          }>;
          conditionalValidations: Array<{
            __typename?: "CandidateProductConditionalValidation";
            id?: string | null;
            validationMessage: string;
            answersCondition?: {
              __typename?: "CandidateAnswersCondition";
              maxNumberOfAnswers?: number | null;
              minNumberOfAnswers?: number | null;
            } | null;
            conditions: Array<{
              __typename?: "CandidateFieldCondition";
              id?: string | null;
              fieldValue: {
                __typename?: "CandidateFieldValue";
                field: {
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                };
                value?:
                  | {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    }
                  | {
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }
                  | {
                      __typename?: "Attachments";
                      attachmentsValue: Array<{
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }>;
                    }
                  | { __typename?: "BooleanValue"; booleanValue: boolean }
                  | {
                      __typename?: "Company";
                      name: string;
                      address: {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      };
                    }
                  | {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    }
                  | {
                      __typename?: "DateRange";
                      from?: string | null;
                      to?: string | null;
                    }
                  | { __typename?: "DateValue"; dateValue: string }
                  | { __typename?: "FloatValue"; floatValue: number }
                  | { __typename?: "LongValue" }
                  | {
                      __typename?: "QualificationsAndGrades";
                      qualificationsAndGrades: Array<{
                        __typename?: "QualificationAndGrades";
                        qualification: string;
                        grades: Array<{
                          __typename?: "GradeTable";
                          grade?: string | null;
                          subject?: string | null;
                        }>;
                      }>;
                    }
                  | { __typename?: "StringValue"; stringValue: string }
                  | { __typename?: "StringValues"; stringValues: Array<string> }
                  | null;
              };
            }>;
          }>;
        };
      }>;
    };
  };
};

export type KonfirVerificationLinkCreateMutationVariables = Exact<{
  input: CandidateKonfirVerificationLinkCreateInput;
}>;

export type KonfirVerificationLinkCreateMutation = {
  __typename?: "Mutation";
  konfirVerificationLinkCreate: {
    __typename?: "CandidateKonfirVerificationLinkCreatePayload";
    verificationLink: string;
  };
};

export type CreateTrustIdGuestLinkMutationVariables = Exact<{
  input: CandidateTrustIdGuestLinkCreateInput;
}>;

export type CreateTrustIdGuestLinkMutation = {
  __typename?: "Mutation";
  trustIdGuestLinkCreate: {
    __typename?: "CandidateTrustIdGuestLinkCreatePayload";
    guestLink: string;
  };
};

export type AdditionalDocumentsUploadMutationVariables = Exact<{
  files: Array<Scalars["Upload"]["input"]> | Scalars["Upload"]["input"];
  input: CandidateUploadAdditionalDocumentInput;
}>;

export type AdditionalDocumentsUploadMutation = {
  __typename?: "Mutation";
  uploadAdditionalDocuments: {
    __typename?: "CandidateUploadAdditionalDocumentPayload";
    screeningCaseId: string;
  };
};

export type GetCaseQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetCaseQuery = {
  __typename?: "Query";
  screeningCase: {
    __typename?: "CandidateCase";
    id: string;
    code: string;
    status: CandidateCaseStatus;
    deadline: number;
    ocrTriggered: boolean;
    consentType: ConsentType;
    consentText?: string | null;
    troubleshotByScreener: boolean;
    consentGiven: boolean;
    whenCreated: number;
    whenCompleted?: number | null;
    draft?: string | null;
    hasEnhancedCriminalityCheck: boolean;
    additionalCandidateAttachments: Array<{
      __typename?: "AdditionalCandidateAttachments";
      note?: string | null;
      whenUploaded: number;
      candidateAttachments: Array<{
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      }>;
    }>;
    candidate: {
      __typename?: "Candidate";
      firstName: string;
      lastName: string;
      emailAddress: string;
    };
    eSignature?: {
      __typename?: "Attachment";
      downloadUrl: string;
      fileName: string;
      id: string;
      uploadDate: number;
      uploadedBy: string;
    } | null;
    trustIdApplication?: {
      __typename?: "CandidateTrustIdApplication";
      state: {
        __typename?: "TrustIdApplicationState";
        status: TrustIdApplicationStatus;
      };
    } | null;
    consentForm?: {
      __typename?: "Attachment";
      downloadUrl: string;
      fileName: string;
      id: string;
      uploadDate: number;
      uploadedBy: string;
    } | null;
    originalConsentForm?: {
      __typename?: "Attachment";
      downloadUrl: string;
      fileName: string;
      id: string;
      uploadDate: number;
      uploadedBy: string;
    } | null;
    client: {
      __typename?: "CandidateClient";
      id: string;
      name: string;
      liveChatEnabled: boolean;
      logo?: {
        __typename?: "Attachment";
        downloadUrl: string;
        fileName: string;
        id: string;
        uploadDate: number;
        uploadedBy: string;
      } | null;
    };
    sectionsByAffectingField: Array<{
      __typename?: "SectionsByAffectingField";
      conditionalProductsAffectedSections: Array<Section>;
      fieldId: string;
    }>;
    sections: Array<{
      __typename?: "CandidateDataSection";
      completed: boolean;
      section: Section;
      answers: Array<{
        __typename?: "CandidateAnswer";
        conditionFulfilled: boolean;
        answers: Array<{
          __typename?: "CandidateAnswerDetails";
          isCandidateAnswer: boolean;
          id: string;
          fieldValues: Array<{
            __typename?: "CandidateFieldValue";
            skip: boolean;
            field: {
              __typename?: "CandidateField";
              id: string;
              type: FieldType;
              fieldType: HandleableFieldType;
              required: boolean;
              conditional: boolean;
              restrictions: Array<
                | {
                    __typename?: "DateRestriction";
                    comp: CompOperator;
                    dateValue: string;
                  }
                | {
                    __typename?: "DurationRestriction";
                    unit: DateUnit;
                    durationValue: number;
                  }
                | {
                    __typename?: "GapRestriction";
                    unit: DateUnit;
                    gapValue: number;
                  }
                | {
                    __typename?: "LengthRestriction";
                    maxLength?: number | null;
                    minLength?: number | null;
                  }
                | {
                    __typename?: "NumericRestriction";
                    comp: CompOperator;
                    numericValue: number;
                  }
                | {
                    __typename?: "SelectRestriction";
                    multiSelect: boolean;
                    values: Array<{
                      __typename?: "SelectValue";
                      id: string;
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                    }>;
                  }
              >;
              description: { __typename?: "LocalizedString"; current: string };
              title: { __typename?: "LocalizedString"; current: string };
              opsFields: Array<
                | { __typename?: "OpsDvlaFieldValue" }
                | {
                    __typename?: "OpsEbulkFieldValue";
                    ebulkValue: OpsEbulkFieldType;
                  }
                | { __typename?: "OpsEquifaxFieldValue" }
                | { __typename?: "OpsKonfirFieldValue" }
                | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
                | { __typename?: "OpsTrustIdFieldValue" }
              >;
            };
            value?:
              | {
                  __typename?: "Address";
                  addressLine1: string;
                  addressLine2?: string | null;
                  addressLine3?: string | null;
                  county?: string | null;
                  postcode: string;
                  town: string;
                  country: {
                    __typename?: "Country";
                    id: string;
                    name: string;
                    isoCode: string;
                  };
                }
              | {
                  __typename?: "Attachment";
                  downloadUrl: string;
                  fileName: string;
                  id: string;
                  uploadDate: number;
                  uploadedBy: string;
                }
              | {
                  __typename?: "Attachments";
                  attachmentsValue: Array<{
                    __typename?: "Attachment";
                    downloadUrl: string;
                    fileName: string;
                    id: string;
                    uploadDate: number;
                    uploadedBy: string;
                  }>;
                }
              | { __typename?: "BooleanValue"; booleanValue: boolean }
              | {
                  __typename?: "Company";
                  name: string;
                  address: {
                    __typename?: "Address";
                    addressLine1: string;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    county?: string | null;
                    postcode: string;
                    town: string;
                    country: {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    };
                  };
                }
              | {
                  __typename?: "Country";
                  id: string;
                  name: string;
                  isoCode: string;
                }
              | {
                  __typename?: "DateRange";
                  from?: string | null;
                  to?: string | null;
                }
              | { __typename?: "DateValue"; dateValue: string }
              | { __typename?: "FloatValue"; floatValue: number }
              | { __typename?: "LongValue" }
              | {
                  __typename?: "QualificationsAndGrades";
                  qualificationsAndGrades: Array<{
                    __typename?: "QualificationAndGrades";
                    qualification: string;
                    grades: Array<{
                      __typename?: "GradeTable";
                      grade?: string | null;
                      subject?: string | null;
                    }>;
                  }>;
                }
              | { __typename?: "StringValue"; stringValue: string }
              | { __typename?: "StringValues"; stringValues: Array<string> }
              | null;
          }>;
        }>;
        question: {
          __typename?: "CandidateQuestion";
          id: string;
          conditional: boolean;
          multipleAnswers: boolean;
          questionType: QuestionType;
          title: string;
          fields: Array<{
            __typename?: "CandidateField";
            id: string;
            type: FieldType;
            fieldType: HandleableFieldType;
            required: boolean;
            conditional: boolean;
            restrictions: Array<
              | {
                  __typename?: "DateRestriction";
                  comp: CompOperator;
                  dateValue: string;
                }
              | {
                  __typename?: "DurationRestriction";
                  unit: DateUnit;
                  durationValue: number;
                }
              | {
                  __typename?: "GapRestriction";
                  unit: DateUnit;
                  gapValue: number;
                }
              | {
                  __typename?: "LengthRestriction";
                  maxLength?: number | null;
                  minLength?: number | null;
                }
              | {
                  __typename?: "NumericRestriction";
                  comp: CompOperator;
                  numericValue: number;
                }
              | {
                  __typename?: "SelectRestriction";
                  multiSelect: boolean;
                  values: Array<{
                    __typename?: "SelectValue";
                    id: string;
                    title: { __typename?: "LocalizedString"; current: string };
                  }>;
                }
            >;
            description: { __typename?: "LocalizedString"; current: string };
            title: { __typename?: "LocalizedString"; current: string };
            opsFields: Array<
              | { __typename?: "OpsDvlaFieldValue" }
              | {
                  __typename?: "OpsEbulkFieldValue";
                  ebulkValue: OpsEbulkFieldType;
                }
              | { __typename?: "OpsEquifaxFieldValue" }
              | { __typename?: "OpsKonfirFieldValue" }
              | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
              | { __typename?: "OpsTrustIdFieldValue" }
            >;
          }>;
          conditionalElements: Array<{
            __typename?: "CandidateQuestionConditionalFields";
            id?: string | null;
            fields: Array<{
              __typename?: "CandidateField";
              id: string;
              type: FieldType;
              fieldType: HandleableFieldType;
              required: boolean;
              conditional: boolean;
              restrictions: Array<
                | {
                    __typename?: "DateRestriction";
                    comp: CompOperator;
                    dateValue: string;
                  }
                | {
                    __typename?: "DurationRestriction";
                    unit: DateUnit;
                    durationValue: number;
                  }
                | {
                    __typename?: "GapRestriction";
                    unit: DateUnit;
                    gapValue: number;
                  }
                | {
                    __typename?: "LengthRestriction";
                    maxLength?: number | null;
                    minLength?: number | null;
                  }
                | {
                    __typename?: "NumericRestriction";
                    comp: CompOperator;
                    numericValue: number;
                  }
                | {
                    __typename?: "SelectRestriction";
                    multiSelect: boolean;
                    values: Array<{
                      __typename?: "SelectValue";
                      id: string;
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                    }>;
                  }
              >;
              description: { __typename?: "LocalizedString"; current: string };
              title: { __typename?: "LocalizedString"; current: string };
              opsFields: Array<
                | { __typename?: "OpsDvlaFieldValue" }
                | {
                    __typename?: "OpsEbulkFieldValue";
                    ebulkValue: OpsEbulkFieldType;
                  }
                | { __typename?: "OpsEquifaxFieldValue" }
                | { __typename?: "OpsKonfirFieldValue" }
                | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
                | { __typename?: "OpsTrustIdFieldValue" }
              >;
            }>;
            conditions: Array<{
              __typename?: "CandidateFieldCondition";
              id?: string | null;
              fieldValue: {
                __typename?: "CandidateFieldValue";
                field: {
                  __typename?: "CandidateField";
                  id: string;
                  type: FieldType;
                  fieldType: HandleableFieldType;
                  required: boolean;
                  conditional: boolean;
                  restrictions: Array<
                    | {
                        __typename?: "DateRestriction";
                        comp: CompOperator;
                        dateValue: string;
                      }
                    | {
                        __typename?: "DurationRestriction";
                        unit: DateUnit;
                        durationValue: number;
                      }
                    | {
                        __typename?: "GapRestriction";
                        unit: DateUnit;
                        gapValue: number;
                      }
                    | {
                        __typename?: "LengthRestriction";
                        maxLength?: number | null;
                        minLength?: number | null;
                      }
                    | {
                        __typename?: "NumericRestriction";
                        comp: CompOperator;
                        numericValue: number;
                      }
                    | {
                        __typename?: "SelectRestriction";
                        multiSelect: boolean;
                        values: Array<{
                          __typename?: "SelectValue";
                          id: string;
                          title: {
                            __typename?: "LocalizedString";
                            current: string;
                          };
                        }>;
                      }
                  >;
                  description: {
                    __typename?: "LocalizedString";
                    current: string;
                  };
                  title: { __typename?: "LocalizedString"; current: string };
                  opsFields: Array<
                    | { __typename?: "OpsDvlaFieldValue" }
                    | {
                        __typename?: "OpsEbulkFieldValue";
                        ebulkValue: OpsEbulkFieldType;
                      }
                    | { __typename?: "OpsEquifaxFieldValue" }
                    | { __typename?: "OpsKonfirFieldValue" }
                    | {
                        __typename?: "OpsOcrFieldValue";
                        ocrValue: OpsOcrFieldType;
                      }
                    | { __typename?: "OpsTrustIdFieldValue" }
                  >;
                };
                value?:
                  | {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    }
                  | {
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }
                  | {
                      __typename?: "Attachments";
                      attachmentsValue: Array<{
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }>;
                    }
                  | { __typename?: "BooleanValue"; booleanValue: boolean }
                  | {
                      __typename?: "Company";
                      name: string;
                      address: {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      };
                    }
                  | {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    }
                  | {
                      __typename?: "DateRange";
                      from?: string | null;
                      to?: string | null;
                    }
                  | { __typename?: "DateValue"; dateValue: string }
                  | { __typename?: "FloatValue"; floatValue: number }
                  | { __typename?: "LongValue" }
                  | {
                      __typename?: "QualificationsAndGrades";
                      qualificationsAndGrades: Array<{
                        __typename?: "QualificationAndGrades";
                        qualification: string;
                        grades: Array<{
                          __typename?: "GradeTable";
                          grade?: string | null;
                          subject?: string | null;
                        }>;
                      }>;
                    }
                  | { __typename?: "StringValue"; stringValue: string }
                  | { __typename?: "StringValues"; stringValues: Array<string> }
                  | null;
              };
            }>;
          }>;
        };
      }>;
    }>;
    contract: {
      __typename?: "CandidateContract";
      id: string;
      support: {
        __typename?: "CandidateContractSupport";
        supportEmailAddress: string;
        supportPhoneNumber: string;
      };
    };
    products: Array<{
      __typename?: "CandidateCaseProduct";
      id: string;
      status: CandidateCaseProductStatus;
      name: string;
      product: {
        __typename?: "CandidateProduct";
        id: string;
        conditionalQuestions: Array<{
          __typename?: "CandidateProductConditionalQuestion";
          id?: string | null;
          conditions: Array<{
            __typename?: "CandidateFieldCondition";
            id?: string | null;
            fieldValue: {
              __typename?: "CandidateFieldValue";
              field: {
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              };
              value?:
                | {
                    __typename?: "Address";
                    addressLine1: string;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    county?: string | null;
                    postcode: string;
                    town: string;
                    country: {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    };
                  }
                | {
                    __typename?: "Attachment";
                    downloadUrl: string;
                    fileName: string;
                    id: string;
                    uploadDate: number;
                    uploadedBy: string;
                  }
                | {
                    __typename?: "Attachments";
                    attachmentsValue: Array<{
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }>;
                  }
                | { __typename?: "BooleanValue"; booleanValue: boolean }
                | {
                    __typename?: "Company";
                    name: string;
                    address: {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    };
                  }
                | {
                    __typename?: "Country";
                    id: string;
                    name: string;
                    isoCode: string;
                  }
                | {
                    __typename?: "DateRange";
                    from?: string | null;
                    to?: string | null;
                  }
                | { __typename?: "DateValue"; dateValue: string }
                | { __typename?: "FloatValue"; floatValue: number }
                | { __typename?: "LongValue" }
                | {
                    __typename?: "QualificationsAndGrades";
                    qualificationsAndGrades: Array<{
                      __typename?: "QualificationAndGrades";
                      qualification: string;
                      grades: Array<{
                        __typename?: "GradeTable";
                        grade?: string | null;
                        subject?: string | null;
                      }>;
                    }>;
                  }
                | { __typename?: "StringValue"; stringValue: string }
                | { __typename?: "StringValues"; stringValues: Array<string> }
                | null;
            };
          }>;
          question: {
            __typename?: "CandidateQuestion";
            id: string;
            conditional: boolean;
            multipleAnswers: boolean;
            questionType: QuestionType;
            title: string;
            fields: Array<{
              __typename?: "CandidateField";
              id: string;
              type: FieldType;
              fieldType: HandleableFieldType;
              required: boolean;
              conditional: boolean;
              restrictions: Array<
                | {
                    __typename?: "DateRestriction";
                    comp: CompOperator;
                    dateValue: string;
                  }
                | {
                    __typename?: "DurationRestriction";
                    unit: DateUnit;
                    durationValue: number;
                  }
                | {
                    __typename?: "GapRestriction";
                    unit: DateUnit;
                    gapValue: number;
                  }
                | {
                    __typename?: "LengthRestriction";
                    maxLength?: number | null;
                    minLength?: number | null;
                  }
                | {
                    __typename?: "NumericRestriction";
                    comp: CompOperator;
                    numericValue: number;
                  }
                | {
                    __typename?: "SelectRestriction";
                    multiSelect: boolean;
                    values: Array<{
                      __typename?: "SelectValue";
                      id: string;
                      title: {
                        __typename?: "LocalizedString";
                        current: string;
                      };
                    }>;
                  }
              >;
              description: { __typename?: "LocalizedString"; current: string };
              title: { __typename?: "LocalizedString"; current: string };
              opsFields: Array<
                | { __typename?: "OpsDvlaFieldValue" }
                | {
                    __typename?: "OpsEbulkFieldValue";
                    ebulkValue: OpsEbulkFieldType;
                  }
                | { __typename?: "OpsEquifaxFieldValue" }
                | { __typename?: "OpsKonfirFieldValue" }
                | { __typename?: "OpsOcrFieldValue"; ocrValue: OpsOcrFieldType }
                | { __typename?: "OpsTrustIdFieldValue" }
              >;
            }>;
            conditionalElements: Array<{
              __typename?: "CandidateQuestionConditionalFields";
              id?: string | null;
              fields: Array<{
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              }>;
              conditions: Array<{
                __typename?: "CandidateFieldCondition";
                id?: string | null;
                fieldValue: {
                  __typename?: "CandidateFieldValue";
                  field: {
                    __typename?: "CandidateField";
                    id: string;
                    type: FieldType;
                    fieldType: HandleableFieldType;
                    required: boolean;
                    conditional: boolean;
                    restrictions: Array<
                      | {
                          __typename?: "DateRestriction";
                          comp: CompOperator;
                          dateValue: string;
                        }
                      | {
                          __typename?: "DurationRestriction";
                          unit: DateUnit;
                          durationValue: number;
                        }
                      | {
                          __typename?: "GapRestriction";
                          unit: DateUnit;
                          gapValue: number;
                        }
                      | {
                          __typename?: "LengthRestriction";
                          maxLength?: number | null;
                          minLength?: number | null;
                        }
                      | {
                          __typename?: "NumericRestriction";
                          comp: CompOperator;
                          numericValue: number;
                        }
                      | {
                          __typename?: "SelectRestriction";
                          multiSelect: boolean;
                          values: Array<{
                            __typename?: "SelectValue";
                            id: string;
                            title: {
                              __typename?: "LocalizedString";
                              current: string;
                            };
                          }>;
                        }
                    >;
                    description: {
                      __typename?: "LocalizedString";
                      current: string;
                    };
                    title: { __typename?: "LocalizedString"; current: string };
                    opsFields: Array<
                      | { __typename?: "OpsDvlaFieldValue" }
                      | {
                          __typename?: "OpsEbulkFieldValue";
                          ebulkValue: OpsEbulkFieldType;
                        }
                      | { __typename?: "OpsEquifaxFieldValue" }
                      | { __typename?: "OpsKonfirFieldValue" }
                      | {
                          __typename?: "OpsOcrFieldValue";
                          ocrValue: OpsOcrFieldType;
                        }
                      | { __typename?: "OpsTrustIdFieldValue" }
                    >;
                  };
                  value?:
                    | {
                        __typename?: "Address";
                        addressLine1: string;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        county?: string | null;
                        postcode: string;
                        town: string;
                        country: {
                          __typename?: "Country";
                          id: string;
                          name: string;
                          isoCode: string;
                        };
                      }
                    | {
                        __typename?: "Attachment";
                        downloadUrl: string;
                        fileName: string;
                        id: string;
                        uploadDate: number;
                        uploadedBy: string;
                      }
                    | {
                        __typename?: "Attachments";
                        attachmentsValue: Array<{
                          __typename?: "Attachment";
                          downloadUrl: string;
                          fileName: string;
                          id: string;
                          uploadDate: number;
                          uploadedBy: string;
                        }>;
                      }
                    | { __typename?: "BooleanValue"; booleanValue: boolean }
                    | {
                        __typename?: "Company";
                        name: string;
                        address: {
                          __typename?: "Address";
                          addressLine1: string;
                          addressLine2?: string | null;
                          addressLine3?: string | null;
                          county?: string | null;
                          postcode: string;
                          town: string;
                          country: {
                            __typename?: "Country";
                            id: string;
                            name: string;
                            isoCode: string;
                          };
                        };
                      }
                    | {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      }
                    | {
                        __typename?: "DateRange";
                        from?: string | null;
                        to?: string | null;
                      }
                    | { __typename?: "DateValue"; dateValue: string }
                    | { __typename?: "FloatValue"; floatValue: number }
                    | { __typename?: "LongValue" }
                    | {
                        __typename?: "QualificationsAndGrades";
                        qualificationsAndGrades: Array<{
                          __typename?: "QualificationAndGrades";
                          qualification: string;
                          grades: Array<{
                            __typename?: "GradeTable";
                            grade?: string | null;
                            subject?: string | null;
                          }>;
                        }>;
                      }
                    | { __typename?: "StringValue"; stringValue: string }
                    | {
                        __typename?: "StringValues";
                        stringValues: Array<string>;
                      }
                    | null;
                };
              }>;
            }>;
          };
        }>;
        conditionalValidations: Array<{
          __typename?: "CandidateProductConditionalValidation";
          id?: string | null;
          validationMessage: string;
          answersCondition?: {
            __typename?: "CandidateAnswersCondition";
            maxNumberOfAnswers?: number | null;
            minNumberOfAnswers?: number | null;
          } | null;
          conditions: Array<{
            __typename?: "CandidateFieldCondition";
            id?: string | null;
            fieldValue: {
              __typename?: "CandidateFieldValue";
              field: {
                __typename?: "CandidateField";
                id: string;
                type: FieldType;
                fieldType: HandleableFieldType;
                required: boolean;
                conditional: boolean;
                restrictions: Array<
                  | {
                      __typename?: "DateRestriction";
                      comp: CompOperator;
                      dateValue: string;
                    }
                  | {
                      __typename?: "DurationRestriction";
                      unit: DateUnit;
                      durationValue: number;
                    }
                  | {
                      __typename?: "GapRestriction";
                      unit: DateUnit;
                      gapValue: number;
                    }
                  | {
                      __typename?: "LengthRestriction";
                      maxLength?: number | null;
                      minLength?: number | null;
                    }
                  | {
                      __typename?: "NumericRestriction";
                      comp: CompOperator;
                      numericValue: number;
                    }
                  | {
                      __typename?: "SelectRestriction";
                      multiSelect: boolean;
                      values: Array<{
                        __typename?: "SelectValue";
                        id: string;
                        title: {
                          __typename?: "LocalizedString";
                          current: string;
                        };
                      }>;
                    }
                >;
                description: {
                  __typename?: "LocalizedString";
                  current: string;
                };
                title: { __typename?: "LocalizedString"; current: string };
                opsFields: Array<
                  | { __typename?: "OpsDvlaFieldValue" }
                  | {
                      __typename?: "OpsEbulkFieldValue";
                      ebulkValue: OpsEbulkFieldType;
                    }
                  | { __typename?: "OpsEquifaxFieldValue" }
                  | { __typename?: "OpsKonfirFieldValue" }
                  | {
                      __typename?: "OpsOcrFieldValue";
                      ocrValue: OpsOcrFieldType;
                    }
                  | { __typename?: "OpsTrustIdFieldValue" }
                >;
              };
              value?:
                | {
                    __typename?: "Address";
                    addressLine1: string;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    county?: string | null;
                    postcode: string;
                    town: string;
                    country: {
                      __typename?: "Country";
                      id: string;
                      name: string;
                      isoCode: string;
                    };
                  }
                | {
                    __typename?: "Attachment";
                    downloadUrl: string;
                    fileName: string;
                    id: string;
                    uploadDate: number;
                    uploadedBy: string;
                  }
                | {
                    __typename?: "Attachments";
                    attachmentsValue: Array<{
                      __typename?: "Attachment";
                      downloadUrl: string;
                      fileName: string;
                      id: string;
                      uploadDate: number;
                      uploadedBy: string;
                    }>;
                  }
                | { __typename?: "BooleanValue"; booleanValue: boolean }
                | {
                    __typename?: "Company";
                    name: string;
                    address: {
                      __typename?: "Address";
                      addressLine1: string;
                      addressLine2?: string | null;
                      addressLine3?: string | null;
                      county?: string | null;
                      postcode: string;
                      town: string;
                      country: {
                        __typename?: "Country";
                        id: string;
                        name: string;
                        isoCode: string;
                      };
                    };
                  }
                | {
                    __typename?: "Country";
                    id: string;
                    name: string;
                    isoCode: string;
                  }
                | {
                    __typename?: "DateRange";
                    from?: string | null;
                    to?: string | null;
                  }
                | { __typename?: "DateValue"; dateValue: string }
                | { __typename?: "FloatValue"; floatValue: number }
                | { __typename?: "LongValue" }
                | {
                    __typename?: "QualificationsAndGrades";
                    qualificationsAndGrades: Array<{
                      __typename?: "QualificationAndGrades";
                      qualification: string;
                      grades: Array<{
                        __typename?: "GradeTable";
                        grade?: string | null;
                        subject?: string | null;
                      }>;
                    }>;
                  }
                | { __typename?: "StringValue"; stringValue: string }
                | { __typename?: "StringValues"; stringValues: Array<string> }
                | null;
            };
          }>;
        }>;
      };
    }>;
  };
};

export type GetActiveTroubleShootingRequestQueryVariables = Exact<{
  caseId: Scalars["ID"]["input"];
}>;

export type GetActiveTroubleShootingRequestQuery = {
  __typename?: "Query";
  activeCaseTroubleshootingRequest: {
    __typename?: "CandidateCaseTroubleshootingRequestConnection";
    troubleshootingRequest?: {
      __typename?: "CandidateCaseTroubleshootingRequest";
      id: string;
      status: TroubleshootingRequestStatus;
      createdWhen: number;
    } | null;
  };
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = {
  __typename?: "Query";
  countries: Array<{
    __typename?: "Country";
    id: string;
    name: string;
    isoCode: string;
  }>;
};

export type GetAuthFlowQueryVariables = Exact<{
  caseId: Scalars["ID"]["input"];
}>;

export type GetAuthFlowQuery = {
  __typename?: "Query";
  authFlow: {
    __typename?: "CandidatePortalAuthFlowPayload";
    authFlow: CandidatePortalAuthFlow;
  };
};

export type GetScreeningCasesQueryVariables = Exact<{ [key: string]: never }>;

export type GetScreeningCasesQuery = {
  __typename?: "Query";
  screeningCases: Array<{
    __typename?: "CandidateCase";
    id: string;
    whenCreated: number;
  }>;
};

export type KonfirVerificationSubmittedMutationMutationVariables = Exact<{
  caseId: Scalars["ID"]["input"];
}>;

export type KonfirVerificationSubmittedMutationMutation = {
  __typename?: "Mutation";
  konfirVerificationSubmitted: { __typename?: "CandidateCase"; id: string };
};

export type RespondToDataRequestMutationVariables = Exact<{
  files?: InputMaybe<
    Array<Scalars["Upload"]["input"]> | Scalars["Upload"]["input"]
  >;
  input: RespondToCandidateDataRequestInput;
}>;

export type RespondToDataRequestMutation = {
  __typename?: "Mutation";
  respondToDataRequest: {
    __typename?: "RespondToCandidateDataRequestPayload";
    product: { __typename?: "CandidateCaseProduct"; id: string };
  };
};

export type GetCaseInformationQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetCaseInformationQuery = {
  __typename?: "Query";
  screeningCase: {
    __typename?: "CandidateCase";
    id: string;
    whenCreated: number;
    whenCompleted?: number | null;
    status: CandidateCaseStatus;
    client: { __typename?: "CandidateClient"; name: string };
    candidate: {
      __typename?: "Candidate";
      firstName: string;
      lastName: string;
    };
    assignee?: { __typename?: "Person"; id: string; name: string } | null;
    products: Array<{
      __typename?: "CandidateCaseProduct";
      id: string;
      status: CandidateCaseProductStatus;
      name: string;
      candidateDataRequest?: {
        __typename?: "CandidateDataRequest";
        id: string;
        content: string;
        attachmentsAllowed: boolean;
        attachments: Array<{
          __typename?: "Attachment";
          downloadUrl: string;
          fileName: string;
          id: string;
          uploadDate: number;
          uploadedBy: string;
        }>;
        replyData?: {
          __typename?: "CandidateDataReplyContent";
          content: string;
          attachments: Array<{
            __typename?: "Attachment";
            downloadUrl: string;
            fileName: string;
            id: string;
          }>;
        } | null;
      } | null;
    }>;
  };
};
