import React, { useCallback, useMemo } from "react";
import { StatusCard } from "components/StatusCard";
import {
  CardStatuses,
  IProps as IStatusCardProps
} from "components/StatusCard/types";
import { UploadFieldCard } from "components/UploadFieldCard";
import {
  AlertCircleIcon,
  Button,
  BUTTON_TYPE,
  disabledButtonColors,
  EyeIcon,
  PlusSmallIcon,
  theme,
  TrashBinIcon
} from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";
import { IProps } from "./types";
import { IAnswer, IFieldAnswer } from "../../types";
import {
  attachmentFragment,
  CaseTroubleshoootingHistoryType
} from "../../graphQLTypes";
import { isUndefinedOrNull } from "../../utils/typeGuards";
import { isGeneralUploadField } from "src/utils";

const DocumentQuestionCardComponent: React.FC<IProps> = ({
  question,
  updatedFieldIds,
  onUploadFile,
  onDeleteFile,
  onShowAnswer,
  onAddEntry,
  onDeleteAnswer,
  onDownloadFile,
  isDisabled,
  isDeleteFileDisabled
}: IProps) => {
  const handleAddEntry = useCallback(() => {
    onAddEntry(question.fields, question.questionId);
  }, [question.fields, question.questionId, onAddEntry]);

  const answers = useMemo(
    () =>
      question.answers.map((answer: IAnswer) => {
        const fields: IFieldAnswer[] = answer.fields.filter(
          (field: IFieldAnswer) =>
            isGeneralUploadField(field.type) && field.isVisible
        );

        return (
          <s.AnswerContainer key={answer.answerId}>
            {answer.isTemplateAnswer && (
              <s.WarningContainer>
                <AlertCircleIcon size={22} />
                <s.WarningText>
                  Until you upload the file, your answer will not be saved
                </s.WarningText>
              </s.WarningContainer>
            )}
            {!question.isUploadQuestion && (
              <s.AnswerToolsPanel>
                <Button
                  icon={
                    <EyeIcon
                      size={16}
                      color={theme.colors["color-action-primary-default"]}
                    />
                  }
                  buttonType={BUTTON_TYPE.Link}
                  onClick={onShowAnswer(answer.fields)}
                >
                  Show answer
                </Button>
              </s.AnswerToolsPanel>
            )}
            {question.isUploadQuestion &&
              question.isMultipleAnswers &&
              Boolean(question.answers.length - 1) && (
                <s.AnswerToolsPanel>
                  <Button
                    isLoading={updatedFieldIds.some(
                      (id: string) => answer.answerId === id
                    )}
                    isDisabled={isDeleteFileDisabled}
                    buttonType={BUTTON_TYPE.Link}
                    icon={
                      <TrashBinIcon
                        size={16}
                        color={
                          isDeleteFileDisabled
                            ? theme.colors["color-icon-disabled"]
                            : theme.colors["color-action-critical-default"]
                        }
                      />
                    }
                    onClick={onDeleteAnswer({
                      questionId: question.questionId,
                      answerId: answer.answerId,
                      isAnswered: answer.isAnswered,
                      history: fields.map((field: IFieldAnswer) => ({
                        type: CaseTroubleshoootingHistoryType.FILE_UPLOAD,
                        answerId: answer.answerId,
                        fieldId: field.fieldId,
                        isMulti: field.isMulti,
                        to: undefined,
                        from:
                          (field.value as attachmentFragment | undefined)
                            ?.fileName || undefined,
                        fieldType: field.type,
                        index: field.answerIndex,
                        isDeleted: true
                      }))
                    })}
                  >
                    <s.DeleteButtonText>Delete answer</s.DeleteButtonText>
                  </Button>
                </s.AnswerToolsPanel>
              )}
            {fields.map((field: IFieldAnswer) => (
              <UploadFieldCard
                isDisabled={isDisabled}
                isDeleteFileDisabled={isDeleteFileDisabled}
                key={field.fieldId}
                field={field}
                questionId={question.questionId}
                answerId={answer.answerId}
                isAnswered={answer.isAnswered}
                updatedFieldIds={updatedFieldIds}
                onUploadFile={onUploadFile}
                onDeleteFile={onDeleteFile}
                onDownloadFile={onDownloadFile}
              />
            ))}
          </s.AnswerContainer>
        );
      }),
    [
      question.answers,
      question.isUploadQuestion,
      question.isMultipleAnswers,
      question.questionId,
      onShowAnswer,
      updatedFieldIds,
      isDeleteFileDisabled,
      onDeleteAnswer,
      isDisabled,
      onUploadFile,
      onDeleteFile,
      onDownloadFile
    ]
  );

  const getAddEntryIsDisabled = useCallback(
    (): boolean =>
      question.answers.some((answer: IAnswer) =>
        answer.fields.some(
          (field: IFieldAnswer) =>
            isGeneralUploadField(field.type) &&
            field.isVisible &&
            field.validation.isRequired &&
            !field.value
        )
      ),
    [question.answers]
  );

  const renderFooter = useCallback(() => {
    const isAddEntryDisabled: boolean = getAddEntryIsDisabled() || !!isDisabled;
    return (
      <s.QuestionFooter>
        <Button
          buttonType={BUTTON_TYPE.Ghost}
          isDisabled={isAddEntryDisabled}
          onClick={handleAddEntry}
          icon={
            <PlusSmallIcon
              size={16}
              color={
                isAddEntryDisabled
                  ? disabledButtonColors[BUTTON_TYPE.Ghost]
                  : theme.colors["color-action-primary-default"]
              }
            />
          }
        >
          Add Entry
        </Button>
      </s.QuestionFooter>
    );
  }, [getAddEntryIsDisabled, handleAddEntry, isDisabled]);

  const statusCardProps: IStatusCardProps =
    useMemo<IStatusCardProps>((): IStatusCardProps => {
      const hasOptional: boolean = question.answers.every((answer: IAnswer) =>
        answer.fields.every(
          (field: IFieldAnswer) =>
            !field.validation.isRequired &&
            field.isVisible &&
            isUndefinedOrNull(field.value)
        )
      );

      if (hasOptional) {
        return {
          status: CardStatuses.HAS_OPTIONAL,
          title: "missing"
        };
      }

      return question.completed
        ? {
            status: CardStatuses.COMPLETE,
            title: "attached"
          }
        : {
            status: CardStatuses.INCOMPLETE,
            title: "missing"
          };
    }, [question.answers, question.completed]);

  return (
    <s.Container
      aria-label={`${question.title}, status ${statusCardProps.title}`}
    >
      <s.QuestionHeader>
        <s.TitleContainer>
          <s.Title>{question.title}</s.Title>
          <StatusCard
            status={statusCardProps.status}
            title={statusCardProps.title}
          />
        </s.TitleContainer>
        {question.description && (
          <s.Description>{question.description}</s.Description>
        )}
      </s.QuestionHeader>
      <s.ContentContainer>{answers}</s.ContentContainer>
      {question.isUploadQuestion &&
        question.isMultipleAnswers &&
        renderFooter()}
    </s.Container>
  );
};

export const DocumentQuestionCard = React.memo<IProps>(
  DocumentQuestionCardComponent
);
