import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";

export const StatusesContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const DraftValueStatusContainer = styled.div`
  margin-left: 15px;
  display: flex;
  ${getTypographyStyles({
    variant: "badge",
    color: "color-text-default"
  })}

  & span {
    margin-left: 7px;
  }
`;
