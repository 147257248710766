import styled, { css } from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import { IStylesProps } from "./types";

const setMarginForText = ({ marginBottom, marginTop }: IStylesProps) => css`
  margin-top: ${marginTop || 0}px;
  margin-bottom: ${marginBottom || 0}px;
`;

export const ContentBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 25px;
  background-color: ${({ theme }) => theme.colors["color-surface-default"]};
  padding: 16px 24px 24px;

  ul {
    padding-left: 32px;
  }
`;

export const PrimaryText = styled.h2`
  margin: 8px 0;
  ${getTypographyStyles({ variant: "h2" })}
`;

export const SecondaryText = styled.div`
  margin-bottom: 8px;
  ${getTypographyStyles({ variant: "body-highlight" })}
`;

export const Text = styled.div<IStylesProps>`
  ${setMarginForText};
  ${getTypographyStyles({ variant: "body-wide-line" })};
`;

export const StyledList = styled.ul<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => `${gap}px`};
`;
