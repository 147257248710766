import React, { FC, useEffect, useRef } from "react";
import * as s from "containers/ScreenerAccess/styles";
import {
  BUTTON_TYPE,
  CheckOutsideCircleIcon,
  CloseCircleIcon,
  SMALL_ICON_SIZE
} from "@security-watchdog/sw-ui-kit";
import { useTheme } from "styled-components";
import { TroubleshootingRequestStatus } from "src/graphQLTypes";

export interface IActionsProps {
  status: TroubleshootingRequestStatus | null;
  isAcceptingRequestInProgress: boolean;
  isCancelRequestInProgress: boolean;
  handleAcceptRequest: () => void;
  handleCancelRequest: () => void;
}

export const Actions: FC<IActionsProps> = ({
  status,
  handleAcceptRequest,
  handleCancelRequest,
  isCancelRequestInProgress,
  isAcceptingRequestInProgress
}: IActionsProps) => {
  const allowBtnRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();

  useEffect(() => {
    allowBtnRef.current?.focus();
  }, []);

  if (status === TroubleshootingRequestStatus.SCREENER_REQUESTED) {
    return (
      <>
        <s.ActionButton
          buttonType={BUTTON_TYPE.Link}
          isLoading={isAcceptingRequestInProgress}
          buttonRef={allowBtnRef}
          icon={
            <CheckOutsideCircleIcon
              size={SMALL_ICON_SIZE}
              color={theme.colors["color-icon-on-primary"]}
            />
          }
          onClick={handleAcceptRequest}
        >
          Allow
        </s.ActionButton>
        <s.ActionButton
          buttonType={BUTTON_TYPE.Link}
          onClick={handleCancelRequest}
          isLoading={isCancelRequestInProgress}
          icon={
            <CloseCircleIcon
              size={SMALL_ICON_SIZE}
              color={theme.colors["color-icon-on-primary"]}
            />
          }
        >
          Cancel
        </s.ActionButton>
      </>
    );
  }

  return null;
};
