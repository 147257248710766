import styled from "styled-components";
import {
  Button as ButtonComponent,
  IButtonProps
} from "@security-watchdog/sw-ui-kit";

import { DEVICE } from "src/constants";

export const Button = styled(ButtonComponent)<IButtonProps>`
  &:first-child {
    margin-right: 20px;
  }

  @media (max-width: ${DEVICE.TABLET}) {
    width: 100%;
    justify-content: center;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 319px;

  @media (max-width: ${DEVICE.TABLET}) {
    height: 100%;
    min-height: 100px;
  }
`;

export const FooterContainer = styled.div<{ $flexDirection: string }>`
  display: flex;
  flex-direction: ${({ $flexDirection }): string => $flexDirection || "column"};
  justify-content: space-between;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
  }
`;

export const CheckboxWrapper = styled.div`
  margin-bottom: 24px;
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MainActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 0 auto;
`;

export const ClearActionWrapper = styled.div`
  margin-right: 24px;
`;
