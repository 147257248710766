import styled from "styled-components";

export const NoteContainer = styled.div`
  display: flex;
  overflow-y: auto;
  margin: 4px 0 8px;
  max-height: 134px;
  word-break: break-word;
  white-space: break-spaces;
`;

export const Container = styled.div`
  margin: 8px 0 24px;
`;

export const ScreenerInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors["color-divider-default"]}`};
`;

export const ScreenerAttachments = styled.div`
  margin-top: 16px;
`;
