import React, {
  memo,
  NamedExoticComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { getTextWithParsedLinks, Link } from "@security-watchdog/ui-components";
import { ButtonBack } from "components/ButtonBack";
import {
  CONSENT_FOR_RECEIPT,
  PRIVACY_POLICY_LINKS,
  ROUTES
} from "src/constants";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "components/PageHeader";
import * as s from "containers/TermsAndConditions/styles";
import { StatusCard } from "components/StatusCard";
import {
  CardStatuses,
  IProps as IStatusCardProps
} from "components/StatusCard/types";
import {
  Button,
  BUTTON_TYPE,
  Checkbox,
  PlusSmallIcon,
  SMALL_ICON_SIZE,
  TrashBinIcon
} from "@security-watchdog/sw-ui-kit";
import { attachmentFragment, ConsentType } from "src/graphQLTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  takeCaseId,
  takeConsentGiven,
  takeConsentText,
  takeConsentType,
  takeESignatureAttachment,
  takeHasEnhancedCriminalityCheck,
  takeHasRoleLocationScotlandField,
  takeIsCaseUpdating,
  takeRoleLocationScotland
} from "modules/case/selectors";
import { DefaultTheme, useTheme } from "styled-components";
import * as actions from "modules/case/actions";
import { Dispatch } from "redux";
import { usePrevious } from "src/hooks/usePrevious";
import { ESignatureModal } from "components/eSignatureModal";
import { ContentLayout } from "components/ContentLayout";
import { Header as CommonHeader } from "components/Header";
import { useScrollToTop } from "src/hooks/useScrollToTop";
import { ScreenerAccess } from "containers/ScreenerAccess";
import { takeIsAllFormsDisabled } from "modules/screenerAccess/selectors";
import { takeIsAdminFlow } from "modules/main/selectors";
import { MetaData } from "components/MetaData";

const TermsAndConditionsComponent: React.FC = () => {
  const navigateTo = useNavigate();

  const dispatch: Dispatch = useDispatch();

  useScrollToTop();

  const theme: DefaultTheme = useTheme();

  const consentType: ConsentType = useSelector(takeConsentType);

  const consentGiven: boolean = useSelector(takeConsentGiven);

  const caseId: string = useSelector(takeCaseId);

  const consentText: string = useSelector(takeConsentText);

  const isCaseUpdating: boolean = useSelector(takeIsCaseUpdating);

  const eSignatureAttachment: attachmentFragment | null = useSelector(
    takeESignatureAttachment
  );

  const isAllFormsDisabled: boolean = useSelector(takeIsAllFormsDisabled);

  const isAdminFlow: boolean = useSelector(takeIsAdminFlow);

  const prevIsCaseUpdating: boolean | undefined = usePrevious(isCaseUpdating);

  const isScotlandLocation: boolean = useSelector(takeRoleLocationScotland);

  const hasRoleLocationScotlandField = useSelector(
    takeHasRoleLocationScotlandField
  );

  const hasEnhancedCriminalityCheck: boolean | undefined = useSelector(
    takeHasEnhancedCriminalityCheck
  );

  const [eSignatureFile, setESignatureFile] = useState<File | undefined>();

  const [isDeclaration, setIsDeclaration] = useState<boolean>(consentGiven);

  const [isConsentForDBS, setIsConsentForDBS] = useState<boolean>(consentGiven);

  const [isConsentMyData, setIsConsentMyData] = useState<boolean>(consentGiven);

  const [isConsent, setIsConsent] = useState<boolean>(consentGiven);

  const [isESignModalActive, setIsESignModalActive] = useState<boolean>(false);

  const [isESignatureAttachmentDeleted, setIsESignatureAttachmentDeleted] =
    useState<boolean>(false);

  useEffect(() => {
    if (prevIsCaseUpdating && !isCaseUpdating) {
      navigateTo({
        pathname: ROUTES.ROOT,
        search: window.location.search
      });
    }
  }, [isCaseUpdating, navigateTo, prevIsCaseUpdating]);

  const toggleConsent = useCallback(
    () => setIsConsent((prevConsent: boolean) => !prevConsent),
    []
  );

  const isFieldsDisabled = isAllFormsDisabled || isAdminFlow;

  const toggleDeclaration = useCallback(
    () => setIsDeclaration((prevIsDeclaration: boolean) => !prevIsDeclaration),
    []
  );

  const toggleConsentForDBS = useCallback(
    () =>
      setIsConsentForDBS(
        (prevIsConsentForDBS: boolean) => !prevIsConsentForDBS
      ),
    []
  );

  const toggleConsentMyData = useCallback(
    () =>
      setIsConsentMyData(
        (prevIsConsentMyData: boolean) => !prevIsConsentMyData
      ),
    []
  );

  const isTermsAndConditionsDisabled = useMemo(
    () =>
      !isDeclaration ||
      ((hasRoleLocationScotlandField || hasEnhancedCriminalityCheck) &&
        !isConsentForDBS) ||
      ((hasRoleLocationScotlandField || hasEnhancedCriminalityCheck) &&
        !isConsentMyData) ||
      (consentType === ConsentType.DIGITAL && !isConsent),
    [
      consentType,
      hasEnhancedCriminalityCheck,
      hasRoleLocationScotlandField,
      isConsent,
      isConsentForDBS,
      isConsentMyData,
      isDeclaration
    ]
  );

  const goToBack = useCallback(
    () => navigateTo({ pathname: ROUTES.ROOT, search: window.location.search }),
    [navigateTo]
  );

  const termsAndConditionsSectionStatus: IStatusCardProps =
    useMemo<IStatusCardProps>(
      (): IStatusCardProps => ({
        status: !isTermsAndConditionsDisabled
          ? CardStatuses.COMPLETE
          : CardStatuses.INCOMPLETE,
        title: !isTermsAndConditionsDisabled
          ? CardStatuses.COMPLETE
          : CardStatuses.INCOMPLETE
      }),
      [isTermsAndConditionsDisabled]
    );

  const handleSubmit = useCallback(
    () =>
      dispatch(
        actions.caseUpdate.started({
          caseInput: {
            files: eSignatureFile ? [eSignatureFile] : undefined,
            input: {
              eSignatureUploadIndex: eSignatureFile ? 0 : undefined,
              consentGiven: !isTermsAndConditionsDisabled,
              answers: [],
              caseId
            }
          }
        })
      ),
    [caseId, dispatch, eSignatureFile, isTermsAndConditionsDisabled]
  );

  const handleOpenESignModal = useCallback(
    () => setIsESignModalActive(true),
    []
  );

  const handleCloseESignModal = useCallback(
    () => setIsESignModalActive(false),
    []
  );

  const handleSetESignatureFile = useCallback(
    (eSignatureFile: File) => setESignatureFile(eSignatureFile),
    []
  );

  const hasESignature: boolean = useMemo<boolean>(
    (): boolean =>
      Boolean(eSignatureAttachment && !isESignatureAttachmentDeleted) ||
      Boolean(eSignatureFile),
    [eSignatureAttachment, eSignatureFile, isESignatureAttachmentDeleted]
  );

  const isSubmitDisabled = useMemo(
    () =>
      isTermsAndConditionsDisabled ||
      (consentType === ConsentType.E_SIGNATURE &&
        !eSignatureFile &&
        !hasESignature),
    [isTermsAndConditionsDisabled, consentType, eSignatureFile, hasESignature]
  );

  const eSignatureStatus =
    hasESignature || eSignatureFile
      ? CardStatuses.COMPLETE
      : CardStatuses.INCOMPLETE;

  const handleDeleteESignature = useCallback(() => {
    if (eSignatureAttachment) {
      setIsESignatureAttachmentDeleted(true);
    }

    if (eSignatureFile) {
      setESignatureFile(undefined);
    }
  }, [eSignatureAttachment, eSignatureFile]);

  return (
    <div>
      <MetaData title="Terms and Conditions" />
      <CommonHeader />

      <ScreenerAccess />

      <ContentLayout>
        <ButtonBack handlerClick={goToBack}>Back</ButtonBack>
        <PageHeader
          title="Terms & Conditions"
          description="View the Terms & Conditions and give SW authority to carry out your checks"
        />
        <s.Container
          aria-label={`Terms & Conditions, status ${termsAndConditionsSectionStatus.status}`}
        >
          <s.QuestionHeader>
            <s.TitleContainer>
              <s.Title>Terms & Conditions</s.Title>
              <StatusCard
                status={termsAndConditionsSectionStatus.status}
                title={termsAndConditionsSectionStatus.title}
              />
            </s.TitleContainer>
            <s.Description>
              Keep in mind that you will not be able to change any information
              after you have submitted, but we may reach out to you for
              additional documents if needed
            </s.Description>
          </s.QuestionHeader>
          <s.ContentContainer>
            <s.ContainerInfo>
              <Checkbox
                isDisabled={isFieldsDisabled}
                value={isDeclaration}
                label="Declaration of Authenticity"
                onChange={toggleDeclaration}
              />
              <s.SecondaryText>
                I declare that the information in this application is complete
                and correct. I understand that knowingly making a false
                statement for this purpose is a criminal offence
              </s.SecondaryText>
            </s.ContainerInfo>

            {(hasRoleLocationScotlandField || hasEnhancedCriminalityCheck) && (
              <>
                <s.ContainerInfo>
                  <Checkbox
                    isDisabled={isFieldsDisabled}
                    value={isConsentForDBS}
                    label={
                      isScotlandLocation
                        ? CONSENT_FOR_RECEIPT.FOR_SCOTLAND.title
                        : CONSENT_FOR_RECEIPT.FOR_NOT_SCOTLAND.title
                    }
                    onChange={toggleConsentForDBS}
                  />
                  <s.SecondaryText>
                    {isScotlandLocation
                      ? CONSENT_FOR_RECEIPT.FOR_SCOTLAND.text
                      : CONSENT_FOR_RECEIPT.FOR_NOT_SCOTLAND.text}
                  </s.SecondaryText>
                </s.ContainerInfo>
                <s.ContainerInfo>
                  <Checkbox
                    isDisabled={isFieldsDisabled}
                    value={isConsentMyData}
                    label="Consent to the use of my data"
                    onChange={toggleConsentMyData}
                  />
                  <s.SecondaryText>
                    I have read the{" "}
                    <Link
                      target="_blank"
                      to={
                        isScotlandLocation
                          ? PRIVACY_POLICY_LINKS.FOR_SCOTLAND
                          : PRIVACY_POLICY_LINKS.FOR_NOT_SCOTLAND
                      }
                    >
                      Privacy Policy
                    </Link>{" "}
                    and understand how the Criminal Record Agency responsible
                    for the submission of my application will process my
                    personal data.
                  </s.SecondaryText>
                </s.ContainerInfo>
              </>
            )}
            {consentType === ConsentType.DIGITAL && (
              <s.ContainerInfo>
                <Checkbox
                  isDisabled={isFieldsDisabled}
                  value={isConsent}
                  label="Declaration of Authority"
                  onChange={toggleConsent}
                />
                <s.DeclarationText
                  dangerouslySetInnerHTML={{
                    __html: getTextWithParsedLinks(consentText)
                  }}
                />
              </s.ContainerInfo>
            )}
          </s.ContentContainer>
        </s.Container>
        {consentType === ConsentType.E_SIGNATURE && (
          <s.Container aria-label={`E-Signature, status ${eSignatureStatus}`}>
            <ESignatureModal
              onSave={handleSetESignatureFile}
              onClose={handleCloseESignModal}
              isShowModal={isESignModalActive}
              title="eSignature"
              description="Please draw your signature in the box below"
            />
            <s.QuestionHeader>
              <s.TitleContainer>
                <s.Title>E-Signature</s.Title>
                <StatusCard
                  title={eSignatureStatus}
                  status={eSignatureStatus}
                />
              </s.TitleContainer>
              <s.Description
                dangerouslySetInnerHTML={{
                  __html: getTextWithParsedLinks(consentText)
                }}
              />
            </s.QuestionHeader>
            {hasESignature && (
              <s.ESignatureRootContainer>
                <s.ESignatureContainer>
                  <s.ESignatureText role="alert">
                    <s.CheckOutsideCircleIcon
                      size={SMALL_ICON_SIZE}
                      color={theme.colors["color-icon-success"]}
                    />
                    Signature has been added
                  </s.ESignatureText>
                  <s.ESignatureDeleteButton
                    onClick={handleDeleteESignature}
                    isDisabled={isFieldsDisabled}
                    icon={
                      <TrashBinIcon
                        size={SMALL_ICON_SIZE}
                        color={theme.colors["color-action-critical-default"]}
                      />
                    }
                    buttonType={BUTTON_TYPE.GhostCritical}
                  >
                    Delete
                  </s.ESignatureDeleteButton>
                </s.ESignatureContainer>
              </s.ESignatureRootContainer>
            )}
            {!hasESignature && (
              <s.ContentContainer>
                <Button
                  buttonType={BUTTON_TYPE.Ghost}
                  onClick={handleOpenESignModal}
                  isDisabled={isFieldsDisabled}
                  icon={
                    <PlusSmallIcon
                      size={SMALL_ICON_SIZE}
                      color={
                        isFieldsDisabled
                          ? theme.colors["color-icon-disabled"]
                          : theme.colors["color-action-primary-default"]
                      }
                    />
                  }
                >
                  Add Signature
                </Button>
              </s.ContentContainer>
            )}
          </s.Container>
        )}
        <Button
          buttonType={BUTTON_TYPE.Primary}
          isDisabled={isSubmitDisabled || isFieldsDisabled}
          onClick={handleSubmit}
          isLoading={isCaseUpdating}
        >
          Submit
        </Button>
      </ContentLayout>
    </div>
  );
};

export const TermsAndConditions: NamedExoticComponent = memo(
  TermsAndConditionsComponent
);

export default TermsAndConditions;
