import styled from "styled-components";
import { Button, IButtonProps } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const Container = styled.div``;

export const CustomButton = styled(Button)<IButtonProps>`
  &:first-child {
    margin-right: 24px;
  }
  @media (max-width: ${DEVICE.TABLET}) {
    padding: 4px 0;
  }
`;

export const CustomCompleteButton = styled(CustomButton)<IButtonProps>`
  margin-top: 16px;
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors["color-surface-default"]};
  margin-top: 24px;
`;

export const QuestionHeaderContainer = styled.div`
  align-items: flex-start;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors["color-divider-default"]}`};
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

export const QuestionContentConteiner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;
