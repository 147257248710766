import React, { FC } from "react";
import { Alert } from "@security-watchdog/ui-components";
import * as s from "./styles";

export const CaseSubmittedAlert: FC = () => (
  <Alert variant="success" className="margin-top-4">
    <p className="body-wide-line" role="alert">
      Your form has been successfully submitted. Here’s what happens next:
      <s.List>
        <li>We will review your details and attached documents</li>
        <li>
          If needed, our team will be in touch should we require any further
          information from you
        </li>
      </s.List>
    </p>
  </Alert>
);
