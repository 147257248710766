import { ApolloProvider } from "@apollo/client";
import Router from "containers/Router";
import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { theme } from "@security-watchdog/sw-ui-kit";
import { NotificationsProvider } from "@security-watchdog/ui-components";
import { store } from "src/store";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { DisclaimerCookie } from "../DisclaimerCookie";
import { GlobalStyle } from "./styles";
import apolloClient from "src/apolloClient";
import "@security-watchdog/ui-components/dist/css/index.css";
import { MicrosoftClarity } from "src/lib/microsoftClarity";

const App: React.FC = () => (
  <Provider store={store}>
    <MicrosoftClarity>
      <ApolloProvider client={apolloClient}>
        <NotificationsProvider>
          <ThemeProvider theme={theme}>
            <HelmetProvider>
              <BrowserRouter>
                <GlobalStyle />
                <Router />
              </BrowserRouter>
              <DisclaimerCookie />
            </HelmetProvider>
          </ThemeProvider>
        </NotificationsProvider>
      </ApolloProvider>
    </MicrosoftClarity>
  </Provider>
);

export default App;
