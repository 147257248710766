import React, {
  Dispatch,
  FC,
  memo,
  NamedExoticComponent,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { getTextWithParsedLinks } from "@security-watchdog/ui-components";
import { IFieldAnswer } from "src/types";
import {
  Button,
  BUTTON_TYPE,
  Dropdown,
  DropDownOnChange,
  EditSmallIcon,
  Input,
  OptionProps,
  PlusSmallIcon,
  TrashBinIcon,
  usePrevious,
  FieldError
} from "@security-watchdog/sw-ui-kit";
import { DefaultTheme, useTheme } from "styled-components";
import { useForm, UseFormReturn, FormState } from "react-hook-form";
import {
  IFormFields,
  IGrade
} from "components/common/QualificationAndGradesField/types";
import { v4 } from "uuid";
import { validationResolver } from "components/common/QualificationAndGradesField/validationResolver";
import { ModalWindow } from "components/ModalWindow";
import * as s from "./styles";

export interface IQualificationAndGradesFieldProps {
  field: IFieldAnswer;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  questionId: string;
  answerId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChangeValue: (value: any) => void;
  errorMessage?: string;
  isDisabled: boolean;
  fieldId: string;
}

const QualificationAndGradesFieldComponent: FC<
  IQualificationAndGradesFieldProps
> = ({
  field,
  handleChangeValue,
  value,
  errorMessage,
  isDisabled,
  fieldId
}: IQualificationAndGradesFieldProps) => {
  const theme: DefaultTheme = useTheme();

  const {
    register,
    unregister,
    watch,
    setValue,
    handleSubmit,
    formState,
    trigger
  }: UseFormReturn<IFormFields> = useForm<IFormFields>({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: validationResolver as any
  });

  const { errors }: FormState<IFormFields> = formState;

  const [isRemoveAnswerModalActive, setIsRemoveAnswerModalActive]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const [idToRemove, setIdToRemove]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>("");

  const [isOpen, setIsOpen]: [
    boolean,
    React.Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const [selectedItemId, setSelectedItemId]: [
    string,
    React.Dispatch<SetStateAction<string>>
  ] = useState<string>("");

  const prevOpen: boolean | undefined = usePrevious<boolean>(isOpen);

  const changeStateOfRemoveModal = useCallback(
    (newValue: boolean) => (): void => setIsRemoveAnswerModalActive(newValue),
    []
  );

  const handleCloseModal = useCallback(() => {
    changeStateOfRemoveModal(false)();
    setIdToRemove("");
  }, [changeStateOfRemoveModal]);

  const onClickDeleteAnswer = useCallback(
    (id: string) => (): void => {
      setIdToRemove(id);
      changeStateOfRemoveModal(true)();
    },
    [changeStateOfRemoveModal]
  );

  const getClearGradesValue = useCallback(
    (): IGrade => ({
      grade: "",
      subject: "",
      id: v4()
    }),
    []
  );

  const getClearValue = useCallback(
    (): IFormFields => ({
      grades: [getClearGradesValue()],
      qualification: "",
      id: v4()
    }),
    [getClearGradesValue]
  );

  useEffect(() => {
    if (!prevOpen && isOpen) {
      const selectedValue: IFormFields =
        (Array.isArray(value) &&
          value.length &&
          value.find((value: IFormFields) => value.id === selectedItemId)) ||
        getClearValue();

      register("id");
      register("grades");
      register("qualification");

      setValue("id", selectedValue.id);
      setValue("grades", selectedValue.grades);
      setValue("qualification", selectedValue.qualification);
    }
  }, [
    getClearValue,
    field.value,
    isOpen,
    prevOpen,
    register,
    setValue,
    value,
    selectedItemId
  ]);

  useEffect(() => {
    if (!isOpen && prevOpen) {
      unregister("id");
      unregister("grades");
      unregister("qualification");
      setSelectedItemId("");
    }
  }, [isOpen, prevOpen, unregister]);

  const values: IFormFields = watch();

  const handleOpenGradesField = useCallback(() => setIsOpen(true), []);

  const handleCloseGradesSidebar = useCallback(() => setIsOpen(false), []);

  const handleDeleteSubEntry = useCallback(
    (rootId: string, subId: string) => (): void => {
      const grades = [...values.grades];

      const gradeIndex: number = values.grades.findIndex(
        (grade: IGrade) => grade.id === subId
      );

      grades.splice(gradeIndex, 1);

      setValue("grades", grades);

      trigger();
    },
    [values, setValue, trigger]
  );

  const addSubEntry = useCallback((): void => {
    setValue("grades", [...values.grades, getClearGradesValue()]);
  }, [getClearGradesValue, values, setValue]);

  const handleChangeTextInputs = useCallback(
    (id: string, key: keyof IGrade) =>
      (e: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(
          "grades",
          values.grades.map((grade: IGrade) =>
            grade.id === id
              ? {
                  ...grade,
                  [key]: e.target.value
                }
              : grade
          ),
          { shouldValidate: true }
        );
      },
    [values, setValue]
  );

  const handleChangeQualification = useCallback(
    (value: DropDownOnChange<string>): void => {
      setValue("qualification", value as string, { shouldValidate: true });
    },
    [setValue]
  );

  const handleSave = useCallback(
    (data: IFormFields) => {
      if (selectedItemId && Array.isArray(value) && value.length) {
        handleCloseGradesSidebar();
        return handleChangeValue(
          value.map((val: IFormFields) =>
            val.id === data.id ? { ...data } : val
          )
        );
      }

      handleCloseGradesSidebar();
      return handleChangeValue([
        ...(value !== undefined && Array.isArray(value) ? value : []),
        data
      ]);
    },
    [handleChangeValue, handleCloseGradesSidebar, selectedItemId, value]
  );

  const handleDelete = useCallback((): void => {
    handleChangeValue(
      value?.filter((val: IFormFields) => val.id !== idToRemove)
    );
    setIdToRemove("");
    setIsRemoveAnswerModalActive(false);
  }, [handleChangeValue, idToRemove, value]);

  const handleEdit = useCallback(
    (id: string) => (): void => {
      setSelectedItemId(id);
      handleOpenGradesField();
    },
    [handleOpenGradesField]
  );

  const renderView = (val: string): string | undefined =>
    field.options?.find(
      (option: OptionProps<string>): boolean => option.value === val
    )?.label;

  const renderGrades = useCallback(
    (id: string, grades: IGrade[]) => (
      <>
        {(grades || []).map((grade: IGrade) => (
          <s.GradesContainer key={grade.id}>
            {grade.subject} - {grade.grade}
          </s.GradesContainer>
        ))}
        <s.CardActionsContainer>
          <Button
            buttonType={BUTTON_TYPE.Ghost}
            icon={
              <EditSmallIcon
                size={16}
                color={
                  isDisabled
                    ? theme.colors["color-icon-disabled"]
                    : theme.colors["color-action-primary-default"]
                }
              />
            }
            onClick={handleEdit(id)}
            isDisabled={isDisabled}
          >
            Edit
          </Button>
          <Button
            buttonType={BUTTON_TYPE.GhostCritical}
            icon={
              <TrashBinIcon
                size={16}
                color={
                  isDisabled
                    ? theme.colors["color-icon-disabled"]
                    : theme.colors["color-action-critical-default"]
                }
              />
            }
            onClick={onClickDeleteAnswer(id)}
            isDisabled={isDisabled}
          >
            Delete
          </Button>
        </s.CardActionsContainer>
      </>
    ),
    [handleEdit, isDisabled, onClickDeleteAnswer, theme.colors]
  );

  const renderRemoveModal: ReactNode = useMemo<ReactNode>(
    () =>
      isRemoveAnswerModalActive && (
        <ModalWindow
          onModalClose={handleCloseModal}
          confirmAction={handleDelete}
          primaryBtnText="Remove"
          title="Confirm action"
        />
      ),
    [isRemoveAnswerModalActive, handleCloseModal, handleDelete]
  );

  const actionButtons: ReactNode = useMemo<ReactNode>(
    (): ReactNode => (
      <>
        <s.ActionButtonAdd
          buttonType={BUTTON_TYPE.Primary}
          onClick={handleSubmit(handleSave)}
          isDisabled={isDisabled}
        >
          Add
        </s.ActionButtonAdd>
        <s.ActionCancelButton
          buttonType={BUTTON_TYPE.Secondary}
          onClick={handleCloseGradesSidebar}
        >
          Cancel
        </s.ActionCancelButton>
      </>
    ),
    [handleSubmit, handleSave, isDisabled, handleCloseGradesSidebar]
  );

  const isAddEntryDisabled: boolean = useMemo<boolean>((): boolean => {
    if (field.isMulti) {
      return false;
    }

    return value && value.length === 1;
  }, [field.isMulti, value]);

  const parsedFieldTitle = (
    <div
      dangerouslySetInnerHTML={{ __html: getTextWithParsedLinks(field.title) }}
    />
  );

  return (
    <>
      <s.Wrapper role="region" aria-labelledby={`region-title-${fieldId}`}>
        <s.SectionTitle id={`region-title-${fieldId}`}>
          {parsedFieldTitle}
        </s.SectionTitle>
        {value?.map((qualificationAndGrades: IFormFields) => (
          <s.ExpandedCard
            key={qualificationAndGrades.id}
            id={qualificationAndGrades.id}
            primaryText={renderView(qualificationAndGrades.qualification) || ""}
            expandedText={renderGrades(
              qualificationAndGrades.id,
              qualificationAndGrades.grades
            )}
          />
        ))}

        {errorMessage && (
          <s.ErrorWrapper>
            <FieldError message={errorMessage} />
          </s.ErrorWrapper>
        )}

        <Button
          buttonType={BUTTON_TYPE.Ghost}
          icon={
            <PlusSmallIcon
              size={16}
              color={
                isAddEntryDisabled || isDisabled
                  ? theme.colors["color-icon-disabled"]
                  : theme.colors["color-action-primary-default"]
              }
            />
          }
          onClick={handleOpenGradesField}
          isDisabled={isAddEntryDisabled || isDisabled}
        >
          Add Entry
        </Button>
      </s.Wrapper>

      <s.Sidebar
        onClose={handleCloseGradesSidebar}
        isOpened={isOpen}
        withCloseIcon
        ariaLabel="Add qualification"
        header={
          <s.Heading id={`field-header-${fieldId}`}>
            Add qualification
          </s.Heading>
        }
        actionButtons={actionButtons}
      >
        <s.Container>
          {values && (
            <section
              key={values.id}
              role="group"
              aria-labelledby={`field-header-${fieldId}`}
            >
              <s.Field
                label={parsedFieldTitle}
                id={`field-qualification-${fieldId}`}
              >
                <Dropdown<string>
                  isSearchable
                  isDisabled={isDisabled}
                  isMulti={false}
                  value={values.qualification}
                  onChange={handleChangeQualification}
                  options={field.options || []}
                  popoverPositionFixed={false}
                  errorMessage={errors.qualification?.message}
                  id={`field-qualification-${fieldId}`}
                />
              </s.Field>

              <div style={{ height: 20 }} />

              {values?.grades?.map((grade: IGrade, index: number) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const errorMessage: any = errors.grades;

                const gradeErrorMessage: string = errorMessage
                  ? errorMessage[index]?.grade?.message
                  : "";

                const subjectErrorMessage: string = errorMessage
                  ? errorMessage[index]?.subject?.message
                  : "";

                return (
                  <s.DoubleField
                    key={grade.id}
                    role="group"
                    aria-label={`Grade entry ${index + 1}`}
                  >
                    <s.LongField>
                      {index === 0 ? (
                        <s.FieldLabel>Subject</s.FieldLabel>
                      ) : null}
                      <Input
                        isDisabled={isDisabled}
                        errorMessage={subjectErrorMessage}
                        value={grade.subject}
                        onChange={handleChangeTextInputs(grade.id, "subject")}
                        inputId={`${fieldId}-subject-${index}`}
                        title={`Grade entry ${index + 1} Subject`}
                      />
                    </s.LongField>

                    <s.ShortField>
                      {index === 0 ? <s.FieldLabel>Grade</s.FieldLabel> : null}
                      <s.DoubleFieldInputWrapper>
                        <Input
                          isDisabled={isDisabled}
                          value={grade.grade}
                          onChange={handleChangeTextInputs(grade.id, "grade")}
                          errorMessage={gradeErrorMessage}
                          inputId={`${fieldId}-grade-${index}`}
                          title={`Grade entry ${index + 1} Grade`}
                        />
                        {values.grades.length > 1 && (
                          <s.DeleteButton
                            isDisabled={isDisabled}
                            buttonType={BUTTON_TYPE.Link}
                            icon={
                              <TrashBinIcon
                                size={24}
                                color={
                                  theme.colors["color-action-secondary-default"]
                                }
                              />
                            }
                            onClick={handleDeleteSubEntry(values.id, grade.id)}
                            ariaLabel={`Remove grade entry ${index + 1}`}
                          />
                        )}
                      </s.DoubleFieldInputWrapper>
                    </s.ShortField>
                  </s.DoubleField>
                );
              })}

              <s.AddSubEntryButton
                isDisabled={isDisabled}
                buttonType={BUTTON_TYPE.Ghost}
                icon={
                  <PlusSmallIcon
                    size={16}
                    color={
                      isDisabled
                        ? theme.colors["color-icon-disabled"]
                        : theme.colors["color-action-primary-default"]
                    }
                  />
                }
                onClick={addSubEntry}
              >
                Add Entry
              </s.AddSubEntryButton>
            </section>
          )}
        </s.Container>
      </s.Sidebar>
      {renderRemoveModal}
    </>
  );
};

export const QualificationAndGradesField: NamedExoticComponent<IQualificationAndGradesFieldProps> =
  memo<IQualificationAndGradesFieldProps>(QualificationAndGradesFieldComponent);
