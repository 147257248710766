import styled from "styled-components";
import { Button, IButtonProps } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const ContainerAddress = styled.div`
  margin: 25px 0 16px;
`;

export const CustomButton = styled(Button)<IButtonProps>`
  @media (max-width: ${DEVICE.TABLET}) {
    width: 100%;
    justify-content: center;
  }
`;

export const CustomButtonAdd = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: ${({ theme }): number => theme.fontWeights.medium};
  color: ${({ theme, $isDisabled }): string =>
    $isDisabled
      ? theme.colors["color-icon-disabled"]
      : theme.colors["color-action-primary-default"]};
`;

export const Text = styled.span`
  margin-left: 16px;
  font-size: 11px;
  line-height: 16px;
  font-weight: ${({ theme }): number => theme.fontWeights.medium};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const ErrorMessage = styled.div`
  margin-bottom: 15px;
`;
