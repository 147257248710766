import { Spinner } from "components/common/Spinner";
import { MetaData } from "components/MetaData";
import React from "react";

export const Login: React.FC = () => (
  <>
    <MetaData title="Login" />
    <Spinner />
  </>
);

export default Login;
