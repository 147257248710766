import styled from "styled-components";
import { NavItems } from "components/Header/NavItem";
import { BurgerMenu } from "components/BurgerMenu";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";
import { IProps } from "./types";
import { Link } from "react-router-dom";

export const HeaderContainer = styled.header<IProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 2px -2px rgb(221 222 237 / 50%);
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors["color-surface-default"]};
  height: 72px;
  padding: 8px 40px;
  box-sizing: border-box;
  position: ${(props: IProps): string =>
    props.$isSticky ? "sticky" : "relative"};
  top: 0;
  width: 100%;
  left: 0;
  z-index: ${({ theme, $isSticky }): number | undefined =>
    $isSticky ? theme.layers.sticky : undefined};

  &.menu-opened {
    z-index: ${({ theme }): number => theme.layers.overlay};
  }

  @media (max-width: ${DEVICE.TABLET}) {
    justify-content: space-between;
    padding: 8px 0 8px 16px;
    width: 100%;
    z-index: ${({ theme }): number => theme.layers.sticky};
  }
  @media (max-width: ${DEVICE.MOBILE_M}) {
    position: relative;
  }
`;

export const TitleHeader = styled(Link)<IProps>`
  text-decoration: none;
  height: 100%;
  box-sizing: border-box;
  align-items: center;

  @media (max-width: ${DEVICE.TABLET}) {
    left: 16px;
  }

  &.hidden {
    display: none;
  }
`;

export const NavButtons = styled(NavItems)<IProps>`
  @media (max-width: ${DEVICE.TABLET}) {
    display: none;
  }
`;

export const BurgerMenuCustom = styled(BurgerMenu)<IProps>`
  @media (min-width: ${DEVICE.TABLET}) {
    display: none;
  }
`;

export const PageTitle = styled.h1`
  ${getTypographyStyles({ variant: "h1" })};
  margin: 0;
`;

export const PageTitleStatusContainer = styled.div`
  box-sizing: border-box;

  .section-title {
    font-size: 16px;
    line-height: 36px;
  }

  .section-status {
    margin-top: 0;
  }
`;
