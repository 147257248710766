import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  IButtonProps
} from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const ContentContainer = styled.div`
  display: flex;
  margin-top: 25px;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  order: 0;
  @media (max-width: ${DEVICE.TABLET}) {
    order: 1;
  }
`;

export const ContainerAttachDocuments = styled.div`
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors["color-surface-default"]};
  padding: 24px;
`;

export const InfoContainer = styled.div`
  background-color: ${({ theme }) => theme.colors["color-surface-default"]};
  padding: 15px 24px;
  width: 280px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  order: 1;
  @media (max-width: ${DEVICE.TABLET}) {
    order: 0;
    margin-left: 0;
    width: auto;
    margin-bottom: 24px;
  }
`;

export const InfoTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const InfoTitle = styled.div`
  ${getTypographyStyles({ variant: "body-highlight" })};
`;

export const InfoDescription = styled.div`
  ${getTypographyStyles({ variant: "body-wide-line" })};
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
  }
`;

export const CustomButton = styled(Button)<IButtonProps>`
  @media (max-width: ${DEVICE.TABLET}) {
    justify-content: center;
    margin-bottom: 15px;
  }
`;

export const ErrorText = styled.div`
  margin-top: 8px;
`;
