import React, {
  MutableRefObject,
  PropsWithChildren,
  useRef,
  useEffect
} from "react";
import { BUTTON_TYPE, FocusTrap } from "@security-watchdog/sw-ui-kit";
import { Portal } from "components/Portal";
import { IProps } from "./types";
import * as s from "./styles";

export const ConfirmModalComponent: React.FC<PropsWithChildren<IProps>> = ({
  isShowModal,
  children,
  onModalClose,
  confirmAction,
  ariaLabelledBy,
  primaryBtnText,
  buttonsState,
  title = "Confirm action",
  dataTestId = "confirm-modal"
}: PropsWithChildren<IProps>) => {
  const cancelBtnRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);

  useEffect(() => {
    if (cancelBtnRef.current) {
      cancelBtnRef.current.focus();
    }
  });

  return isShowModal ? (
    <Portal>
      <s.Container data-testid={dataTestId}>
        <FocusTrap isActive>
          <s.ModalContainer
            role="dialog"
            aria-modal="true"
            aria-labelledby={ariaLabelledBy ?? "dialog-title"}
          >
            <s.Header id="dialog-title">{title}</s.Header>
            <s.BodyModal>{children}</s.BodyModal>
            <s.ButtonsContainer $withoutCloseButton={!onModalClose}>
              <s.SubmitButton
                isLoading={buttonsState?.confirmLoading}
                isDisabled={buttonsState?.confirmDisabled}
                buttonType={BUTTON_TYPE.Primary}
                onClick={confirmAction}
              >
                {primaryBtnText}
              </s.SubmitButton>
              {onModalClose && (
                <s.CancelButton
                  isDisabled={buttonsState?.cancelDisabled}
                  buttonRef={cancelBtnRef}
                  buttonType={BUTTON_TYPE.Secondary}
                  onClick={onModalClose}
                >
                  Cancel
                </s.CancelButton>
              )}
            </s.ButtonsContainer>
          </s.ModalContainer>
        </FocusTrap>
      </s.Container>
    </Portal>
  ) : null;
};

export const ConfirmModal = React.memo<PropsWithChildren<IProps>>(
  ConfirmModalComponent
);
