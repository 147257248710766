import styled from "styled-components";
import { DEVICE } from "src/constants";
import { Button, getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import { IPropsStyles } from "./types";

export const ContainerListSectionItems = styled.div`
  margin: 25px 0 16px;
  font-size: 20px;
`;

export const InfoSectionBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const NameSectionBlock = styled.div`
  font-size: 16px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: calc(100% - 44px); /* 24px - size of Icon + 20px padding left*/
`;

export const TitleCard = styled.span`
  ${getTypographyStyles({
    color: "color-text-default",
    variant: "body-highlight"
  })};

  @media (max-width: ${DEVICE.TABLET}) {
    order: 1;
  }
`;

export const TitleSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const Description = styled.span`
  color: ${({ theme }) => theme.colors["color-text-subdued"]};

  @media (max-width: ${DEVICE.TABLET}) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ButtonSubmit = styled(Button)<IPropsStyles>`
  width: ${({ isButtonModal }): string => (isButtonModal ? "240px" : "auto")};
  justify-content: center;
  @media (max-width: ${DEVICE.TABLET}) {
    width: 100%;
    justify-content: center;
  }
`;

export const ArrowIconForMobile = styled.div`
  display: none;

  @media (max-width: ${DEVICE.TABLET}) {
    display: block;
  }
`;

export const ScreenerInfoBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  ${getTypographyStyles({
    variant: "caption",
    color: "color-text-subdued"
  })}
  & > span {
    margin-left: 8px;
  }
`;

export const ConfirmModalText = styled.div`
  ${getTypographyStyles({ variant: "body-wide-line" })};
  text-align: left;
`;
