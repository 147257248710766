import React, { FC, useCallback, useState } from "react";
import {
  AlertCircleIcon,
  theme,
  Button,
  BUTTON_TYPE,
  LifebuoyIcon
} from "@security-watchdog/sw-ui-kit";

import { TermsAndConditionsModal } from "components/TermsAndConditionsModal";
import { DesignSystemConfirmModal } from "components/common/DesignSystemConfirmModal";
import {
  getCase_screeningCase_sections,
  TrustIDApplicationStatus
} from "src/graphQLTypes";
import { useDifficultiesBtnState } from "./useDifficultiesBtnState";
import { useGoToTrustIdBtnState } from "./useGoToTrustIdBtnState";
import { useCreateTrustIdGuestLink } from "./useCreateTrustIdGuestLink";
import * as s from "./styles";

type TrustIdPageContentProps = {
  sectionData: getCase_screeningCase_sections | undefined;
  caseId: string;
  status: TrustIDApplicationStatus | null;
  onTrustIdFormOpen: () => void;
  onDifficultiesConfirmed: () => void;
};

export const TrustIdPageContent: FC<TrustIdPageContentProps> = ({
  sectionData,
  caseId,
  status,
  onTrustIdFormOpen,
  onDifficultiesConfirmed
}) => {
  const [isTermsModalOpened, setIsTermsModalOpened] = useState<boolean>(false);
  const [isDifficultiesModalOpened, setIsDifficultiesModalOpened] =
    useState<boolean>(false);

  const {
    buttonState: difficultiesBtnState,
    setDifficultiesButtonVisibilityToStorage
  } = useDifficultiesBtnState({
    sectionAnswers: sectionData?.answers || [],
    trustIdStatus: status
  });

  const { buttonState: goToTrustIdBtnState } = useGoToTrustIdBtnState({
    sectionAnswers: sectionData?.answers || [],
    trustIdStatus: status
  });

  const { createTrustIdGuestLink, submitTrustIdSection } =
    useCreateTrustIdGuestLink({
      sectionAnswers: sectionData?.answers || [],
      caseId
    });

  const onTermsModalClose = useCallback(() => {
    setIsTermsModalOpened(false);
    setDifficultiesButtonVisibilityToStorage();
  }, [setDifficultiesButtonVisibilityToStorage]);

  const onTermsModalConfirm = useCallback(async () => {
    setIsTermsModalOpened(false);
    // prevent safari browser window pop-up blockers
    const newWindow = window.open();

    const url = await createTrustIdGuestLink();

    if (url && newWindow) {
      newWindow.location.href = url;
      onTrustIdFormOpen();
    }
    setDifficultiesButtonVisibilityToStorage();
  }, [
    createTrustIdGuestLink,
    onTrustIdFormOpen,
    setDifficultiesButtonVisibilityToStorage
  ]);

  const onDifficultiesModalClose = useCallback(() => {
    setIsDifficultiesModalOpened(false);
  }, []);

  const onDifficultiesModalConfirm = useCallback(() => {
    setIsDifficultiesModalOpened(false);
    submitTrustIdSection(true);
    onDifficultiesConfirmed();
  }, [submitTrustIdSection, onDifficultiesConfirmed]);

  const goToTrustIdHandler = useCallback(() => {
    setIsTermsModalOpened(true);
  }, []);

  const onDifficultiesBtnClick = useCallback(() => {
    setIsDifficultiesModalOpened(true);
  }, []);

  return (
    <s.Container>
      <TermsAndConditionsModal
        headerSubTitle="Agree to data processing by Trust ID"
        isOpen={isTermsModalOpened}
        onClose={onTermsModalClose}
        onConfirm={onTermsModalConfirm}
      >
        <div className="body-wide-line color-text-default">
          <p className="margin-b-4">
            I also authorise Security Watchdog to share my personal data with
            TrustID Limited for the purpose of verifying my right to work in the
            UK and my identity.
          </p>
          <p className="margin-b-4">
            I understand that Trust ID will process my data in accordance with
            UK Data Protection legislation and that the results will be shared
            with Security Watchdog and my prospective employer (or prospective
            client where I will not directly be employed)
          </p>
          <p className="margin-b-4">
            I confirm that I have read and understood Security Watchdog’s
            Privacy Notice.
          </p>
          <p className="margin-b-4">
            I confirm that I have also read my prospective employer’s Privacy
            Notice, as the Data Controller, and understand my subject rights
            under UK GDPR and understand the legal basis for processing my
            personal information.
          </p>
          <p>
            I confirm that I have read and understood Trust ID’s Privacy Notice,
            and understand how my personal data will be processed during the
            identity verification process.
          </p>
        </div>
      </TermsAndConditionsModal>
      <DesignSystemConfirmModal
        showModal={isDifficultiesModalOpened}
        onConfirm={onDifficultiesModalConfirm}
        onModalClose={onDifficultiesModalClose}
      >
        <div>
          <s.ConfirmationModalSubHeader>
            Are you sure you want to proceed?
          </s.ConfirmationModalSubHeader>
          <s.ConfirmModalInfoBanner>
            <s.InfoBannerIcon>
              <AlertCircleIcon
                size={20}
                color={theme.colors["color-icon-warning"]}
              />
            </s.InfoBannerIcon>
            <s.InfoBannerText>
              Verifying your identity is a mandatory part of screening process.
              Our team will contact you to discuss alternative methods of
              verifying your identity if you are unable to complete this part of
              the form. This will delay your screening.
            </s.InfoBannerText>
          </s.ConfirmModalInfoBanner>
        </div>
      </DesignSystemConfirmModal>
      <s.Section aria-labelledby="trust-id-page-about-title">
        <s.Title id="trust-id-page-about-title">About Trust ID</s.Title>
        <s.Text>
          Trust ID are experts in identity documentation verification services
          and are a certified Identity Document Service Provider (IDSP) under
          the Home Office certification scheme.
        </s.Text>
        <s.Text>
          You will be redirected to our digital identity provider, Trust ID, to
          complete a digital identity verification.
        </s.Text>
      </s.Section>
      <s.Section aria-labelledby="trust-id-page-works-title">
        <s.Title id="trust-id-page-works-title">How it works</s.Title>
        <s.Text>
          In order to run our checks we need to validate your right to work in
          the UK as well as your identity.
        </s.Text>
        <s.Text>
          Trust ID will capture photos of your ID documentation and/or a selfie
          that enables us to verify your identity remotely.
        </s.Text>
        <s.Text>
          Upon accepting the terms and conditions a new tab will open to take
          you through to the Trust ID form.
        </s.Text>
      </s.Section>
      <s.PageInfoBanner role="alert">
        <s.InfoBannerIcon>
          <AlertCircleIcon
            size={20}
            color={theme.colors["color-icon-warning"]}
          />
        </s.InfoBannerIcon>
        <div>
          Don&apos;t forget to return here after completing the Trust ID form
        </div>
      </s.PageInfoBanner>
      <s.ActionsWrapper>
        {goToTrustIdBtnState.isVisible && (
          <Button
            buttonType={BUTTON_TYPE.Primary}
            isDisabled={goToTrustIdBtnState.isDisabled}
            onClick={goToTrustIdHandler}
          >
            Go to Trust ID form
          </Button>
        )}
        {difficultiesBtnState.isVisible && (
          <Button
            buttonType={BUTTON_TYPE.Ghost}
            onClick={onDifficultiesBtnClick}
            isDisabled={difficultiesBtnState.isDisabled}
            icon={
              <LifebuoyIcon
                size={16}
                color={
                  difficultiesBtnState.isDisabled
                    ? theme.colors["color-icon-disabled"]
                    : theme.colors["color-action-primary-default"]
                }
              />
            }
          >
            Unable to complete Trust ID
          </Button>
        )}
      </s.ActionsWrapper>
    </s.Container>
  );
};
