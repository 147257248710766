import { UseFormRegister, UseFormUnregister } from "react-hook-form";

import {
  IAnswer,
  IFieldAnswer,
  IFormFields,
  IPartialTroubleShootHistoryRecord,
  IQuestion
} from "../../../types";
import {
  CandidateCaseFragment_sectionsByAffectingField,
  CaseTroubleshoootingHistoryType,
  Section
} from "../../../graphQLTypes";
import { checkEntityIds } from "src/utils/checkEntityIds";
import {
  buildFieldKey,
  checkIsFieldVisible,
  getConditionId,
  isGeneralUploadField,
  parseFieldKey
} from "src/utils";
import { checkIsQuestionVisible } from "src/utils/checkConditionValue";
import { GetUpdatedFieldsVisibilityArgs } from "containers/common/SectionDetails/types";

const isConditionalFieldVisible = (
  field: IFieldAnswer,
  allFields: IFieldAnswer[]
): boolean => {
  return field.conditions.length
    ? field.conditions.some((conditionsList) =>
        conditionsList.every(
          (condition) =>
            allFields.find((i) => i.fieldId === condition.fieldId)?.isVisible
        )
      )
    : field.isVisible;
};

export const convertQuestionsToPartialFieldHistoryRecords = (
  questions: IQuestion[]
): IPartialTroubleShootHistoryRecord[] =>
  questions.reduce(
    (
      acc: IPartialTroubleShootHistoryRecord[],
      question: IQuestion
    ): IPartialTroubleShootHistoryRecord[] =>
      acc.concat(
        question.answers.reduce(
          (
            acc: IPartialTroubleShootHistoryRecord[],
            answer: IAnswer
          ): IPartialTroubleShootHistoryRecord[] =>
            acc.concat(
              answer.fields.map(
                (field: IFieldAnswer): IPartialTroubleShootHistoryRecord => ({
                  type: CaseTroubleshoootingHistoryType.FIELD_VALUE_UPDATED,
                  index: answer.index,
                  isDeleted: Boolean(answer.isDeleted),
                  answerId: answer.answerId,
                  fieldId: field.fieldId,
                  from: field.value,
                  to: undefined,
                  fieldType: field.type,
                  isMulti: field.isMulti
                })
              )
            ),
          []
        )
      ),
    []
  );

type CheckIfFieldShouldTriggerCaseUpdateArgs = {
  fieldId: string;
  section: Section;
  sectionsByAffectingField: CandidateCaseFragment_sectionsByAffectingField[];
};

export const checkIfFieldShouldTriggerCaseUpdate = ({
  fieldId,
  section,
  sectionsByAffectingField
}: CheckIfFieldShouldTriggerCaseUpdateArgs): boolean => {
  const sectionByAffectingField = sectionsByAffectingField.find((i) =>
    checkEntityIds(i.fieldId, fieldId)
  );

  if (sectionByAffectingField) {
    return sectionByAffectingField.conditionalProductsAffectedSections.includes(
      section
    );
  }

  return false;
};

type GetUpdatedQuestionsVisibilityArgs = {
  triggeredFieldKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  triggeredFieldKeyValue: any;
  formValues: IFormFields;
  questions: IQuestion[];
  registerField: UseFormRegister<IFormFields>;
  unregisterField: UseFormUnregister<IFormFields>;
};

export const getUpdatedQuestionsVisibility = ({
  questions,
  triggeredFieldKey,
  triggeredFieldKeyValue,
  formValues,
  registerField,
  unregisterField
}: GetUpdatedQuestionsVisibilityArgs): IQuestion[] => {
  return questions.map((question) => {
    const questionConditions =
      question.questionConditionsMap[getConditionId(question.questionId)];

    if (questionConditions?.length) {
      const isQuestionVisible = checkIsQuestionVisible({
        conditions: questionConditions,
        fieldKey: triggeredFieldKey,
        value: triggeredFieldKeyValue,
        initialValue: question.isVisible
      });

      question.answers.forEach((answer) => {
        answer.fields.forEach((field) => {
          if (isGeneralUploadField(field.type)) {
            return;
          }

          const fieldKey = buildFieldKey({
            questionId: question.questionId,
            answerId: answer.answerId,
            fieldId: field.fieldId,
            type: field.type,
            fieldType: field.fieldType,
            isMulti: field.isMulti
          });

          const isRegistered = Object.keys(formValues).some(
            (key) => key === fieldKey
          );

          if (field.isVisible && !isRegistered) {
            registerField(fieldKey);
          }

          if (!isQuestionVisible) {
            unregisterField(fieldKey);
          }
        });
      });

      return {
        ...question,
        isVisible: isQuestionVisible
      };
    }

    return question;
  });
};

export const getUpdatedFieldsVisibility = ({
  fieldKey,
  value,
  unregisteredFields,
  formValues,
  questions
}: GetUpdatedFieldsVisibilityArgs): IQuestion[] => {
  const { questionId, answerId } = parseFieldKey(fieldKey ?? "");

  return questions.map((question) => {
    if (question.questionId !== questionId) {
      return question;
    }

    return {
      ...question,
      answers: question.answers.map((answer) => {
        if (answer.answerId !== answerId) {
          return answer;
        }

        const answerFields = [...answer.fields];

        answer.fields.forEach((field, idx) => {
          const isCondFieldVisible = isConditionalFieldVisible(
            field,
            answerFields
          );
          const isFieldVisible = isCondFieldVisible
            ? checkIsFieldVisible({
                conditions: field.conditions,
                questionId,
                answerId,
                value,
                formValues,
                fieldKey: fieldKey ?? "",
                unregisteredFields
              })
            : isCondFieldVisible;

          answerFields[idx] = {
            ...field,
            isVisible: isFieldVisible,
            value: isFieldVisible ? field.value : undefined
          };
        });

        return {
          ...answer,
          fields: answerFields
        };
      })
    };
  });
};

export const getConditionalProductQuestionToSubmit = (
  changedFormFieldKey: string,
  questions: IQuestion[]
): IQuestion | null => {
  const { questionId, answerId, fieldId } = parseFieldKey(changedFormFieldKey);

  return questions.reduce<IQuestion | null>((acc, question) => {
    if (question.questionId === questionId) {
      return question.answers.reduce<IQuestion | null>(
        (questionAcc, answer) => {
          if (answer.answerId === answerId) {
            const field = answer.fields.find((f) => f.fieldId === fieldId);

            if (field && questionAcc) {
              return {
                ...questionAcc,
                answers: [{ ...answer, fields: [field] }]
              };
            }
          }

          return questionAcc;
        },
        question
      );
    }

    return acc;
  }, null);
};
