import styled, { css } from "styled-components";
import { hexToRgb, theme } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${`rgba(${hexToRgb(theme.colors["color-surface-overlay"])}, 0.1)`};
`;

export const ModalContainer = styled.div<{ $fullHeightMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 752px;
  background: ${theme.colors["color-surface-default"]};
  border-radius: 4px;

  @media (max-width: ${DEVICE.TABLET}) {
    ${({ $fullHeightMode }) =>
      $fullHeightMode &&
      css`
        top: 32px;
      `}
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  @media (max-width: ${DEVICE.TABLET}) {
    top: unset;
    max-height: 100%;
    overflow-y: auto;
  }
`;
