import React, { FC, ReactNode } from "react";
import { Link } from "@security-watchdog/ui-components";

type AcceptableDocumentsLinkProps = {
  children: ReactNode;
};

export const AcceptableDocumentsLink: FC<AcceptableDocumentsLinkProps> = ({
  children
}) => (
  <Link
    to="https://www.securitywatchdog.org.uk/candidate-zone-acceptable-documentation"
    target="_blank"
  >
    {children}
  </Link>
);
