import { ButtonBack } from "components/ButtonBack";
import { PageHeader } from "components/PageHeader";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/constants";
import googleAnalytics, { IUseGA } from "src/lib/googleAnalytics";
import * as s from "./styles";
import { ContentLayout } from "components/ContentLayout";
import { Header as CommonHeader } from "components/Header";
import { MetaData } from "components/MetaData";
import { Alert, Link } from "@security-watchdog/ui-components";

export const AccessibilityStatementPage: React.FC = () => {
  const { logPageView }: IUseGA = googleAnalytics();

  const navigateTo = useNavigate();

  const goToBack = useCallback(
    () => navigateTo({ pathname: ROUTES.ROOT, search: window.location.search }),
    [navigateTo]
  );

  useEffect(() => {
    logPageView(window.location.pathname);
  }, []);

  return (
    <>
      <MetaData title="Accessibility statement for VOLTi4" />

      <CommonHeader />

      <ContentLayout>
        <ButtonBack handlerClick={goToBack}>Back</ButtonBack>
        <PageHeader title="Accessibility statement for VOLTi4" />
        <s.ContentBox>
          <Alert variant="info" className="margin-b-8">
            <div>
              This accessibility statement applies to the VOLTi4 Candidate and
              Client Portals.
            </div>
            <div>
              It does not cover third parties that the user may be re-directed
              to as part of the user journey in either Portal.
            </div>
          </Alert>
          <s.Text className="margin-b-2">
            This website is run by
            <Link
              className="margin-l-1"
              to="https://www.securitywatchdog.org.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Matrix Security Watchdog
            </Link>
            . We want as many people as possible to be able to use this website.
            For example, that means you should be able to:
          </s.Text>
          <s.StyledList className="margin-b-2">
            <li>
              <s.Text>
                Zoom in up to and over 400% without the text spilling off the
                screen
              </s.Text>
            </li>
            <li>
              <s.Text>
                Navigate most of the website using just a keyboard
              </s.Text>
            </li>
            <li>
              <s.Text>
                Navigate most of the website using speech recognition software
              </s.Text>
            </li>
            <li>
              <s.Text>
                Listen to most of the website using a screen reader
              </s.Text>
            </li>
          </s.StyledList>
          <s.Text className="margin-b-2">
            We’ve also made the website text as simple as possible to
            understand.
          </s.Text>
          <s.Text className="margin-b-8">
            <Link
              className="margin-r-1"
              to="https://mcmw.abilitynet.org.uk/"
              target="_blank"
              rel="noreferrer"
            >
              AbilityNet
            </Link>
            has advice on making your device easier to use if you have a
            disability.
          </s.Text>

          <s.PrimaryText>How accessible VOLTi4 is</s.PrimaryText>
          <s.Text className="margin-b-2">
            We know some parts of this website are not fully accessible:
          </s.Text>
          <s.StyledList className="margin-b-8">
            <li>
              <s.Text>You cannot modify the line height of text</s.Text>
            </li>
            <li>
              <s.Text>Some PDF documents are not fully accessible</s.Text>
            </li>
            <li>
              <s.Text>
                Some error messages and date formats may not be clear when using
                a screen reader
              </s.Text>
            </li>
            <li>
              <s.Text>
                On mobile, some formatting is not fully accessible when zoomed
                in over 200% or in landscape orientation.
              </s.Text>
            </li>
          </s.StyledList>

          <s.PrimaryText>Feedback and contact information</s.PrimaryText>
          <s.Text className="margin-b-2">
            If you find any problems not listed on this page or think we’re not
            meeting accessibility requirements, contact our Accessibility
            Officer on the contact details below.
          </s.Text>
          <s.Text className="margin-b-2">
            If you need information on this website in a different format like
            accessible PDF, large print, easy read, audio recording or braille:
          </s.Text>
          <s.StyledList className="margin-b-2">
            <li>
              <s.Text>
                Email us at
                <Link
                  className="margin-l-1"
                  to="mailto:accessibility@teammatrix.com"
                >
                  accessibility@teammatrix.com
                </Link>
              </s.Text>
            </li>
            <li>
              <s.Text>
                Call us on
                <Link className="margin-l-1" to="tel:+44 (0)1420 593 830">
                  +44 (0)1420 593 830
                </Link>
              </s.Text>
            </li>
          </s.StyledList>
          <s.Text className="margin-b-8">
            We’ll consider your request and get back to you within 10 days.
          </s.Text>

          <s.PrimaryText>Enforcement procedure</s.PrimaryText>
          <s.Text className="margin-b-8">
            The Equality and Human Rights Commission (EHRC) is responsible for
            enforcing the Public Sector Bodies (Websites and Mobile
            Applications) (No. 2) Accessibility Regulations 2018 (the
            ‘accessibility regulations’). If you’re not happy with how we
            respond to your complaint,
            <Link
              className="margin-l-1"
              to="https://www.equalityadvisoryservice.com/"
              target="_blank"
              rel="noreferrer"
            >
              contact the Equality Advisory and Support Service (EASS)
            </Link>
            .
          </s.Text>

          <s.PrimaryText>
            Technical information about this website’s accessibility
          </s.PrimaryText>
          <s.Text className="margin-b-4">
            Matrix Security Watchdog is committed to making its website
            accessible, in accordance with the Public Sector Bodies (Websites
            and Mobile Applications) (No. 2) Accessibility Regulations 2018.
          </s.Text>
          <s.SecondaryText className="margin-b-2">
            Compliance status
          </s.SecondaryText>
          <s.Text className="margin-b-8">
            VOLTi4 Candidate Portal is partially compliant with the
            <Link
              className="margin-l-1 margin-r-1"
              to="https://www.w3.org/TR/WCAG22/"
              target="_blank"
              rel="noreferrer"
            >
              Web Content Accessibility Guidelines version
            </Link>
            2.2 AA standard.
          </s.Text>

          <s.PrimaryText>Non-accessible content</s.PrimaryText>
          <s.Text className="margin-b-4">
            The content listed below is non-accessible for the following
            reasons.
          </s.Text>
          <s.SecondaryText>
            Non-compliance with the accessibility regulations
          </s.SecondaryText>
          <s.TextHighlight>Candidate portal</s.TextHighlight>
          <s.StyledList className="margin-b-2">
            <li>
              <s.Text>
                On mobile, not all of the content is visible when using
                landscape orientation (1.3.4)
              </s.Text>
            </li>
            <li>
              <s.Text>
                On mobile, some input areas are not completely visible when
                zoomed in over 200% (1.4.4)
              </s.Text>
            </li>
            <li>
              <s.Text>
                The links in the footer of Candidate Portal appear in a new
                window, and this is not announced to the user (3.2.2)
              </s.Text>
            </li>
            <li>
              <s.Text>
                When using a screen reader, some error messages are unclear as
                to which field they have been triggered by (3.3.1, 4.1.3)
              </s.Text>
            </li>
            <li>
              <s.Text>
                When using a screen reader, the format needed for dates is not
                always clear (3.3.2)
              </s.Text>
            </li>
          </s.StyledList>
          <s.Text className="margin-b-8">
            We plan to remedy these issues by the end of June 2025.
          </s.Text>

          <s.PrimaryText>
            Preparation of this accessibility statement
          </s.PrimaryText>
          <s.Text className="margin-b-2">
            This statement was prepared in March 2025. It was last reviewed in
            March 2025.
          </s.Text>
          <s.Text>
            VOLTi4 was last tested in March 2025 against the WCAG 2.2 AA
            standard. The test was carried out by our internal Quality Assurance
            and User Acceptance Testing teams using automated testing tools and
            manual review. We will continue to update this accessibility
            statement with any relevant changes.
          </s.Text>
        </s.ContentBox>
      </ContentLayout>
    </>
  );
};
