import React from "react";
import { BurgerIcon } from "components/common/icons";
import { CloseIcon } from "@security-watchdog/sw-ui-kit";
import { NavItems } from "components/Header/NavItem";
import * as s from "./styles";
import { IProps } from "./types";

export const BurgerMenu: React.FC<IProps> = ({
  isOpened,
  handleClick,
  className
}: IProps) => {
  const showMenu = (): void => {
    handleClick(!isOpened);
  };

  return (
    <s.Container
      className={className}
      tabIndex={0}
      role="button"
      aria-label={isOpened ? "Close burger menu" : "Burger menu"}
      onClick={showMenu}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          showMenu();
        }
      }}
    >
      {(!isOpened && <BurgerIcon />) || <CloseIcon />}
      <s.ContainerMenu $isShowMenu={isOpened}>
        <NavItems />
      </s.ContainerMenu>
    </s.Container>
  );
};
