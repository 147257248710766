import React, { FC, useEffect, useRef } from "react";
import { Portal } from "components/Portal";
import { BUTTON_TYPE, FocusTrap } from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";

export interface IOCRModalProps {
  onClose: () => void;
}

export const OCRModal: FC<IOCRModalProps> = ({ onClose }: IOCRModalProps) => {
  const closeBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (closeBtnRef.current) {
      closeBtnRef.current?.focus();
    }
  });

  return (
    <Portal>
      <s.Container>
        <FocusTrap isActive>
          <s.ModalContainer
            role="dialog"
            aria-modal="true"
            aria-labelledby={"ocr-modal-title"}
          >
            <s.Title id="ocr-modal-title">To speed things up</s.Title>
            <s.MainText>
              We use optical character recognition to complete some of the
              fields for you - we&apos;ve highlighted which ones. Please
              don&apos;t forget to check all the pre-populated information is
              correct before you click &apos;Complete&apos;
            </s.MainText>
            <s.CloseButton
              buttonType={BUTTON_TYPE.Secondary}
              onClick={onClose}
              buttonRef={closeBtnRef}
            >
              Close
            </s.CloseButton>
          </s.ModalContainer>
        </FocusTrap>
      </s.Container>
    </Portal>
  );
};
