import styled from "styled-components";
import { getTypographyStyles, theme } from "@security-watchdog/sw-ui-kit";
import { CardStatuses, IStatusCircleProps } from "./types";

const getColorNameByCardStatus = (status: CardStatuses): string | undefined => {
  switch (status) {
    case CardStatuses.INCOMPLETE:
      return theme.colors["color-icon-warning"];

    case CardStatuses.HAS_OPTIONAL:
      return theme.colors["color-icon-default"];

    case CardStatuses.COMPLETE:
      return theme.colors["color-icon-success"];
    default:
      return undefined;
  }
};

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusCircle = styled.div<IStatusCircleProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${({ status }): string | undefined =>
    getColorNameByCardStatus(status)};
`;

export const Title = styled.span`
  ${getTypographyStyles({ variant: "badge" })}
`;
