import styled, { css } from "styled-components";
import {
  Button,
  getTypographyStyles,
  IButtonProps
} from "@security-watchdog/sw-ui-kit";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors["color-surface-default"]};
  padding: 24px;
  border-radius: 4px;
  width: 368px;
  max-width: 100vw;
  box-sizing: border-box;
`;
export const Header = styled.div`
  ${getTypographyStyles({ variant: "h3", color: "color-text-on-surface" })};
  margin-bottom: 15px;
`;
export const BodyModal = styled.div`
  text-align: center;
  ${getTypographyStyles({ variant: "body-wide-line" })};
  margin-bottom: 25px;
`;
export const ButtonsContainer = styled.div<{ $withoutCloseButton: boolean }>`
  display: flex;
  width: 100%;

  ${({ $withoutCloseButton }) =>
    $withoutCloseButton &&
    css`
      button {
        width: 100%;
        margin: 0;
      }
    `}
`;

export const SubmitButton = styled(Button)<IButtonProps>`
  width: 168px;
  justify-content: center;
  margin-right: 16px;
`;
export const CancelButton = styled(Button)<IButtonProps>`
  width: 120px;
  justify-content: center;
`;
