import { TimelineEntityName } from "src/types";

type GetTimelineCardAriaLabelArgs = {
  entityName: TimelineEntityName;
  activityType: string | undefined;
  currentAddress: string | undefined;
  dateRange: string | undefined;
};

export const getTimelineCardAriaLabel = ({
  entityName,
  activityType,
  currentAddress,
  dateRange
}: GetTimelineCardAriaLabelArgs): string => {
  const mainText =
    entityName === TimelineEntityName.ACTIVITY ? activityType : currentAddress;
  const date = dateRange ? `, date range ${dateRange}` : "";

  return `${mainText ?? ""}${date}`;
};
