import React, { Fragment, useCallback, useMemo } from "react";
import { IFieldAnswer } from "src/types";
import { isUndefinedOrNull } from "src/utils/typeGuards";
import { FieldType } from "src/graphQLTypes";
import { formatDate, OptionProps } from "@security-watchdog/sw-ui-kit";
import { IProps } from "./types";
import * as s from "./styles";
import { getFieldAttachments } from "src/utils";

export const AnswerSidebarComponent: React.FC<IProps> = ({
  title,
  fields,
  isOpened,
  onClose
}: IProps) => {
  const renderFieldValue = useCallback(
    (title: string, value: string) => (
      <s.FieldContainer>
        <s.FieldTitle>{title}</s.FieldTitle>
        <s.FieldValue>{value}</s.FieldValue>
      </s.FieldContainer>
    ),
    []
  );

  const renderUploadField = useCallback(
    (field: IFieldAnswer) => (
      <s.FieldContainer>
        <s.FieldTitle>{field.title}</s.FieldTitle>
        <s.FileContainer>
          <s.DocumentIcon size={30} />
          <s.FileName>{field.value.fileName}</s.FileName>
        </s.FileContainer>
      </s.FieldContainer>
    ),
    []
  );

  const renderUploadsField = useCallback((field: IFieldAnswer) => {
    const attachments = getFieldAttachments(field.value);

    return (
      <s.FieldContainer>
        <s.FieldTitle>{field.title}</s.FieldTitle>
        {attachments.map((attachment) => (
          <s.FileContainer key={attachment.id}>
            <s.DocumentIcon size={30} />
            <s.FileName>{attachment.fileName}</s.FileName>
          </s.FileContainer>
        ))}
      </s.FieldContainer>
    );
  }, []);

  const renderCompanyField = useCallback(
    (field: IFieldAnswer) => (
      <>
        {renderFieldValue("Country", field.value.country.name)}
        {field.value.name && renderFieldValue("Name", field.value.name)}
        {renderFieldValue("Address line 1", field.value.addressLine1)}
        {field.value.addressLine2 &&
          renderFieldValue("Address line 2", field.value.addressLine2)}
        {field.value.addressLine3 &&
          renderFieldValue("Address line 3", field.value.addressLine3)}
        {renderFieldValue("Town", field.value.town)}
        {field.value.county && renderFieldValue("County", field.value.county)}
        {renderFieldValue("Postcode", field.value.postcode)}
      </>
    ),
    [renderFieldValue]
  );

  const renderSelectField = useCallback(
    (field: IFieldAnswer) => {
      const { value, isMulti, options }: IFieldAnswer = field;
      const stringValue: string | undefined =
        isMulti && Array.isArray(value)
          ? value
              .map(
                (v: string) =>
                  options?.find(
                    (option: OptionProps<string>) => option.value === v
                  )?.label
              )
              .join(" ,")
          : options?.find(
              (option: OptionProps<string>) => option.value === value
            )?.label;

      return renderFieldValue(field.title, stringValue || "");
    },
    [renderFieldValue]
  );

  const renderField = useCallback(
    (field: IFieldAnswer) => {
      switch (field.type) {
        case FieldType.UPLOAD:
          return renderUploadField(field);
        case FieldType.MULTIPLE_UPLOAD:
          return renderUploadsField(field);

        case FieldType.ADDRESS:
        case FieldType.COMPANY:
          return renderCompanyField(field);

        case FieldType.SELECT:
          return renderSelectField(field);

        case FieldType.COUNTRY:
          return renderFieldValue(field.title, field.value.name);

        case FieldType.BOOLEAN:
          return renderFieldValue(
            field.title,
            field.value === true ? "Yes" : "No"
          );

        case FieldType.DATE:
          return renderFieldValue(field.title, formatDate(field.value));

        case FieldType.DATE_RANGE:
          return renderFieldValue(
            field.title,
            `${formatDate(field.value.from)} - ${
              field.value.to ? formatDate(field.value.to) : "Current"
            }`
          );

        default:
          return renderFieldValue(field.title, field.value);
      }
    },
    [renderFieldValue, renderUploadField, renderCompanyField, renderSelectField]
  );

  const answerFields = useMemo(() => {
    const visibleFields: IFieldAnswer[] = fields.filter(
      (field: IFieldAnswer) =>
        field.isVisible && !isUndefinedOrNull(field.value)
    );

    return visibleFields.map((field: IFieldAnswer) => (
      <Fragment key={field.fieldId}>{renderField(field)}</Fragment>
    ));
  }, [fields, renderField]);

  return (
    <s.Sidebar
      onClose={onClose}
      withCloseIcon
      ariaLabel={title}
      header={<s.Heading>{title}</s.Heading>}
      isOpened={isOpened}
    >
      <s.ContentContainer>{answerFields}</s.ContentContainer>
    </s.Sidebar>
  );
};

export const AnswerSidebar = React.memo<IProps>(AnswerSidebarComponent);
