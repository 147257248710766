import { ITimeLineRestriction } from "src/types";
import { getDateUnitText } from "src/utils";

const TIMELINE_HISTORY_MESSAGE =
  "Please share your employment and education history (including your activities in between)";

export const getEmploymentAndEducationSectionDescription = (
  timeLineRestriction: ITimeLineRestriction | undefined,
  isMultipleAnswers: boolean | undefined
): string => {
  if (!isMultipleAnswers) {
    return "Please give us details of your current employer";
  }

  const duration = timeLineRestriction?.duration;
  const durationDate = duration
    ? getDateUnitText(timeLineRestriction?.durationUnit, duration)
    : "";

  return duration
    ? `${TIMELINE_HISTORY_MESSAGE} for the past ${duration} ${durationDate}`
    : TIMELINE_HISTORY_MESSAGE;
};
