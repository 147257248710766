import React, {
  FC,
  memo,
  MutableRefObject,
  NamedExoticComponent,
  useCallback,
  useRef,
  useState
} from "react";
import { v4 as uuidv4 } from "uuid";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  Button,
  BUTTON_TYPE,
  Checkbox,
  theme,
  TYPE_NOTIFICATION,
  UpdateIcon
} from "@security-watchdog/sw-ui-kit";

import { IESignatureProps, MODAL_TAB } from "components/eSignatureModal/types";
import { dataURLtoFile } from "src/utils";
import { DesignSystemModal } from "components/DesignSystemModal";
import { Tabs } from "components/Tabs";
import {
  MODAL_TABS,
  SELECT_SIGNATURE_MESSAGE,
  TYPE_SIGNATURE_TEXT_SIZE
} from "./constants";
import {
  DrawSignatureControl,
  TypeSignatureControl,
  UploadSignatureControl
} from "./components";
import { addMessageNtc } from "modules/messages/actions";
import * as s from "./styles";

const ESignatureModalComponent: FC<IESignatureProps> = ({
  onSave,
  ...modalProps
}: IESignatureProps) => {
  const [activeTab, setActiveTab] = useState<MODAL_TAB>(MODAL_TAB.Draw);
  const [isAgreedWithCheckbox, setIsAgreedWithCheckbox] =
    useState<boolean>(false);
  const [uploadSignatureFile, setUploadSignatureFile] = useState<File | null>(
    null
  );

  const dispatch: Dispatch = useDispatch();

  const drawSignatureRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null);

  const typeSignatureInputRef = useRef<HTMLInputElement>(null);

  const getDrawSignatureFile = useCallback((): File | null => {
    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.$(drawSignatureRef.current).jSignature("getData", "native")
        .length === 0
    ) {
      return null;
    }

    const sSignatureFile: Blob = dataURLtoFile(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.$(drawSignatureRef.current).jSignature("getData", "default"),
      "eSignature.png"
    );
    return sSignatureFile as File;
  }, []);

  const getTypeTextSignatureFile = useCallback((): File | null => {
    const signText = typeSignatureInputRef.current?.value || "";
    const canvasHorizontalPadding: number = 100;
    const canvasVerticalPadding: number = 80;
    const canvas = document.createElement("canvas");
    const canvasCtx = canvas?.getContext("2d");

    if (signText && canvasCtx) {
      canvasCtx.font = `bold ${TYPE_SIGNATURE_TEXT_SIZE}px DancingScriptBold`;

      const metrics = canvasCtx.measureText(signText);
      const signTextWidth = Math.round(metrics.width);
      const signTextHeight = TYPE_SIGNATURE_TEXT_SIZE;

      canvas.width = signTextWidth + canvasHorizontalPadding;
      canvas.height = signTextHeight + canvasVerticalPadding;
      canvasCtx.font = `bold ${TYPE_SIGNATURE_TEXT_SIZE}px DancingScriptBold`;
      canvasCtx.fillText(
        signText,
        canvasHorizontalPadding / 2,
        signTextHeight + canvasVerticalPadding / 2
      );

      const imageUrl = canvas.toDataURL("image/png", 1);

      return dataURLtoFile(imageUrl, "eSignature.png") as File;
    }

    return null;
  }, []);

  const resetDrawSignature = useCallback((): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.$(drawSignatureRef.current).jSignature("reset");
  }, []);

  const resetUploadSignature = useCallback((): void => {
    setUploadSignatureFile(null);
  }, []);

  const onCancelModal = useCallback(() => {
    modalProps.onClose();

    setActiveTab(MODAL_TAB.Draw);
    resetUploadSignature();
    setIsAgreedWithCheckbox(false);
  }, [modalProps, resetUploadSignature]);

  const handleSaveSignature = useCallback((): void => {
    let file: File | null;

    switch (activeTab) {
      case MODAL_TAB.Draw:
        file = getDrawSignatureFile();
        break;
      case MODAL_TAB.Type:
        file = getTypeTextSignatureFile();
        break;
      case MODAL_TAB.Upload:
        file = uploadSignatureFile;
        break;
      default:
        file = null;
    }

    if (file) {
      onSave?.(file);
      onCancelModal();
    } else {
      dispatch(
        addMessageNtc({
          id: uuidv4(),
          type: TYPE_NOTIFICATION.Info,
          message: SELECT_SIGNATURE_MESSAGE
        })
      );
    }
  }, [
    uploadSignatureFile,
    activeTab,
    onSave,
    onCancelModal,
    dispatch,
    getTypeTextSignatureFile,
    getDrawSignatureFile
  ]);

  const onClearBtnClick = useCallback((): void => {
    if (activeTab === MODAL_TAB.Draw) {
      resetDrawSignature();
    }
    if (activeTab === MODAL_TAB.Upload) {
      resetUploadSignature();
    }
  }, [activeTab, resetDrawSignature, resetUploadSignature]);

  const onUploadSignatureFile = useCallback((file: File): void => {
    setUploadSignatureFile(file);
  }, []);

  const handleChangeCheckbox = useCallback(
    () => setIsAgreedWithCheckbox((prevState: boolean): boolean => !prevState),
    []
  );

  const onTabChange = useCallback(
    (tab: MODAL_TAB) => {
      setActiveTab(tab);
      resetUploadSignature();
    },
    [resetUploadSignature]
  );

  const renderModalBody = (): JSX.Element | null => {
    switch (activeTab) {
      case MODAL_TAB.Draw:
        return <DrawSignatureControl ref={drawSignatureRef} />;
      case MODAL_TAB.Type:
        return <TypeSignatureControl ref={typeSignatureInputRef} />;
      case MODAL_TAB.Upload:
        return (
          <UploadSignatureControl
            selectedFile={uploadSignatureFile}
            onFileAdd={onUploadSignatureFile}
          />
        );
      default:
        return null;
    }
  };

  const renderModalFooter = (): JSX.Element | null => {
    const withClearBtn =
      MODAL_TAB.Draw === activeTab || MODAL_TAB.Upload === activeTab;

    return (
      <s.FooterContainer $flexDirection={withClearBtn ? "column" : "row"}>
        <s.CheckboxWrapper>
          <Checkbox
            value={isAgreedWithCheckbox}
            onChange={handleChangeCheckbox}
            label="I confirm this is a legal representation of my signature"
          />
        </s.CheckboxWrapper>
        <s.ModalActions>
          {withClearBtn && (
            <s.ClearActionWrapper>
              <Button
                buttonType={BUTTON_TYPE.Link}
                icon={
                  <UpdateIcon
                    color={theme.colors["color-action-primary-default"]}
                    size={16}
                  />
                }
                onClick={onClearBtnClick}
              >
                Clear field
              </Button>
            </s.ClearActionWrapper>
          )}
          <s.MainActions>
            <s.Button
              buttonType={BUTTON_TYPE.Secondary}
              onClick={onCancelModal}
            >
              Cancel
            </s.Button>
            <s.Button
              buttonType={BUTTON_TYPE.Primary}
              onClick={handleSaveSignature}
              isDisabled={!isAgreedWithCheckbox}
            >
              Accept
            </s.Button>
          </s.MainActions>
        </s.ModalActions>
      </s.FooterContainer>
    );
  };

  return (
    <DesignSystemModal showModal={modalProps.isShowModal}>
      <DesignSystemModal.Header title="E-signature" onClose={onCancelModal}>
        <Tabs
          tabs={MODAL_TABS}
          activeTab={activeTab}
          onTabChange={onTabChange}
        />
      </DesignSystemModal.Header>
      <DesignSystemModal.Body>
        <s.BodyContainer>{renderModalBody()}</s.BodyContainer>
      </DesignSystemModal.Body>
      <DesignSystemModal.Footer>{renderModalFooter()}</DesignSystemModal.Footer>
    </DesignSystemModal>
  );
};

export const ESignatureModal: NamedExoticComponent<IESignatureProps> =
  memo<IESignatureProps>(ESignatureModalComponent);
