import { Portal } from "components/Portal";
import { TimerCountDown } from "components/TimerCountDown";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useIdleTimer } from "react-idle-timer";
import { BUTTON_TYPE, FocusTrap } from "@security-watchdog/sw-ui-kit";
import { TIME_OF_IDLE } from "src/constants";
import * as s from "./styles";
import { AuthContext, IAuthContextValueProps } from "src/context/auth";

export const IdleTimerComponent: React.FC<PropsWithChildren> = ({
  children
}: PropsWithChildren) => {
  const { logout }: IAuthContextValueProps =
    useContext<IAuthContextValueProps>(AuthContext);

  const continueBtnRef = useRef<HTMLButtonElement>(null);

  const [isIdle, setIsIdle] = useState<boolean>(false);

  const onIdle = useCallback((): void => {
    setIsIdle(true);
  }, []);

  useEffect(() => {
    if (isIdle) {
      continueBtnRef.current?.focus();
    }
  }, [isIdle]);

  const { reset } = useIdleTimer({
    timeout: TIME_OF_IDLE,
    onIdle
  });

  const handleLogoutButtonClick = (): void => logout?.();

  const stayActive = useCallback(() => {
    reset();
    setIsIdle(false);
  }, [reset]);

  return (
    <>
      {isIdle && (
        <Portal>
          <s.ExtraContainer>
            <FocusTrap isActive>
              <s.ModalContainer
                role="dialog"
                aria-modal
                aria-labelledby="idle-modal-title"
              >
                <s.Header id="idle-modal-title">Are you still here?</s.Header>
                <s.SecondaryText>
                  To keep your details secure, you will automatically be signed
                  out in:
                </s.SecondaryText>
                <TimerCountDown onComplete={handleLogoutButtonClick} />
                <s.ButtonsContainer>
                  <s.CustomButton
                    buttonType={BUTTON_TYPE.Primary}
                    buttonRef={continueBtnRef}
                    onClick={stayActive}
                  >
                    Continue
                  </s.CustomButton>
                  <s.CustomButton
                    buttonType={BUTTON_TYPE.Link}
                    onClick={handleLogoutButtonClick}
                  >
                    Log out now
                  </s.CustomButton>
                </s.ButtonsContainer>
              </s.ModalContainer>
            </FocusTrap>
          </s.ExtraContainer>
        </Portal>
      )}
      {children}
    </>
  );
};
