import React, {
  NamedExoticComponent,
  useCallback,
  useEffect,
  useState
} from "react";
import { BUTTON_TYPE } from "@security-watchdog/sw-ui-kit";
import { useForm, UseFormReturn, FormState } from "react-hook-form";
import {
  IFieldAnswer,
  IFieldValue,
  IFormFields,
  IPartialTroubleShootHistoryRecord
} from "src/types";
import { AnswerField } from "components/common/AnswerField";
import { usePrevious } from "src/hooks/usePrevious";
import {
  CaseTroubleshoootingHistoryType,
  FieldType,
  HandleableFieldType
} from "src/graphQLTypes";
import { isUndefinedOrNull } from "src/utils/typeGuards";
import { updateTroubleShootPartialHistory } from "src/utils/updateTroubleShootPartialHistory";
import { ICheckConditionsProps, IProps } from "./types";
import * as s from "./styles";
import {
  buildFieldKey,
  checkIsFieldVisible,
  getFirstFieldIdAByFields,
  isGeneralUploadField,
  validationResolver
} from "src/utils";

export const AddTimelineEntrySidebarComponent: React.FC<IProps> = ({
  countries,
  title,
  saveButtonName,
  isOpened,
  fields: allFields,
  onSave,
  onClose,
  questionId,
  answerId,
  validationMap,
  isMultipleAnswers,
  saveDraftInProgress,
  isAllFormsDisabled,
  answerIndex
}: IProps) => {
  const [troubleShootHistory, setTroubleShootHistory] = useState<
    IPartialTroubleShootHistoryRecord[]
  >([]);

  const {
    register,
    unregister,
    setValue,
    watch,
    reset,
    formState,
    trigger,
    handleSubmit
  }: UseFormReturn<IFormFields> = useForm<IFormFields>({
    resolver: validationResolver,
    context: validationMap
  });

  const { errors, isSubmitted }: FormState<IFormFields> = formState;

  const [fields, setFields] = useState<IFieldAnswer[]>([]);

  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);

  const prevIsOpen: boolean | undefined = usePrevious(isOpened);

  useEffect(() => {
    if (isOpened && isOpened !== prevIsOpen) {
      setFields(
        allFields.map((field: IFieldAnswer) => ({ ...field, answerIndex }))
      );
      reset();
    }
  }, [allFields, isOpened, reset, prevIsOpen, answerIndex]);

  const formValues = watch();

  useEffect((): void => {
    if (isOpened) {
      setTroubleShootHistory(() =>
        fields.map((field: IFieldAnswer) => ({
          type: CaseTroubleshoootingHistoryType.FIELD_VALUE_UPDATED,
          answerId,
          isDeleted: false,
          fieldId: field.fieldId,
          index: answerIndex,
          from: field.value,
          to: undefined,
          fieldType: field.type,
          isMulti: field.isMulti
        }))
      );
    } else {
      setTroubleShootHistory([]);
    }
  }, [answerId, answerIndex, fields, isOpened]);

  const checkConditions = useCallback(
    ({
      fieldKey = "",
      value = "",
      unregisteredFields = []
    }: ICheckConditionsProps) => {
      setFields((prevFields: IFieldAnswer[]) =>
        prevFields.map((field: IFieldAnswer) => ({
          ...field,
          isVisible: checkIsFieldVisible({
            conditions: field.conditions,
            questionId,
            answerId,
            value,
            fieldKey,
            formValues,
            unregisteredFields
          })
        }))
      );
    },
    [formValues, questionId, answerId]
  );

  const checkIsCurrentEntity = useCallback(
    (field: IFieldAnswer): boolean =>
      !isMultipleAnswers &&
      (field.fieldType === HandleableFieldType.CURRENT_ADDRESS ||
        field.fieldType === HandleableFieldType.CURRENT_EMPLOYER),
    [isMultipleAnswers]
  );

  useEffect(() => {
    if (isOpened) {
      const unregisteredFields: string[] = [];
      fields.forEach((field: IFieldAnswer) => {
        if (isGeneralUploadField(field.type)) {
          return;
        }
        const fieldKey = buildFieldKey({
          questionId,
          answerId,
          fieldId: field.fieldId,
          type: field.type,
          fieldType: field.fieldType,
          isMulti: field.isMulti
        });
        const isRegistered = Object.keys(formValues).some(
          (key: string) => key === fieldKey
        );
        if (field.isVisible && !isRegistered) {
          register(fieldKey);

          if (checkIsCurrentEntity(field)) {
            setValue(fieldKey, true);
          }

          if (!isUndefinedOrNull(field.value)) {
            setValue(fieldKey, field.value);
          }
        }
        if (!field.isVisible && isRegistered) {
          unregister(fieldKey);
          if (field.hasDependentFields) {
            unregisteredFields.push(fieldKey);
          }
        }
      });
      if (unregisteredFields.length) {
        checkConditions({ unregisteredFields });
      }
    }
  }, [
    setValue,
    register,
    fields,
    isOpened,
    questionId,
    answerId,
    unregister,
    formValues,
    checkConditions,
    isMultipleAnswers,
    checkIsCurrentEntity
  ]);

  useEffect(() => {
    if (isSubmitClicked && Object.keys(errors).length) {
      const elementId: string = getFirstFieldIdAByFields(
        fields,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors as any,
        questionId,
        answerId
      );
      const element = document.getElementById(elementId);
      element?.scrollIntoView();
      setIsSubmitClicked(false);
    }
  }, [errors, isSubmitClicked, fields, questionId, answerId]);

  const handleSetValue = useCallback(
    (
      hasDependentFields: boolean,
      hasGapRestriction: boolean,
      fieldKey: string,
      value: IFieldValue,
      answerIndex: number
    ) => {
      setValue(fieldKey, value);

      if (isSubmitted) {
        trigger();
      }
      if (hasDependentFields) {
        checkConditions({ fieldKey, value });
      }

      setTroubleShootHistory(
        (historyRecords: IPartialTroubleShootHistoryRecord[]) =>
          updateTroubleShootPartialHistory({
            fieldKey,
            value,
            answerIndex,
            historyRecords
          })
      );
    },
    [setValue, isSubmitted, trigger, checkConditions]
  );

  const checkIsCurrent = useCallback(
    (field: IFieldAnswer): boolean => {
      if (
        field.type === FieldType.DATE_RANGE &&
        field.validation.dependentField &&
        field.validation.dependentField.type === FieldType.BOOLEAN
      ) {
        const dependentFieldKey = buildFieldKey({
          questionId,
          answerId,
          fieldId: field.validation.dependentField.fieldId,
          type: field.validation.dependentField.type,
          fieldType: field.validation.dependentField.fieldType,
          isMulti: field.validation.isMulti
        });

        return formValues[dependentFieldKey];
      }
      return false;
    },
    [questionId, answerId, formValues]
  );

  const getFieldValueForSave = useCallback(
    (value: IFieldValue, field: IFieldAnswer) => {
      if (
        field.type === FieldType.DATE_RANGE &&
        field.validation.dependentField &&
        field.validation.dependentField.type === FieldType.BOOLEAN &&
        checkIsCurrent(field)
      ) {
        return {
          ...value,
          to: undefined
        };
      }
      return value;
    },
    [checkIsCurrent]
  );

  const onSubmit = useCallback(
    (
      data: IFormFields,
      troubleShootingHistory: IPartialTroubleShootHistoryRecord[]
    ) => {
      const fieldsWithValue: IFieldAnswer[] = fields.map(
        (field: IFieldAnswer) => {
          const fieldKey = buildFieldKey({
            questionId,
            answerId,
            fieldId: field.fieldId,
            type: field.type,
            fieldType: field.fieldType,
            isMulti: field.isMulti
          });
          return {
            ...field,
            value: isGeneralUploadField(field.type)
              ? field.value
              : getFieldValueForSave(data[fieldKey], field)
          };
        }
      );

      onSave(fieldsWithValue, troubleShootingHistory);
    },
    [fields, onSave, questionId, answerId, getFieldValueForSave]
  );

  const handleSubmitClick = useCallback(() => {
    setIsSubmitClicked(true);
    return handleSubmit((data: IFormFields) =>
      onSubmit(data, troubleShootHistory)
    )();
  }, [handleSubmit, onSubmit, troubleShootHistory]);

  const getValue = useCallback(
    (field: IFieldAnswer) => {
      const fieldKey = buildFieldKey({
        questionId,
        answerId,
        fieldId: field.fieldId,
        type: field.type,
        fieldType: field.fieldType,
        isMulti: field.isMulti
      });
      return formValues[fieldKey];
    },
    [formValues, questionId, answerId]
  );

  const getErrorMessage = useCallback(
    (field: IFieldAnswer): string => {
      const fieldKey = buildFieldKey({
        questionId,
        answerId,
        fieldId: field.fieldId,
        type: field.type,
        fieldType: field.fieldType,
        isMulti: field.isMulti
      });
      return errors[fieldKey] && errors[fieldKey]?.message
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (errors[fieldKey]?.message as any)
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (errors[fieldKey] as any);
    },
    [errors, questionId, answerId]
  );

  return (
    <s.Sidebar
      onClose={onClose}
      withCloseIcon
      header={<s.Heading>{title}</s.Heading>}
      isOpened={isOpened}
      ariaLabel={title}
      actionButtons={
        <s.AddButton
          buttonType={BUTTON_TYPE.Primary}
          onClick={handleSubmitClick}
          isLoading={saveDraftInProgress}
        >
          {saveButtonName}
        </s.AddButton>
      }
    >
      <s.Form>
        {fields
          .filter((field: IFieldAnswer) => field.isVisible)
          .map((field: IFieldAnswer) => (
            <AnswerField
              isDisabled={isAllFormsDisabled}
              key={field.fieldId}
              field={field}
              isCurrent={checkIsCurrent(field)}
              questionId={questionId}
              answerId={answerId}
              value={getValue(field)}
              errorMessage={getErrorMessage(field)}
              onChange={handleSetValue}
              countryOptions={countries}
              isCurrentEntity={checkIsCurrentEntity(field)}
            />
          ))}
      </s.Form>
    </s.Sidebar>
  );
};

export const AddTimelineEntrySidebar: NamedExoticComponent<IProps> =
  React.memo<IProps>(AddTimelineEntrySidebarComponent);
