import { ButtonBack } from "components/ButtonBack";
import { PageHeader } from "components/PageHeader";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "@security-watchdog/ui-components";
import { AcceptableDocumentsLink } from "components/common/AcceptableDocumentsLink";
import { ROUTES } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import { takeHelpInfo, takeLogoVisibilityStatus } from "modules/case/selectors";
import { getCase_screeningCase_contract_support } from "src/graphQLTypes";
import { ContentLayout } from "components/ContentLayout";
import { Header as CommonHeader } from "components/Header";
import { useScrollToTop } from "src/hooks/useScrollToTop";
import { Dispatch } from "redux";
import * as actions from "modules/case/actions";
import { ScreenerAccess } from "containers/ScreenerAccess";
import * as s from "./styles";
import googleAnalytics, { IUseGA } from "src/lib/googleAnalytics";
import { MetaData } from "components/MetaData";

const HelpComponent: React.FC = () => {
  const { logPageView }: IUseGA = googleAnalytics();

  const dispatch: Dispatch = useDispatch();

  useScrollToTop();

  const navigateTo = useNavigate();

  const isLogoVisible: boolean = useSelector(takeLogoVisibilityStatus);

  const goToBack = useCallback(
    () => navigateTo({ pathname: ROUTES.ROOT, search: window.location.search }),
    [navigateTo]
  );

  const supportInfo: getCase_screeningCase_contract_support | undefined =
    useSelector(takeHelpInfo);

  useEffect(() => {
    logPageView(window.location.pathname);

    if (!isLogoVisible) {
      dispatch(actions.changeLogoVisibility(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaData title="Help" />

      <CommonHeader />

      <ScreenerAccess />

      <ContentLayout>
        <ButtonBack handlerClick={goToBack}>Back</ButtonBack>
        <PageHeader
          title="Help"
          description="We are here to provide support and more information if you need assistance during your application process"
        />
        <s.Content>
          <s.ContainerBox aria-labelledby="help-page-contact-title">
            <s.Title id="help-page-contact-title">Contact us</s.Title>
            <s.ContainerBoxInfo>
              <s.BoxInfo>
                <s.PrimaryTextInfo>Email</s.PrimaryTextInfo>
                <Link to={`mailto:${supportInfo?.supportEmailAddress}`}>
                  {supportInfo?.supportEmailAddress || "-"}
                </Link>
              </s.BoxInfo>
              <s.Line />
              <s.BoxInfo>
                <s.PrimaryTextInfo>Support</s.PrimaryTextInfo>
                <Link to={`tel:${supportInfo?.supportPhoneNumber}`}>
                  {supportInfo?.supportPhoneNumber || "-"}
                </Link>
              </s.BoxInfo>
            </s.ContainerBoxInfo>
            <s.Title>Opening hours</s.Title>
            <s.ContainerBoxInfo>
              <s.BoxInfo>
                <s.PrimaryTextInfo>Weekdays</s.PrimaryTextInfo>
                <s.SecondaryTextInfo className="body-wide-line">
                  8&#58;00 AM - 8&#58;00 PM
                </s.SecondaryTextInfo>
              </s.BoxInfo>
              <s.Line />
              <s.BoxInfo>
                <s.PrimaryTextInfo>Weekend</s.PrimaryTextInfo>
                <s.SecondaryTextInfo className="body-wide-line">
                  Closed
                </s.SecondaryTextInfo>
              </s.BoxInfo>
            </s.ContainerBoxInfo>
          </s.ContainerBox>
          <s.ContainerBox>
            <span className="body-wide-line margin-r-2">
              Unsure of the documents to provide? Click here for
            </span>
            <AcceptableDocumentsLink>
              a list of acceptable documents
            </AcceptableDocumentsLink>
          </s.ContainerBox>
        </s.Content>
      </ContentLayout>
    </>
  );
};

export const Help = React.memo(HelpComponent);

export default Help;
