import styled from "styled-components";
import {
  Button,
  IButtonProps,
  getTypographyStyles,
  Sidebar as SidebarComponent
} from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const Sidebar = styled(SidebarComponent)`
  @media (max-width: ${DEVICE.MOBILE_L}) {
    width: 100%;
  }
  @media (max-width: ${DEVICE.MOBILE_M}) {
    max-height: 100%;
    overflow-y: auto;
  }
`;

export const Heading = styled.h2`
  margin: 0;
  ${getTypographyStyles({ variant: "h2" })};
`;

export const Form = styled.div`
  color: ${({ theme }) => theme.colors["color-text-default"]};
  padding: 40px;
  overflow: auto;
  flex-grow: 1;
  flex-basis: 0;
  @media (max-width: ${DEVICE.MOBILE_M}) {
    overflow: unset;
  }
`;

export const AddButton = styled(Button)<IButtonProps>`
  justify-content: center;
  flex-grow: 1;
`;
