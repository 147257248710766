import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: ${({ theme }) => theme.colors["color-surface-subdued-default"]};
  border-radius: 4px;
  overflow: hidden;
`;

export const DropzoneWrapper = styled.div`
  max-width: 360px;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const ErrorMessage = styled.div`
  margin-left: 16px;
  ${getTypographyStyles({ variant: "body-wide-line" })};
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  margin-bottom: 24px;
  background: ${({ theme }) => theme.colors["color-surface-critical-default"]};
  border-radius: 4px;
`;
