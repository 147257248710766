import React from "react";
import { SpinIcon, theme } from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";

const SpinnerComponent: React.FC = () => (
  <s.SpinnerContainer>
    <SpinIcon color={theme.colors["color-icon-active"]} />
  </s.SpinnerContainer>
);

export const Spinner = React.memo(SpinnerComponent);
