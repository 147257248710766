import styled from "styled-components";
import {
  getTypographyStyles,
  Sidebar as SidebarComponent,
  DocumentSmallIcon
} from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const Sidebar = styled(SidebarComponent)`
  @media (max-width: ${DEVICE.MOBILE_L}) {
    width: 100%;
  }
`;

export const DocumentIcon = styled(DocumentSmallIcon)`
  margin-right: 10px;
`;

export const Heading = styled.h2`
  margin: 0;
  ${getTypographyStyles({ variant: "h2" })};
`;

export const ContentContainer = styled.div`
  padding: 28px 40px;
`;

export const FieldContainer = styled.div`
  padding-bottom: 18px;
  margin-bottom: 20px;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors["color-divider-default"]}`};
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export const FieldTitle = styled.div`
  ${getTypographyStyles({
    variant: "badge",
    color: "color-text-subdued"
  })};
  margin-bottom: 3px;
`;

export const FieldValue = styled.div`
  ${getTypographyStyles({ variant: "body-wide-line" })};
`;

export const FileContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const FileName = styled.div`
  ${getTypographyStyles({ variant: "body-wide-line" })};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  flex-grow: 1;
  flex-basis: 0;
`;
