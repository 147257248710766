import React, {
  FC,
  memo,
  NamedExoticComponent,
  ReactNode,
  useMemo
} from "react";
import { ISectionStatusProps } from "components/common/SectionStatus/types";
import { StatusCard } from "components/StatusCard";
import { DefaultTheme, useTheme } from "styled-components";
import { IDraftStatuses } from "modules/case/types";
import {
  AlertCircleIcon,
  CheckRoundedIcon,
  SMALL_ICON_SIZE,
  UpdateIcon
} from "@security-watchdog/sw-ui-kit";
import { DRAFT_STATUS_TEXT } from "src/constants";
import * as s from "./styles";

const SectionStatusComponent: FC<ISectionStatusProps> = ({
  sectionStatus,
  saveDraftStatus,
  role
}: ISectionStatusProps) => {
  const theme: DefaultTheme = useTheme();

  const draftStatusIcon: ReactNode = useMemo<ReactNode>((): ReactNode => {
    const color = theme.colors["color-icon-default"];

    switch (saveDraftStatus) {
      case IDraftStatuses.SAVING:
        return <UpdateIcon color={color} size={SMALL_ICON_SIZE} />;
      case IDraftStatuses.SAVED:
        return <CheckRoundedIcon color={color} size={SMALL_ICON_SIZE} />;
      case IDraftStatuses.NO_CHANGES:
      case IDraftStatuses.UNSAVED_CHANGES:
        return <AlertCircleIcon color={color} size={SMALL_ICON_SIZE} />;
      default:
        return null;
    }
  }, [saveDraftStatus, theme.colors]);

  return (
    <s.StatusesContainer className="section-status">
      <StatusCard title={sectionStatus} status={sectionStatus} />
      {saveDraftStatus ? (
        <s.DraftValueStatusContainer>
          {draftStatusIcon}
          <span
            role={role}
            aria-label={`Form draft status changed to ${DRAFT_STATUS_TEXT[saveDraftStatus]}`}
          >
            {DRAFT_STATUS_TEXT[saveDraftStatus]}
          </span>
        </s.DraftValueStatusContainer>
      ) : null}
    </s.StatusesContainer>
  );
};

export const SectionStatus: NamedExoticComponent<ISectionStatusProps> =
  memo<ISectionStatusProps>(SectionStatusComponent);
