import React from "react";
import { Helmet } from "react-helmet-async";

interface IMetaDataProps {
  title?: string;
}

export const MetaData: React.FC<IMetaDataProps> = ({ title }) => (
  <Helmet>
    <title>
      {title
        ? `${title} - Matrix Security Watchdog`
        : "Matrix Security Watchdog"}
    </title>
  </Helmet>
);
