import React, { FC } from "react";

import { Link } from "./styles";

type AccessibilitySkipLinkProps = {
  name?: string;
  skipTo: string;
};

export const AccessibilitySkipLink: FC<AccessibilitySkipLinkProps> = ({
  name = "skip navigation",
  skipTo
}) => {
  return <Link href={skipTo}>{name}</Link>;
};
