import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  IButtonProps,
  theme
} from "@security-watchdog/sw-ui-kit";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.colors["color-surface-default"]};
  padding: 24px 32px;
  border-radius: 4px;
`;
export const Header = styled.div`
  ${getTypographyStyles({ variant: "h3", color: "color-text-on-surface" })};
  margin-bottom: 15px;
`;
export const BodyModal = styled.div`
  width: 240px;
  text-align: center;
  ${getTypographyStyles({ variant: "body-wide-line" })};
  margin-bottom: 25px;
`;
export const ButtonsWr = styled.div`
  display: flex;
`;

export const SubmitButton = styled(Button)<IButtonProps>`
  min-width: 184px;
  justify-content: center;
  margin-right: 16px;
`;
export const CancelButton = styled(Button)<IButtonProps>`
  width: 120px;
  justify-content: center;
`;
