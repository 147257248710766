import React, {
  ChangeEvent,
  NamedExoticComponent,
  useCallback,
  useMemo
} from "react";
import {
  getTextWithParsedLinks,
  Button,
  Icon
} from "@security-watchdog/ui-components";
import {
  Dropdown,
  DropDownOnChange,
  Input
} from "@security-watchdog/sw-ui-kit";

import {
  getInputFieldType,
  isAddressField,
  isBooleanField,
  isCompanyField,
  isCountryField,
  isDateField,
  isDateRangeField,
  isInputField,
  isMultipleStingField,
  isPhoneNumberInputFormField,
  isNumericInputFormField,
  isQualificationAndGradesField,
  isSelectField,
  getInputAutoCompleteType
} from "components/common/AnswerField/utils/detectFieldType";
import { AddressField } from "components/common/AddressField";
import { CompanyField } from "components/common/CompanyField";
import { DateRangeField } from "components/common/DateRangeField";
import { AddAnswerButton } from "components/common/AddAnswerButton";
import { QualificationAndGradesField } from "components/common/QualificationAndGradesField";
import { OCRHighlightField } from "components/common/OCRHighlightField";
import { IFieldValue } from "src/types";
import { Countries_countries, FieldType } from "src/graphQLTypes";
import { buildFieldKey, formatId } from "src/utils";
import { IProps } from "./types";
import * as s from "./styles";
import { useDeviceType } from "src/hooks/useDeviceType";
import { getValidatedPhoneNumber } from "./utils/getValidatedPhoneNumber";
import { INTEGER_VALIDATION_PATTERN } from "src/constants/form.validation";

const radioGroupOptions = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" }
];

export const AnswerFieldComponent: React.FC<IProps> = ({
  value,
  field,
  questionId,
  answerId,
  onChange,
  countryOptions = [],
  errorMessage,
  isCurrent = false,
  isCurrentEntity,
  isDisabled,
  isOcrUploaded
}: IProps) => {
  const { isMobile } = useDeviceType();

  const handleChangeValue = useCallback(
    (value: IFieldValue): void => {
      const fieldKey: string = buildFieldKey({
        questionId,
        answerId,
        fieldId: field.fieldId,
        type: field.type,
        fieldType: field.fieldType,
        isMulti: field.isMulti
      });
      const hasGapRestriction: boolean = Boolean(
        field.type === FieldType.DATE_RANGE && field.validation.gapRestriction
      );

      const isScotlandLocation = field.isRoleLocationScotland;

      onChange(
        field.hasDependentFields,
        hasGapRestriction,
        fieldKey,
        value,
        field.answerIndex || 0,
        isScotlandLocation
      );
    },
    [
      questionId,
      answerId,
      field.fieldId,
      field.type,
      field.isMulti,
      field.validation.gapRestriction,
      field.isRoleLocationScotland,
      field.hasDependentFields,
      field.answerIndex,
      onChange
    ]
  );

  const handleChangeBoolean = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      handleChangeValue(e.target.value === "true"),
    [handleChangeValue]
  );

  const handleChangeSelect = useCallback(
    (value: DropDownOnChange<string>) => handleChangeValue(value),
    [handleChangeValue]
  );

  const handleChangeCountry = useCallback(
    (value: DropDownOnChange<Countries_countries>) => handleChangeValue(value),
    [handleChangeValue]
  );

  const handleChangeInput = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      fieldType: FieldType
    ) => {
      const value = e.target.value;

      if (isPhoneNumberInputFormField(fieldType)) {
        handleChangeValue(getValidatedPhoneNumber(value));
      } else if (isNumericInputFormField(fieldType)) {
        if (INTEGER_VALIDATION_PATTERN.test(value) || !value) {
          handleChangeValue(value);
        }
      } else {
        handleChangeValue(value);
      }
    },
    [handleChangeValue]
  );

  const handleChangeDate = useCallback(
    (date: Date | null) => handleChangeValue(date),
    [handleChangeValue]
  );

  const handleChangeOther = useCallback(
    (value: IFieldValue) => handleChangeValue(value),
    [handleChangeValue]
  );

  const fieldId: string = useMemo(
    () => formatId(`${answerId}${field.fieldId}`),
    [answerId, field.fieldId]
  );

  const multipleStringValue = useMemo(
    () => (Array.isArray(value) && value.length !== 0 ? value : [""]),
    [value]
  );

  const handleAddMultipleStringValue = useCallback(
    () => handleChangeValue([...multipleStringValue, ""]),
    [multipleStringValue, handleChangeValue]
  );

  const handleRemoveMultiStringValue = useCallback(
    (index: number) => (): void => {
      const values = [...multipleStringValue];

      values.splice(index, 1);

      handleChangeValue(values);
    },
    [handleChangeValue, multipleStringValue]
  );

  const handleChangeMultiStringValue = useCallback(
    (i: number) =>
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const newValue: string[] =
          Array.isArray(value) && value.length
            ? value.map((multiStringValue: string, index: number) => {
                if (i === index) {
                  return e.target.value;
                }

                return multiStringValue;
              })
            : [e.target.value];

        handleChangeValue(newValue);
      },
    [handleChangeValue, value]
  );

  const onPhonePaste = useCallback(
    (value: string, e: React.ClipboardEvent<HTMLInputElement>): void => {
      e.preventDefault();
      handleChangeValue(getValidatedPhoneNumber(value));
    },
    [handleChangeValue]
  );

  const isHighlightedField = Boolean(
    isOcrUploaded && field.isHighlightedWhenOcrUploaded
  );

  const parsedFieldTitle = (
    <div
      dangerouslySetInnerHTML={{ __html: getTextWithParsedLinks(field.title) }}
    />
  );

  return (
    <>
      {isInputField(field.type) && field.type === FieldType.STRING && (
        <s.Field
          label={parsedFieldTitle}
          id={fieldId}
          isHighlightedField={isHighlightedField}
        >
          <s.TextArea
            isDisabled={isDisabled}
            isRequired={field.validation.isRequired}
            id={fieldId}
            placeholder={field.validation.isRequired ? "" : "Optional"}
            value={value || ""}
            onChange={(e) => handleChangeInput(e, field.type)}
            errorMessage={errorMessage}
          />
        </s.Field>
      )}

      {isInputField(field.type) && field.type !== FieldType.STRING && (
        <s.Field
          label={parsedFieldTitle}
          id={fieldId}
          isHighlightedField={isHighlightedField}
        >
          <Input
            autoComplete={getInputAutoCompleteType(field.type)}
            isDisabled={isDisabled}
            isRequired={field.validation.isRequired}
            inputId={fieldId}
            placeholder={field.validation.isRequired ? "" : "Optional"}
            value={value || ""}
            type={getInputFieldType(field.type)}
            onChange={(e) => handleChangeInput(e, field.type)}
            onPaste={
              isPhoneNumberInputFormField(field.type) ? onPhonePaste : undefined
            }
            errorMessage={errorMessage}
          />
        </s.Field>
      )}

      {isQualificationAndGradesField(field.type) && (
        <QualificationAndGradesField
          isDisabled={isDisabled}
          field={field}
          questionId={questionId}
          answerId={answerId}
          value={value}
          handleChangeValue={handleChangeValue}
          errorMessage={errorMessage}
          fieldId={fieldId}
        />
      )}
      {isMultipleStingField(field.type) && (
        <s.FieldGroup
          label={parsedFieldTitle}
          id={fieldId}
          dataTestId={field.title}
          isHighlightedField={isHighlightedField}
        >
          <div role="group" aria-labelledby={fieldId}>
            {multipleStringValue.map((itemValue: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <s.ArrayFieldContainer key={`${field.fieldId}${index}`}>
                <s.Input
                  isDisabled={isDisabled}
                  isRequired={field.validation.isRequired}
                  inputId={`${fieldId}-${index}`}
                  ariaLabel={`item ${index + 1}`}
                  placeholder={field.validation.isRequired ? "" : "Optional"}
                  value={itemValue || ""}
                  onChange={handleChangeMultiStringValue(index)}
                  errorMessage={
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    // @ts-ignore
                    errorMessage ? errorMessage[index]?.message : ""
                  }
                />
                {index === 0 ? null : (
                  <Button
                    variant="ghost-critical"
                    onClick={handleRemoveMultiStringValue(index)}
                    dataTestId="delete-btn"
                    aria-label={`Remove item ${index + 1}`}
                  >
                    <Icon name="bin" size={16} />
                  </Button>
                )}
              </s.ArrayFieldContainer>
            ))}
          </div>
          <AddAnswerButton
            onClick={handleAddMultipleStringValue}
            isDisabled={!multipleStringValue.length || isDisabled}
          />
        </s.FieldGroup>
      )}
      {isBooleanField(field.type) && (
        <s.FieldGroup
          label={parsedFieldTitle}
          id={fieldId}
          dataTestId={field.title}
          isHighlightedField={isHighlightedField}
        >
          <s.RadioGroup
            isDisabled={isCurrentEntity || isDisabled}
            isRequired={field.validation.isRequired}
            name={fieldId}
            value={String(value)}
            options={radioGroupOptions}
            onChange={handleChangeBoolean}
            errorMessage={errorMessage}
          />
        </s.FieldGroup>
      )}
      {isSelectField(field.type) && (
        <s.Field
          label={parsedFieldTitle}
          id={fieldId}
          isHighlightedField={isHighlightedField}
        >
          <Dropdown<string>
            isSearchable
            isDisabled={isDisabled}
            isRequired={field.validation.isRequired}
            id={fieldId}
            isMulti={field.isMulti}
            value={field.isMulti ? value || [] : value}
            onChange={handleChangeSelect}
            options={field.options || []}
            errorMessage={errorMessage}
            popoverPositionFixed={false}
          />
        </s.Field>
      )}
      {isCountryField(field.type) && (
        <s.Field
          label={parsedFieldTitle}
          id={fieldId}
          isHighlightedField={isHighlightedField}
        >
          <Dropdown<Countries_countries>
            id={fieldId}
            isDisabled={isDisabled}
            isRequired={field.validation.isRequired}
            isSearchable
            value={value}
            onChange={handleChangeCountry}
            options={countryOptions}
            errorMessage={errorMessage}
            popoverPositionFixed={false}
          />
        </s.Field>
      )}
      {isDateField(field.type) && (
        <s.Field
          label={parsedFieldTitle}
          id={fieldId}
          isHighlightedField={isHighlightedField}
        >
          <s.DatePicker
            isMobile={isMobile}
            isDisabled={isDisabled}
            id={fieldId}
            value={value}
            isRequired={field.validation.isRequired}
            onChange={handleChangeDate}
            errorMessage={errorMessage}
            popoverPositionFixed={false}
          />
        </s.Field>
      )}
      {isDateRangeField(field.type) && (
        <DateRangeField
          isMobile={isMobile}
          isDisabled={isDisabled}
          isRequired={field.validation.isRequired}
          id={fieldId}
          title={parsedFieldTitle}
          value={value}
          isCurrent={isCurrent}
          onChange={handleChangeOther}
          errorMessage={errorMessage}
        />
      )}
      {isAddressField(field.type) && (
        <AddressField
          isDisabled={isDisabled}
          id={fieldId}
          countries={countryOptions}
          value={value}
          onChange={handleChangeOther}
          errorMessage={errorMessage}
        />
      )}
      {isCompanyField(field.type) && (
        <CompanyField
          isDisabled={isDisabled}
          id={fieldId}
          countries={countryOptions}
          value={value}
          onChange={handleChangeOther}
          errorMessage={errorMessage}
        />
      )}
      {isHighlightedField && (
        <OCRHighlightField isHighlightedField={isHighlightedField} />
      )}
    </>
  );
};

export const AnswerField: NamedExoticComponent<IProps> =
  React.memo<IProps>(AnswerFieldComponent);
