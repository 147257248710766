import React, { ReactElement } from "react";
import {
  CheckOutsideCircleIcon,
  theme,
  BUTTON_TYPE
} from "@security-watchdog/sw-ui-kit";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import * as actions from "modules/case/actions";
import * as s from "./styles";

export const DocumentsSubmitSuccess: React.FC = (): ReactElement => {
  const dispatch: Dispatch = useDispatch();

  const closeDocumentsSubmitSuccessMessage = (): void => {
    dispatch(actions.setIsAdditionalDocumetsSubmitted(false));
  };

  return (
    <s.TitleContainer>
      <s.TitleDescription>
        <CheckOutsideCircleIcon
          color={theme.colors["color-icon-on-primary"]}
          size={24}
        />
        <s.Title>
          Your documents were successfully uploaded. Please click
          &apos;Additional Documents&apos; to add more
        </s.Title>
      </s.TitleDescription>
      <s.StyledButton
        buttonType={BUTTON_TYPE.Secondary}
        ariaLabel="Close"
        onClick={closeDocumentsSubmitSuccessMessage}
      >
        <s.StyledCloseIcon
          color={theme.colors["color-icon-on-primary"]}
          size={24}
        />
      </s.StyledButton>
    </s.TitleContainer>
  );
};
