import styled from "styled-components";
import {
  getTypographyStyles,
  CheckOutsideCircleIcon as CheckOutsideCircleIconComponent,
  IIconProps,
  Button
} from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const Container = styled.section`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors["color-surface-default"]};
  margin-bottom: 8px;
  box-sizing: border-box;
  padding-bottom: 24px;
`;

export const QuestionHeader = styled.div`
  padding: 24px;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors["color-divider-default"]}`};
  margin-bottom: 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${DEVICE.TABLET}) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  ${getTypographyStyles({ variant: "body-highlight" })};

  @media (max-width: ${DEVICE.TABLET}) {
    order: 1;
    margin-top: 2px;
  }
`;

export const Description = styled.div`
  white-space: break-spaces;
  overflow-wrap: break-word;
  ${getTypographyStyles({
    color: "color-text-subdued",
    variant: "body-wide-line"
  })}
`;

export const ContentContainer = styled.div`
  padding: 0 23px;
`;

export const ContainerInfo = styled.div`
  margin-bottom: 25px;
`;

export const SecondaryText = styled.div`
  margin-left: 40px;
  overflow-wrap: break-word;
  ${getTypographyStyles({
    color: "color-text-subdued",
    variant: "body-wide-line"
  })}
`;

export const DeclarationText = styled(SecondaryText)`
  white-space: break-spaces;
  word-break: break-word;
`;

export const ESignatureRootContainer = styled.div`
  display: flex;
  padding: 0 24px;
`;

export const ESignatureContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #f8f8fc;
  border: 4px;
  justify-content: space-between;
  height: 56px;
  align-items: center;
  padding: 0 16px;
`;

export const ESignatureText = styled.div`
  ${getTypographyStyles({
    variant: "body-wide-line",
    color: "color-text-default"
  })};
  display: flex;
  align-items: center;
`;

export const CheckOutsideCircleIcon = styled(
  CheckOutsideCircleIconComponent
)<IIconProps>`
  margin-right: 9px;
`;

export const ESignatureDeleteButton = styled(Button)`
  flex-shrink: 0;
`;
