import { DEVICE } from "src/constants";
import styled from "styled-components";

export const Container = styled.div`
  margin: auto;
  width: 100%;
  box-sizing: border-box;
`;

export const Wrapper = styled.section`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: var(--border-radius-default);
  background: var(--color-surface-default);
  padding: var(--unit-8) var(--unit-6);
`;

export const CandidateInfoBlockContainer = styled.div`
  display: flex;
  border-radius: var(--border-radius-default);
  border: 1px solid var(--color-stroke-default);
  padding: var(--unit-6);
`;

export const CandidateInfoBlockContainerColumn = styled.div`
  gap: var(--unit-4);
  margin-right: var(--unit-6);
  display: flex;
  flex-direction: column;
`;

export const ContactsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
    align-items: normal;
    gap: 0;
  }
`;

export const ContactsDivider = styled.span`
  @media (max-width: ${DEVICE.TABLET}) {
    display: none;
  }
`;
