import React, { FC, useCallback, useMemo } from "react";
import {
  FormControl,
  getTextWithParsedLinks,
  TextArea,
  UploadedFilesList
} from "@security-watchdog/ui-components";
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormTrigger
} from "react-hook-form";

import { attachmentFragment } from "src/graphQLTypes";
import { downloadFile } from "src/utils";
import {
  IFormFields,
  FORM_FIELDS
} from "containers/SuccessSubmit/hooks/useProductActionAnswerForm";
import * as s from "./styles";
import { UploadFileViewer } from "components/UploadFileViewer/UploadFileViewer";
import { getFormFilesError } from "src/utils/validateUploadedFiles";
import { getDropzoneUploadedFiles } from "src/utils/attachments";

type ProductActionAnswerFormProps = {
  messageFromScreener: string;
  attachmentsFromScreener: attachmentFragment[];
  candidateCanAttachFiles: boolean;
  formValues: IFormFields;
  formErrors: FieldErrors<IFormFields>;
  formTrigger: UseFormTrigger<IFormFields>;
  formControl: Control<IFormFields>;
};

export const ProductActionAnswerForm: FC<ProductActionAnswerFormProps> = ({
  messageFromScreener,
  attachmentsFromScreener,
  candidateCanAttachFiles,
  formTrigger,
  formErrors,
  formValues,
  formControl
}) => {
  const filesError = useMemo(
    () => getFormFilesError(formErrors, FORM_FIELDS.ATTACHMENTS),
    [formErrors.attachments]
  );

  const uploadedFiles = useMemo(
    () => getDropzoneUploadedFiles(formValues.attachments || []),
    [formValues.attachments]
  );

  const attachmentsControls = useFieldArray({
    control: formControl,
    name: FORM_FIELDS.ATTACHMENTS as never
  });

  const handleDownloadFile = useCallback(
    (attachment: attachmentFragment): void => {
      void downloadFile(attachment);
    },
    []
  );

  const handleAddFiles = useCallback(
    (files: File[]): void => {
      attachmentsControls.append(files);
      void formTrigger(FORM_FIELDS.ATTACHMENTS);
    },
    [attachmentsControls, formTrigger]
  );

  const handleDeleteFile = useCallback(
    (_, itemIndex: number) => {
      attachmentsControls.remove(itemIndex);
      void formTrigger(FORM_FIELDS.ATTACHMENTS);
    },
    [attachmentsControls, formTrigger]
  );

  const onAttachmentClick = useCallback(
    (fileId: string) => {
      const attachment = attachmentsFromScreener.find((i) => i.id === fileId);

      if (attachment) {
        handleDownloadFile(attachment);
      }
    },
    [handleDownloadFile, attachmentsFromScreener]
  );

  return (
    <>
      <s.ScreenerInfo>
        <span className="body-highlight">Note from the screener</span>
        <s.NoteContainer>
          <div
            className="body-default"
            dangerouslySetInnerHTML={{
              __html: getTextWithParsedLinks(messageFromScreener)
            }}
          />
        </s.NoteContainer>
        {attachmentsFromScreener.length ? (
          <s.ScreenerAttachments>
            <UploadedFilesList
              files={attachmentsFromScreener}
              onFileClick={onAttachmentClick}
            />
          </s.ScreenerAttachments>
        ) : null}
      </s.ScreenerInfo>
      <s.Container>
        <FormControl
          control={formControl}
          name={FORM_FIELDS.DESCRIPTION}
          showError
        >
          <FormControl.Label>Message</FormControl.Label>
          <TextArea required />
        </FormControl>
      </s.Container>
      {candidateCanAttachFiles && (
        <s.Container>
          <FormControl.Label name={FORM_FIELDS.ATTACHMENTS}>
            Attachments
          </FormControl.Label>
          <UploadFileViewer
            uploadedFiles={uploadedFiles}
            multiple
            filesError={filesError ?? undefined}
            onChange={handleAddFiles}
            onFileDelete={handleDeleteFile}
            ariaLabel="Attachments"
            name={FORM_FIELDS.ATTACHMENTS}
          />
        </s.Container>
      )}
    </>
  );
};
