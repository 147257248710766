import { PageHeader } from "components/PageHeader";
import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  takeSectionsListWhenCaseIsSubmitted,
  takeAdditionalDocumentsStatus,
  takeIsAgreedToPrivacyPolicy,
  takeIsAdditionalDocumenstLoading,
  takeAdditionalDocuments,
  takeCaseId,
  takeIsAdditionalDocumentsSubmitted,
  takeShowAdditionalDocumentsSection
} from "modules/case/selectors";
import { ContentLayout } from "components/ContentLayout";
import { Header as CommonHeader } from "components/Header";
import { ISection } from "containers/SectionList/types";
import { useNavigate } from "react-router-dom";
import * as actions from "modules/case/actions";
import { Dispatch } from "redux";
import { IAdditionalDocumentsState } from "modules/case/types";
import { DocumentsSubmitSuccess } from "containers/AdditionalDocuments/components/DocumentsSubmitSuccess";
import { ScreenerAccess } from "containers/ScreenerAccess";
import { SuccessMessage } from "./components/SuccessMessage";
import { SectionsList } from "./components/SectionsList";
import { Policy } from "./components/Policy";
import { useSurvicate } from "containers/SuccessSubmit/hooks";
import { MetaData } from "components/MetaData";

const SuccessSubmitOldComponent: FC = () => {
  useSurvicate();

  const navigateTo = useNavigate();

  const dispatch: Dispatch = useDispatch();

  const isAdditionalDocumentsSectionComplete: boolean = useSelector(
    takeAdditionalDocumentsStatus
  );

  const isAdditionalDocumentsLoading: boolean = useSelector(
    takeIsAdditionalDocumenstLoading
  );

  const additionalDocumentsData: IAdditionalDocumentsState = useSelector(
    takeAdditionalDocuments
  );

  const isAdditionalDocumentsSubmitted: boolean = useSelector(
    takeIsAdditionalDocumentsSubmitted
  );

  const caseId: string = useSelector(takeCaseId);

  const showAdditionalDocuments: boolean = useSelector(
    takeShowAdditionalDocumentsSection
  );

  const showAdditionalDocumentsSubmitSuccess =
    isAdditionalDocumentsSubmitted && !isAdditionalDocumentsSectionComplete;

  const sectionList: ISection[] = useSelector(
    takeSectionsListWhenCaseIsSubmitted
  );

  const isAgreedToPrivacy: boolean = useSelector(takeIsAgreedToPrivacyPolicy);

  const handleAgreedToPrivacyChecboxChange = (): void => {
    dispatch(actions.setAgreedToPrivacyPolicy(!isAgreedToPrivacy));
  };

  const handleSectionItemClick = (pathname: string) => (): void =>
    navigateTo({ pathname, search: window.location.search });

  const handleCaseSubmit = (): void => {
    dispatch(
      actions.additionalDocumentsUpload.started({
        files: additionalDocumentsData.data.files,
        input: {
          caseId,
          note: additionalDocumentsData.data.noteForScreener.trim()
        }
      })
    );
  };

  return (
    <>
      <MetaData title="Screening finished" />

      <CommonHeader />

      <ScreenerAccess />

      <ContentLayout>
        <PageHeader title="Great! Your application has been submitted" />

        <SuccessMessage />
        {showAdditionalDocuments && (
          <>
            {showAdditionalDocumentsSubmitSuccess && <DocumentsSubmitSuccess />}
            <SectionsList
              sectionList={sectionList}
              onSectionItemClick={handleSectionItemClick}
            />
            <Policy
              isAgreedToPrivacy={isAgreedToPrivacy}
              isAdditionalDocumentsLoading={isAdditionalDocumentsLoading}
              isAdditionalDocumentsSectionComplete={
                isAdditionalDocumentsSectionComplete
              }
              onPolicyCheckboxValueChange={handleAgreedToPrivacyChecboxChange}
              onCaseSubmit={handleCaseSubmit}
            />
          </>
        )}
      </ContentLayout>
    </>
  );
};

export const SuccessSubmitOld = React.memo(SuccessSubmitOldComponent);
