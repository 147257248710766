import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";

export const FieldContainer = styled.div`
  max-width: 368px;

  &:not(:last-child) {
    margin-bottom: 23px;
  }
`;

export const FieldTitle = styled.div`
  ${getTypographyStyles({
    color: "color-text-subdued",
    variant: "body-wide-line"
  })};
  display: inline-block;
  margin-bottom: 17px;
`;
