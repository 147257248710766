import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  ${getTypographyStyles({ variant: "body-highlight" })};
`;

export const SubTitle = styled.div`
  ${getTypographyStyles({
    variant: "body-highlight",
    color: "color-text-subdued"
  })};
`;
