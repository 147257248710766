import React, { FC, memo } from "react";
import * as s from "./styles";
import { Icon, Link } from "@security-watchdog/ui-components";
import { useSelector } from "react-redux";
import { takeHelpInfo } from "modules/case/selectors";
import { isCaseManagerAssignmentEnabled } from "src/utils";
import { CASE_ASSIGNMENT_MANAGER_DEFAULT_VALUE } from "src/constants/system";

type Props = {
  candidateName: string;
  clientName: string;
  assigneeName: string | null;
};

const Component: FC<Props> = ({ candidateName, clientName, assigneeName }) => {
  const supportInfo = useSelector(takeHelpInfo);
  const supportPhone = supportInfo?.supportPhoneNumber || "+44 (0)1420 593830";

  return (
    <s.Container>
      <s.Wrapper aria-labelledby="application-submitted-title">
        <Icon name={"check-v2"} className="color-icon-success" size={24} />

        <h1
          className="h1 color-text-on-surface margin-t-4"
          id="application-submitted-title"
        >
          Your screening has finished
        </h1>
        <p className="body-wide-line color-text-subdued margin-t-1">
          Your screening has been finalised and the results have been shared
          with our client. Please note that in some circumstances additional
          checks may be necessary, however Security Watchdog or our client will
          be in contact with you should this be required.
        </p>

        <s.CandidateInfoBlockContainer className="margin-t-6">
          <s.CandidateInfoBlockContainerColumn className="body-highlight">
            <span>Candidate</span>
            <span>Client</span>
            {isCaseManagerAssignmentEnabled() && <span>Manager</span>}
          </s.CandidateInfoBlockContainerColumn>
          <s.CandidateInfoBlockContainerColumn className="body-wide-line">
            <span>{candidateName || "-"}</span>
            <span>{clientName || "-"}</span>
            {isCaseManagerAssignmentEnabled() && (
              <span>
                {assigneeName || CASE_ASSIGNMENT_MANAGER_DEFAULT_VALUE}
              </span>
            )}
          </s.CandidateInfoBlockContainerColumn>
        </s.CandidateInfoBlockContainer>

        <s.ContactsWrapper className="notes-wide-line color-text-subdued margin-t-6">
          <div>Contact us for any further details:</div>
          <div>
            <span className="margin-r-1">Email</span>
            <Link to="mailto:hello@securitywatchdog.org.uk">
              hello@securitywatchdog.org.uk
            </Link>
          </div>
          <div>
            <span className="margin-r-1">
              <s.ContactsDivider>/</s.ContactsDivider> Support
            </span>
            <Link to={`tel:${supportPhone}`}>{supportPhone}</Link>
          </div>
        </s.ContactsWrapper>
      </s.Wrapper>
    </s.Container>
  );
};

Component.displayName = "SuccessfullySubmittedCaseInformation";

export const SuccessfullySubmittedCaseInformation = memo(Component);
