import styled from "styled-components";
import { Button, getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const QuestionsContainer = styled.section`
  border-radius: 4px;
  margin-top: 28px;
`;

export const QuestionWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors["color-surface-default"]};
  padding: 22px 24px;
  margin-bottom: 8px;
`;

export const QuestionActionContainer = styled.div`
  padding-top: 22px;
  border-top: ${({ theme }) =>
    `1px solid ${theme.colors["color-divider-default"]}`};
  margin-top: 22px;
`;

export const AnswerWrapper = styled.div`
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors["color-divider-default"]}`};
  margin-bottom: 22px;
  padding-bottom: 22px;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const AnswerActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CustomButton = styled(Button)`
  margin-top: 16px;
  justify-content: center;

  @media (max-width: ${DEVICE.TABLET}) {
    width: 100%;
  }
`;

export const CardTag = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 16px;
  box-sizing: border-box;
  ${getTypographyStyles({
    variant: "badge",
    color: "color-text-on-highlight"
  })};
  background: ${({ theme }) => theme.colors["color-surface-info-default"]};
`;

export const HeaderWrapper = styled.div`
  padding-top: 5px;
  position: relative;
  top: 0;
`;
