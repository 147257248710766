import styled from "styled-components";

export const AlertContainer = styled.div`
  margin-top: var(--unit-4);
`;

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: var(--unit);
  background: var(--color-surface-default);
  padding: calc(var(--unit) * 14) 0;
  margin-top: calc(var(--unit) * 2);
`;

export const Wrapper = styled.div`
  max-width: calc(var(--unit) * 72);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
