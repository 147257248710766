import styled from "styled-components";
import { getTypographyStyles, theme } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "../../constants";

export const Content = styled.div`
  margin-top: 25px;
`;

export const ContainerBox = styled.section`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${theme.colors["color-surface-default"]};
  padding: 26px 24px;
  margin-bottom: 16px;

  @media (max-width: ${DEVICE.TABLET}) {
    padding: 26px 16px;
  }
`;

export const Title = styled.h2`
  ${getTypographyStyles({ variant: "h2" })}
`;

export const ContainerBoxInfo = styled.div`
  width: 100%;
  padding: 15px 24px;
  border: 1px solid ${theme.colors["color-divider-default"]};
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  margin: 16px 0;

  @media (max-width: ${DEVICE.TABLET}) {
    padding: 15px 0;
    flex-direction: column;
  }
`;

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  @media (max-width: ${DEVICE.TABLET}) {
    width: 100%;
  }
`;

export const Line = styled.div`
  width: 1px;
  background-color: ${theme.colors["color-divider-default"]};

  @media (max-width: ${DEVICE.TABLET}) {
    width: 100%;
    height: 1px;
    margin: 24px 0 15px 0;
  }
`;

export const PrimaryTextInfo = styled.span`
  ${getTypographyStyles({ variant: "body-highlight" })};
  text-align: center;
`;

export const SecondaryTextInfo = styled.span`
  text-align: center;
`;
