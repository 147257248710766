import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  BUTTON_TYPE,
  ExitIcon,
  LifebuoyIcon,
  theme
} from "@security-watchdog/sw-ui-kit";
import { ROUTES } from "src/constants";
import { AuthContext, IAuthContextValueProps } from "src/context/auth";
import * as s from "./styles";
import { IProps } from "./types";

export const NavItems: React.FC<IProps> = ({ className }: IProps) => {
  const navigateTo = useNavigate();

  const { logout, isAuthenticated }: IAuthContextValueProps =
    useContext<IAuthContextValueProps>(AuthContext);

  const goToHelpPage = useCallback(
    () => navigateTo({ pathname: ROUTES.HELP, search: window.location.search }),
    [navigateTo]
  );

  return (
    <s.Container className={className}>
      <s.NavButton
        icon={
          <LifebuoyIcon
            size={16}
            color={theme.colors["color-action-primary-default"]}
          />
        }
        buttonType={BUTTON_TYPE.Ghost}
        onClick={goToHelpPage}
      >
        Help
      </s.NavButton>
      {isAuthenticated && (
        <s.NavButton
          icon={
            <ExitIcon
              size={16}
              color={theme.colors["color-action-critical-default"]}
            />
          }
          buttonType={BUTTON_TYPE.GhostCritical}
          onClick={logout}
        >
          Log out
        </s.NavButton>
      )}
    </s.Container>
  );
};
