import React from "react";
import { ITimeLineRestriction, TimelineEntityName } from "src/types";
import {
  takeEmploymentEducationQuestion,
  takeEmploymentEducationRestriction,
  takeSaveDraftStatusState
} from "modules/case/selectors";
import { useSelector } from "react-redux";
import { Section } from "src/graphQLTypes";
import { IDraftStatusState, IPageTimelineData } from "modules/case/types";
import { TimelineSectionDetails } from "../common/TimelineSectionDetails";
import { getEmploymentAndEducationSectionDescription } from "containers/EmploymentAndEducation/utils";
import { MetaData } from "components/MetaData";

const EmploymentAndEducationComponent: React.FC = () => {
  const data: IPageTimelineData = useSelector(takeEmploymentEducationQuestion);

  const saveDraftStatusState: IDraftStatusState = useSelector(
    takeSaveDraftStatusState
  );

  const timeLineRestriction: ITimeLineRestriction | undefined = useSelector(
    takeEmploymentEducationRestriction
  );

  const sectionDescription = getEmploymentAndEducationSectionDescription(
    timeLineRestriction,
    data.question?.isMultipleAnswers
  );

  return (
    <>
      <MetaData title={"Employment and Education"} />
      <TimelineSectionDetails
        sectionStatus={data.status}
        saveDraftStatusState={saveDraftStatusState}
        section={Section.EMPLOYMENT_EDUCATION}
        title="Employment and education"
        description={sectionDescription}
        entityName={TimelineEntityName.ACTIVITY}
        question={data.question}
        timeLineRestriction={timeLineRestriction}
      />
    </>
  );
};

export const EmploymentAndEducation = React.memo(
  EmploymentAndEducationComponent
);

export default EmploymentAndEducation;
