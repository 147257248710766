import React, { useCallback } from "react";
import { DatePicker } from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";
import { IProps } from "./types";

export const DateRangeFieldComponent: React.FC<IProps> = ({
  value,
  onChange,
  errorMessage,
  title,
  id,
  isCurrent,
  isDisabled,
  isRequired,
  isMobile
}: IProps) => {
  const handleChangeFrom = useCallback(
    (date: Date | null) => {
      onChange(value ? { ...value, from: date } : { from: date });
    },
    [onChange, value]
  );

  const handleChangeTo = useCallback(
    (date: Date | null) => {
      onChange(value ? { ...value, to: date } : { to: date });
    },
    [onChange, value]
  );

  const getErrorMessage = useCallback(
    (index: number): string => {
      if (!errorMessage) {
        return "";
      }
      return errorMessage.split("|")[index];
    },
    [errorMessage]
  );

  return (
    <s.FieldGroup label={title} id={id}>
      <s.DateRangeWrapper aria-labelledby={id}>
        <s.DatePickerWrapper>
          <s.SubLabel htmlFor={`${id}-from`}>From</s.SubLabel>
          <DatePicker
            isMobile={isMobile}
            isDisabled={isDisabled}
            id={`${id}-from`}
            isRequired={isRequired}
            value={value?.from}
            onChange={handleChangeFrom}
            errorMessage={getErrorMessage(0)}
            popoverPositionFixed={false}
          />
        </s.DatePickerWrapper>
        <s.DatePickerWrapper>
          {!isCurrent && (
            <>
              <s.SubLabel htmlFor={`${id}-to`}>To</s.SubLabel>
              <DatePicker
                isMobile={isMobile}
                isDisabled={isDisabled}
                id={`${id}-to`}
                isRequired={isRequired}
                value={value?.to}
                onChange={handleChangeTo}
                errorMessage={getErrorMessage(1)}
                popoverPositionFixed={false}
              />
            </>
          )}
        </s.DatePickerWrapper>
      </s.DateRangeWrapper>
    </s.FieldGroup>
  );
};

export const DateRangeField = React.memo<IProps>(DateRangeFieldComponent);
