import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  IButtonProps,
  IPropsField,
  Field as FieldComponent,
  Sidebar as SidebarComponent
} from "@security-watchdog/sw-ui-kit";
import { ExpandedCard as ExpandedCardComponent } from "components/ExpandedCard";
import { IExpandedCardProps } from "components/ExpandedCard/types";
import { DEVICE } from "src/constants";
import { ReactNode } from "react";

export const Field = styled(FieldComponent)<
  Omit<IPropsField, "label"> & { label: string | ReactNode }
>`
  margin-bottom: 23px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Wrapper = styled.section`
  margin-bottom: 23px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SectionTitle = styled.div`
  margin-bottom: 9px;
`;

export const Sidebar = styled(SidebarComponent)`
  @media (max-width: ${DEVICE.MOBILE_L}) {
    width: 100%;
  }
  @media (max-width: ${DEVICE.MOBILE_S}) {
    overflow: auto;
  }
`;

export const Heading = styled.h2`
  margin: 0;
  ${getTypographyStyles({ variant: "h2", color: "color-text-on-surface" })};
`;

export const ErrorWrapper = styled.div`
  margin-top: 8px;
`;

export const Container = styled.div`
  color: ${({ theme }): string => theme.colors["color-text-default"]};
  padding: 40px;
  overflow: auto;
  flex-grow: 1;
  flex-basis: 0;
  @media (max-width: ${DEVICE.MOBILE_S}) {
    overflow: unset;
  }
`;

export const DoubleField = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 14px;
`;

export const LongField = styled.div`
  width: 60%;
  margin-bottom: 0;
`;

export const ShortField = styled.div`
  width: 40%;
  margin-bottom: 0;
`;

export const DoubleFieldInputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

export const FieldLabel = styled.div`
  margin-bottom: 4px;
`;

export const DeleteButton = styled(Button)`
  flex: 0 0 auto;
  margin-top: 8px;
`;

export const AddSubEntryButton = styled(Button)<IButtonProps>`
  margin-top: 5px;
`;

export const GradesContainer = styled.div`
  ${getTypographyStyles({
    variant: "body-default",
    color: "color-text-subdued"
  })};
  display: flex;
  width: 100%;
`;

export const CardActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ExpandedCard = styled(ExpandedCardComponent)<IExpandedCardProps>`
  margin-bottom: 15px;
`;

export const ActionButtonAdd = styled(Button)<IButtonProps>`
  justify-content: center;
  margin-right: 20px;
  width: 240px;
`;

export const ActionCancelButton = styled(Button)<IButtonProps>`
  width: 112px;
  justify-content: center;
`;
