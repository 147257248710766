import React from "react";
import * as s from "./styles";
import { IProps } from "./types";

export const ContentLayout: React.FC<IProps> = ({
  children,
  isSticky
}: IProps) => (
  <s.MainContainer $isSticky={isSticky}>
    <s.Content>{children}</s.Content>
  </s.MainContainer>
);
