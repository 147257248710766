import React, { FC, useCallback, useEffect, useState } from "react";
import { Dropzone, AlertCircleIcon, theme } from "@security-watchdog/sw-ui-kit";

import { validateUploadedSignatureFile } from "src/utils/validateUploadedFiles";
import { ACCEPT_SIGNATURE_FILE_TYPES } from "src/constants";
import { DROP_BOX_DESCRIPTION } from "./constants";
import * as s from "./styles";

type UploadSignatureControlProps = {
  selectedFile: File | null;
  onFileAdd: (file: File) => void;
};

export const UploadSignatureControl: FC<UploadSignatureControlProps> = ({
  selectedFile,
  onFileAdd
}) => {
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState<string | null>(null);

  const onFileAddHandler = useCallback(
    async (fileList: File[]) => {
      const { isValid, message } = await validateUploadedSignatureFile(
        fileList[0]
      );

      if (isValid) {
        onFileAdd(fileList[0]);
        setFileErrorMessage(null);
      } else {
        setFileErrorMessage(message ?? "");
      }
    },
    [onFileAdd]
  );

  useEffect(() => {
    setUploadedFileUrl(
      selectedFile ? window.URL.createObjectURL(selectedFile) : null
    );
  }, [selectedFile]);

  return (
    <>
      {fileErrorMessage && (
        <s.ErrorMessageWrapper>
          <AlertCircleIcon
            size={20}
            color={theme.colors["color-icon-critical"]}
          />
          <s.ErrorMessage role="alert">{fileErrorMessage}</s.ErrorMessage>
        </s.ErrorMessageWrapper>
      )}
      <s.Wrapper>
        {uploadedFileUrl ? (
          <s.Image src={uploadedFileUrl} />
        ) : (
          <s.DropzoneWrapper>
            <Dropzone
              isDisabled={false}
              multiple={false}
              isLoading={false}
              accept={ACCEPT_SIGNATURE_FILE_TYPES}
              primaryText="Drop or click here to upload new file"
              secondaryText={DROP_BOX_DESCRIPTION}
              onAddFiles={onFileAddHandler}
            />
          </s.DropzoneWrapper>
        )}
      </s.Wrapper>
    </>
  );
};
