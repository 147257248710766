import React from "react";
import { ArrowIcon, BUTTON_TYPE, theme } from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";
import { IProps } from "./types";

export const ButtonBack: React.FC<IProps> = ({
  children,
  handlerClick,
  ariaLabel
}: IProps) => (
  <s.ButtonBack
    buttonType={BUTTON_TYPE.Ghost}
    onClick={() => (handlerClick ? handlerClick() : null)}
    icon={
      <ArrowIcon
        color={theme.colors["color-action-primary-default"]}
        size={16}
      />
    }
    ariaLabel={ariaLabel ? ariaLabel : "back"}
  >
    {children}
  </s.ButtonBack>
);
