import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

const largeHorizontalPadding = 24;
const smallHorizontalPadding = 16;

const Section = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-left: ${largeHorizontalPadding}px;
  padding-right: ${largeHorizontalPadding}px;

  @media (max-width: ${DEVICE.TABLET}) {
    padding-left: ${smallHorizontalPadding}px;
    padding-right: ${smallHorizontalPadding}px;
  }
`;

export const Header = styled(Section)<{ $hasChildren: boolean }>`
  padding-top: 16px;
  padding-bottom: ${({ $hasChildren }) => ($hasChildren ? 0 : `16px`)};
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors["color-divider-default"]}`};
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  ${getTypographyStyles({ variant: "h3", color: "color-text-on-surface" })};
`;

export const HeaderSubTitle = styled.div`
  ${getTypographyStyles({
    variant: "body-default",
    color: "color-text-subdued"
  })};
`;

export const Body = styled(Section)`
  flex: 1 1 auto;
  padding-top: 24px;
  padding-bottom: 24px;
  overflow-y: auto;
  @media (max-width: ${DEVICE.TABLET}) {
    overflow: unset;
  }
`;

export const Footer = styled(Section)`
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: ${({ theme }) =>
    `1px solid ${theme.colors["color-divider-default"]}`};
`;
