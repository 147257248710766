import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  theme,
  IButtonProps
} from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${theme.colors["color-surface-info-default"]};
  padding: 18px 20px 18px 20px;
  justify-content: space-between;
  box-sizing: border-box;
  @media (max-width: ${DEVICE.MOBILE_S}) {
    padding: 12px;
  }
`;

export const InfoTextContainer = styled.div`
  ${getTypographyStyles({
    variant: "body-wide-line",
    color: "color-text-on-highlight"
  })};
  display: flex;
  align-items: center;

  & > span {
    margin-left: 16px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
`;

export const ActionButton = styled(Button)<IButtonProps>`
  color: ${theme.colors["color-action-subdued-default"]};
  margin-left: 28px;
  @media (max-width: ${DEVICE.MOBILE_S}) {
    margin-left: 16px;
  }
`;

export const OnlineContainer = styled.div`
  ${getTypographyStyles({
    variant: "caption",
    color: "color-text-on-highlight"
  })};
  display: flex;
  align-items: center;
`;

export const OnlineIcon = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${theme.colors["color-icon-success"]};
  border: 1px solid ${theme.colors["color-icon-on-primary"]};
  margin-right: 8px;
`;
